import { IGeneralResponse } from './GeneralModel';

export interface IUser extends IGeneralResponse {
  userId: number;
  securityId: number;
  userName: string;
  email: string;
  name: string;
  surName: string;
  status: number;
  role: number;
  isDefault: number;
  partnerId: number;
}

export const defaultUser: IUser = {
  userId: 0,
  securityId: 0,
  userName: '',
  email: '',
  name: '',
  surName: '',
  status: 0,
  role: 0,
  isDefault: 1,
  partnerId: 0,
} as IUser;

export interface ILoginFormInput extends IGeneralResponse {
  email: string;
  password: string;
  showPassword: boolean;
}

export const defaultLoginFormInput: ILoginFormInput = {
  error: false,
  message: '',
  email: '',
  data: '',
  password: '',
  showPassword: false,
} as ILoginFormInput;

export interface IForgotPasswordInput extends IGeneralResponse {
  email: string;
}

export interface IRescuePasswordInput extends IGeneralResponse {
  id: string;
  password: string;
  rePassword: string;
}

export const defaultRescuePasswordInput: IRescuePasswordInput = {
  id: '',
  password: '',
  rePassword: '',
} as IRescuePasswordInput;

export interface ICheckApi extends IGeneralResponse {
  id: string;
}

export const defaultcheckApi : ICheckApi = {
  id: '',
} as ICheckApi;

export interface IApplicationInput extends IGeneralResponse {
  /* eslint-disable camelcase */
  company_name: string;
  company_web_address: string;
  mobile_code: string;
  mobile_number: string;
  firstname: string;
  surname: string;
  email: string;
  application_note: string;
  social_medial_link: string;
  partner_type: number;
  lang: string;
}

export const applicationFormInput: IApplicationInput = {
  company_name: '',
  company_web_address: '',
  mobile_code: '',
  mobile_number: '',
  firstname: '',
  surname: '',
  email: '',
  application_note: '',
  social_medial_link: '',
  partner_type: 0,
} as IApplicationInput;

export interface IInsertUserFormInput extends IGeneralResponse {
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  partnerId:number | undefined;
  role:number | undefined;
}

export const defaultInsertUserFormInput: IInsertUserFormInput = {
  name: '',
  surname: '',
  email: '',
  role: 0,
} as IInsertUserFormInput;

export interface IEditUserFormInput extends IGeneralResponse {
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  partnerId:number | undefined;
  userId:number | undefined;
}

export const defaultEditUserFormInput: IEditUserFormInput = {
  name: '',
  surname: '',
  email: '',
  partnerId: undefined,
  userId: undefined,
} as IEditUserFormInput;

export interface IUserDeleteRequest {
  securityId: number;
  userId: number | undefined;
  partnerId: number;
}
