import { ToastContainer } from 'react-toastify';
import HeaderSection from '../../components/newLanding/Header';
import FAQSection from '../../components/newLanding/FAQ';
import FooterSection from '../../components/newLanding/Footer';

export const FAQ: React.FC = () => (
  <main className="landing">
    <HeaderSection />
    <FAQSection />
    <FooterSection />
    <ToastContainer />
  </main>
);
export default FAQ;
