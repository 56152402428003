import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../components/banner';
import { ServerError } from '../../models/AxiosModel';
import { IPartnerChannel } from '../../models/PartnerChannelModel';
import BaseConfig from '../../config/BaseConfig';

export const Banner = (): JSX.Element => {
  const [size, setSize] = useState<string>('300x250');

  const { t } = useTranslation();

  const spanRef = useRef<HTMLAnchorElement>(null);
  const partnerId = BaseConfig.utilities.partnerId();

  const apiClient = axios.create();

  const [partnerChannels, setPartnerChannels] = useState <Array <IPartnerChannel>>([]);
  const [selectedPartnerChannel, setSelectedPartnerChannel] = useState<IPartnerChannel>();

  const bannerCode = `<a href='http://www.otelz.com/?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}'target='_blank'><img src='http://image.otelz.com/Uploads/AcentaBanner/${size}.gif' alt='Otelz.com, otel rezervasyonunda Türkiye&#39;nin tercihi'/></a>`;

  const getPartnerChannels = async () => {
    try {
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post(
        BaseConfig.api.user('/api/PartnerChannel/GetPartnerChannelList'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      const partnerChannelList : Array <IPartnerChannel> = response.data.data;
      partnerChannelList.unshift(
        { partnerChannelId: 0,
          name: t('banner.salesChannelNotSelected'),
          partnerId: param.partnerId,
          website: '',
          referenceCode: '',
          data: '',
          error: false,
          message: '' },
      );
      setPartnerChannels(partnerChannelList);

      setSelectedPartnerChannel(partnerChannelList[0]);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(event.target.value);
  };

  const handleCopy = () => {
    toast.success(t('operation.operationCopied'));
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = bannerCode;
    }
  }, [bannerCode]);

  useEffect(() => {
    getPartnerChannels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedPartnerChannel) {
    return (
      <>
        <Breadcrumb />
        <Box sx={{ mb: 3 }}>
          <Alert variant="outlined" severity="info">
            {t('banner.bannerInfo')}
          </Alert>
        </Box>
        <Divider />
        <Box>
          <Grid item xs={12} md={3} sx={{ marginTop: 2, marginBottom: 2 }}>
            <TextField
              id="outlined-select-partner-channel"
              select
              size="small"
              defaultValue={0}
              label={t('banner.saleChannels')}
              className="w-100"
            >
              {partnerChannels.map(option => (
                <MenuItem onClick={() => setSelectedPartnerChannel(option)} key={option.partnerChannelId} value={option.partnerChannelId}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>
          <Typography
            sx={{ flex: '1 1 100%', marginBottom: 2 }}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {t('banner.createBannerTitle')}
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <Box>
                  <Card>
                    <CardContent>
                      <Typography
                        sx={{ flex: '1 1 100%', marginBottom: 2 }}
                        variant="body2"
                        component="div"
                      >
                        {t('banner.preview')}
                        :
                      </Typography>
                      <span ref={spanRef} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <form>
                  <Card>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ flex: '1 1 100%', marginRight: 2 }}
                            variant="body2"
                            component="a"
                          >
                            {t('banner.dimension')}
                            :
                          </Typography>
                          <label htmlFor="field-160x600">
                            <input
                              type="radio"
                              name="size"
                              onChange={handleChange}
                              value="160x600"
                              id="160x600"
                            />
                            160x600
                          </label>
                          <label htmlFor="field-300x250">
                            <input
                              defaultChecked
                              type="radio"
                              name="size"
                              onChange={handleChange}
                              value="300x250"
                              id="300x250"
                            />
                            300x250
                          </label>
                          <label htmlFor="field-336x280">
                            <input
                              type="radio"
                              name="size"
                              onChange={handleChange}
                              value="336x280"
                              id="336x280"
                            />
                            336x280
                          </label>
                          <label htmlFor="field-728x90">
                            <input
                              type="radio"
                              name="size"
                              onChange={handleChange}
                              value="728x90"
                              id="728x90"
                            />
                            728x90
                          </label>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        p: 2,
                      }}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        spacing={2}
                      >
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size="small"
                            disabled
                            value={bannerCode}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CopyToClipboard
                            text={bannerCode}
                            onCopy={() => handleCopy()}
                          >
                            <Button
                              variant="contained"
                              endIcon={<ContentCopyIcon />}
                            >
                              {t('banner.copyCode')}
                            </Button>
                          </CopyToClipboard>
                        </Grid>
                      </Stack>
                    </Box>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
  return (
    <Grid
      style={{
        position: 'absolute',
        left: '42%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default Banner;
