/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';
import { defaultIChangePasswordProps, IChangePassword } from '../../models/ChangePasswordModel';
import { ServerError } from '../../models/AxiosModel';
import BaseConfig from '../../config/BaseConfig';

export const Form = () => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IChangePassword>(
    defaultIChangePasswordProps,
  );

  const changePassword = async (changePasswordInput: IChangePassword) => {
    try {
      if (
        changePasswordInput.NewPassword !== changePasswordInput.NewPasswordAgain
      ) {
        toast.error('Tekrarlanan şifre eşlemiyor!');
        return false;
      }

      const headers = BaseConfig.utilities.authorizedHeader();

      const apiClient = axios.create();
      const response = await apiClient.post<IChangePassword>(
        BaseConfig.api.user('/api/User/ChangePassword'),
        changePasswordInput,
        {
          headers: JSON.parse(headers),
        },
      );

      if (response.data.error) {
        toast.error(response.data.message);
      } else {
        toast.success(t('operation.operationSuccess'));
      }
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IChangePassword) => {
    if (data.NewPassword !== data.NewPasswordAgain) {
      toast.error(t('"Yeni Şifre" ve "Şifreyi Tekrarla" alanları eşit değil'));
      return;
    }
    if (data.CurrentPassword === data.NewPassword) {
      toast.error(t('Eski şifre ile yeni şifre aynı olamaz'));
      return;
    }
    changePassword(data);
  };

  const handleClickShowPassword = () => {
    setInputs({
      ...inputs,
      showPassword: !inputs.showPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setInputs({
      ...inputs,
      showNewPassword: !inputs.showNewPassword,
    });
  };

  const handleClickShowRePassword = () => {
    setInputs({
      ...inputs,
      showRePassword: !inputs.showRePassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useForm<IChangePassword>();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('label.pass')}
                  </InputLabel>
                  <Controller
                    control={control}
                    name="CurrentPassword"
                    render={({ field: { onChange, value } }) => (
                      <OutlinedInput
                        {...register('CurrentPassword', { required: true })}
                        id="outlined-adornment-password"
                        type={inputs.showPassword ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {inputs.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                          )}
                        label={t('label.currentPassword')}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel htmlFor="outlined-adornment-newpassword">
                    {t('label.newPassword')}
                  </InputLabel>
                  <Controller
                    control={control}
                    name="NewPassword"
                    render={({ field: { value } }) => (
                      <OutlinedInput
                        {...register('NewPassword', { required: true })}
                        id="outlined-adornment-password"
                        type={inputs.showNewPassword ? 'text' : 'password'}
                        value={value}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              edge="end"
                            >
                              {inputs.showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                          )}
                        label={t('label.newPassword')}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" className="w-100">
                  <InputLabel htmlFor="outlined-adornment-newpasswordagain">
                    {t('label.repeatPassword')}
                  </InputLabel>
                  <Controller
                    control={control}
                    name="NewPasswordAgain"
                    render={({ field: { value } }) => (
                      <OutlinedInput
                        {...register('NewPasswordAgain', { required: true })}
                        id="outlined-adornment-password"
                        type={inputs.showRePassword ? 'text' : 'password'}
                        value={value}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRePassword}
                              edge="end"
                            >
                              {inputs.showRePassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                          )}
                        label={t('label.repeatPassword')}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button color="primary" variant="contained" type="submit">
              {t('button.changePassword')}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};
