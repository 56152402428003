const localePrice = (languageCode:string, price:any, currenyIcon:any, decimal = 0) => {
  const currencySymbol = currenyIcon.toUpperCase() === 'TL' ? 'TRY' : currenyIcon;
  const localeMap: Record<string, string> = {
    tr: 'tr-TR',
    en: 'en-US',
  };
  if (price || price === 0) {
    let locale: string | Record<string, string> = localeMap[languageCode]?.toString();
    if (languageCode !== 'tr' && (currencySymbol === 'TRY' || currencySymbol === 'try')) {
      locale = 'tr-TR';
    }
    const localePriceValue = price.toLocaleString(locale, {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 0,
      maximumFractionDigits: decimal,
    });
    return localePriceValue as string;
  }
  return null;
};
localePrice.defaultProps = {
  decimal: 0,
  currenyIcon: '',
};
export default localePrice;
