import { useContext } from 'react';
import { classList } from '../../helpers/Utils';
import DrawerContext from '../../context/drawer/DrawerContext';
import { DrawerProps } from '../../context/drawer/DrawerContextProps';
import { Content } from '../../views/layout/Content';

export default function LayoutMain() {
  const context = useContext<DrawerProps>(DrawerContext);
  // CTRL + S yapınca iframe atıyor. Bunu engellemek için yazıldı
  // useEffect(() => {
  //   setTimeout(() => {
  //     document.querySelector('iframe')?.remove();
  //   }, 1000);
  // }, []);
  return (
    <main
      className={classList({
        'nav-item dropdown-sidemenu main': true,
        open: context.isShow,
      })}
    >
      <Content />
    </main>
  );
}
