import {
  Box,
  styled,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Breadcrumb,
  CommissionReportEarned,
  CommissionReportEstimated,
} from '../../components/commission-report';

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export const CommissionReport = (): JSX.Element => (
  <>
    <Breadcrumb />

    <Box sx={{ mb: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <CommissionReportEarned />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CommissionReportEstimated />
        </Grid>
      </Grid>
    </Box>
  </>
);
