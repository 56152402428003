import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { ProfileCard, Form, Breadcrumb } from '../../components/profile';
import { defaultProfile, IProfile } from '../../models/ProfileModel';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';

export const Profile = (): JSX.Element => {
  const [profileInfo, setProfileInfo] = useState<IProfile>(defaultProfile);

  const apiClient = axios.create();

  const getProfile = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/Profile/GetUserProfile'), {
        headers: JSON.parse(headers),
      });

      setProfileInfo(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb />

      <Box sx={{ mb: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ProfileCard profileInfo={profileInfo} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            {
              profileInfo.firstName && (
              <Form profileInfo={profileInfo} />
              )
            }
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default Profile;
