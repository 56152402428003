import { IGeneralResponse } from './GeneralModel';

export interface IFirm extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  partnerId: number;
  partnerName: string;
  partnerType: number;
  profitSharingType: number;
  commissionRate: number;
  commercialName: string;
  currentCode: string;
  taxOfficce: string;
  taxNumber: string;
  status: string;
  createdOn: Date;
  updatedOn?: Date;
}

export const defaultFirm: IFirm = {
  partnerId: 0,
  partnerName: '',
  partnerType: 0,
  profitSharingType: 0,
  commissionRate: 0,
  commercialName: '',
  currentCode: '',
  taxOfficce: '',
  taxNumber: '',
  status: '',
  createdOn: new Date(),
  updatedOn: new Date(),
} as IFirm;

export interface IFirmGetRequest {
  partnerId: number;
}
