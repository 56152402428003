/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, DialogContentText, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseConfig } from '../../config/BaseConfig';
import { IBankInfo, IBankLookupFormInput, IBankLookup, IBankBranchLookup } from '../../models/BankInfoModel';
import { ServerError } from '../../models/AxiosModel';
import { Lookup } from '../../models/GeneralModel';

  interface Props {
    // eslint-disable-next-line no-unused-vars
    refreshTable: (arg: boolean) => void;
    selectedBankFormInput: IBankInfo;
  }

export const EditModal: React.FC<Props> = ({ refreshTable, selectedBankFormInput }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IBankInfo>();

  const apiClient = axios.create();

  const editBank = async (bankFormInput: IBankInfo) => {
    try {
      const selectedBank = bankLookup.find((x => x.id === bankFormInput.bankCode)) as unknown as IBankLookup;
      bankFormInput.countryCode = selectedBank.country;
      bankFormInput.bankCode = selectedBank.id;

      const selectedBranch = bankBranchLookup.find((x => x.id === bankFormInput.branchCode)) as unknown as IBankBranchLookup;
      bankFormInput.cityId = selectedBranch.cityId;
      bankFormInput.districtId = selectedBranch.districtId;
      bankFormInput.branchCode = selectedBranch.id;
      bankFormInput.branchName = selectedBranch.label;
      bankFormInput.relationId = BaseConfig.utilities.partnerId();

      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post<IBankInfo>(BaseConfig.api.user('/api/Finance/SaveBankInfo'), bankFormInput, {
        headers: JSON.parse(headers),
      });
      toast.success(t('operation.operationSuccess'));
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IBankInfo) => {
    editBank(data);
  };

  const [bankLookup, setBankLookup] = React.useState <Array <Lookup>>([]);
  const [bankBranchLookup, setBankBranchLookup] = React.useState <Array <Lookup>>([]);
  const [currencyLookup, setCurrencyLookup] = React.useState <Array <Lookup>>([]);

  const getBankLookup = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/Finance/GetBankLookup'), {
        headers: JSON.parse(headers),
      });

      setBankLookup(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const getBankBranchLookup = async (bankCode: string) => {
    try {
      const bankBranchModel: IBankLookupFormInput = {
        bankCode,
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post(BaseConfig.api.user('/api/Finance/GetBankBranchLookup'), bankBranchModel, {
        headers: JSON.parse(headers),
      });
      setBankBranchLookup(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const getCurrencies = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/Finance/GetCurrencyLookup'), {
        headers: JSON.parse(headers),
      });
      setCurrencyLookup(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getBankLookup();
    getCurrencies();
    getBankBranchLookup(selectedBankFormInput.bankCode);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getBankBranchLookup((event.target.value));
  };

  return (
    <>
      <DialogContentText sx={{ fontSize: 15, marginBottom: 3 }}>
        Banka bilgilerini düzenleyin, daha hızlı işlem yapın.
      </DialogContentText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

            <Grid sx={{ display: 'none' }}>
              <TextField
                {...register('bankInfoId', {
                  required: true,
                })}
                value={selectedBankFormInput.bankInfoId}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('name', {
                  required: true,
                })}
                variant="outlined"
                label={t('bankInfo.accountName')}
                size="medium"
                defaultValue={selectedBankFormInput.name}
                className="w-100"
                autoComplete="off"
              />
              {errors?.iban?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">Bu alan boş bırakılamaz.</span>
              </Box>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('iban', {
                  required: true,
                  pattern: /TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/,
                })}
                variant="outlined"
                label="IBAN"
                defaultValue={selectedBankFormInput.iban}
                size="medium"
                className="w-100"
                autoComplete="off"
              />
              {errors?.iban?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">Bu alan boş bırakılamaz.</span>
              </Box>
              )}
              {errors?.iban?.type === 'pattern' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">Lütfen bir geçerli iban giriniz.</span>
              </Box>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('bankCode')}
                id="outlined-select-currency"
                select
                label={t('bankInfo.bank')}
                defaultValue={selectedBankFormInput.bankCode}
                className="w-100"
                onChange={handleChange}
              >
                {bankLookup.map((option, key) => (
                  <MenuItem key={key} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('branchCode')}
                id="outlined-select-currency"
                select
                defaultValue={selectedBankFormInput.branchCode}
                label={t('bankInfo.branch')}
                className="w-100"
              >
                {bankBranchLookup.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('accountNumber', {
                  required: true,
                })}
                variant="outlined"
                label={t('bankInfo.accountNumber')}
                size="medium"
                defaultValue={selectedBankFormInput.accountNumber}
                className="w-100"
                autoComplete="off"
              />
              {errors?.iban?.type === 'required' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">Bu alan boş bırakılamaz.</span>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('currencyCode')}
                id="outlined-select-currency"
                select
                defaultValue={selectedBankFormInput.currencyCode}
                label={t('bankInfo.currency')}
                className="w-100"
              >
                {currencyLookup.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3, textAlign: 'right' }}>
          <Button onClick={() => refreshTable(true)} size="large" type="submit" variant="contained">
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditModal;
