/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { BaseConfig } from '../../config/BaseConfig';
import { IPartnerChannel, defaultIPartnerChannel } from '../../models/PartnerChannelModel';
import { ServerError } from '../../models/AxiosModel';

  interface Props {
    // eslint-disable-next-line no-unused-vars
    refreshTable: (arg: boolean) => void;
    selectedPartnerChannelFormInput: IPartnerChannel;
  }

export const EditModal: React.FC<Props> = ({ refreshTable, selectedPartnerChannelFormInput }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IPartnerChannel>();

  const apiClient = axios.create();

  const insertPartnerChannel = async (partnerChannelFormInput: IPartnerChannel) => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post<IPartnerChannel>(BaseConfig.api.user('/api/PartnerChannel/SavePartnerChannel'), partnerChannelFormInput, {
        headers: JSON.parse(headers),
      });

      toast.success(t('operation.operationSuccess'));
      reset(defaultIPartnerChannel);
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  useEffect(() => {
  }, []);

  const onSubmit = (data: IPartnerChannel) => {
    insertPartnerChannel(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

            <Grid sx={{ display: 'none' }}>
              <TextField
                {...register('partnerChannelId', {
                  required: true,
                })}
                value={selectedPartnerChannelFormInput.partnerChannelId}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                {...register('name', {
                  required: true,
                })}
                variant="outlined"
                label={t('partnerChannel.channelName')}
                size="medium"
                defaultValue={selectedPartnerChannelFormInput.name}
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.name?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('referenceCode', {
                  required: false,
                })}
                variant="outlined"
                label={t('partnerChannel.referenceCode')}
                size="medium"
                defaultValue={selectedPartnerChannelFormInput.referenceCode}
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.referenceCode?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('website', {
                  required: true,
                })}
                variant="outlined"
                label={t('partnerChannel.webSite')}
                size="medium"
                defaultValue={selectedPartnerChannelFormInput.website}
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.website?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>

          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3, textAlign: 'right' }}>
          <Button onClick={() => refreshTable(true)} size="large" type="submit" variant="contained">
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditModal;
