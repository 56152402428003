/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, DialogActions } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../models/AxiosModel';
import { IGeneralResponse } from '../../models/GeneralModel';
import BaseConfig from '../../config/BaseConfig';
import { IUserDeleteRequest, IUser } from '../../models/UserServiceModel';

interface Props {
  // eslint-disable-next-line no-unused-vars
  refreshTable: (arg: boolean) => void;
  selectedUser: IUser | undefined;
  // eslint-disable-next-line no-unused-vars
  handleClose: (arg: boolean) => void;
}

export const DeleteModal: React.FC<Props> = ({
  refreshTable,
  selectedUser,
  handleClose,
}) => {
  const { t } = useTranslation();

  const apiClient = axios.create();

  const deleteUser = async () => {
    try {
      const userDeleteModel: IUserDeleteRequest = {
        partnerId: BaseConfig.utilities.partnerId(),
        securityId: BaseConfig.utilities.securityId(),
        userId: selectedUser?.userId,
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post<IGeneralResponse>(
        BaseConfig.api.user('/api/User/DeletePartnerUser'),
        userDeleteModel,
        {
          headers: JSON.parse(headers),
        },
      );
      if (response.data.error || response.data.data === false) {
        toast.error(t('operation.operationFailed'));
      }
      toast.success(t('operation.operationSuccess'));
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  return (
    <>
      <DialogActions>
        <Button
          sx={{ textAlign: 'left', color: 'red', borderColor: 'red' }}
          startIcon={<DeleteForeverIcon />}
          variant="outlined"
          onClick={() => {
            deleteUser();
            refreshTable(true);
          }}
        >
          {t('button.delete')}
        </Button>
        <Button
          onClick={() => {
            handleClose(false);
          }}
        >
          {t('button.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteModal;
