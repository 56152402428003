import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import GroupIcon from '@mui/icons-material/Group';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

export const Breadcrumb: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mb: 5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          component="a"
          href="#"
          label={t('partnerMenu.partner')}
          icon={<ApartmentIcon fontSize="small" />}
        />
        <StyledBreadcrumb
          component="a"
          href="#"
          label={t('partnerMenu.partnerUsers')}
          icon={<GroupIcon fontSize="small" />}
        />
      </Breadcrumbs>
    </Box>
  );
};
export { Breadcrumb as default };
