import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios, { AxiosError } from 'axios';
import { Box, LinearProgress, TableHead, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseConfig from '../../config/BaseConfig';
import { FinancialStatusReportModel } from '../../models/FinancialStatusReportModel';
import localePrice from '../../utilities/localePriceHelper';

type ServerError = {
  code: string;
  description: string;
};

export const FinancialStatusReport: React.FC = () => {
  const { t } = useTranslation();

  const [financialStatusData, setFinancialStatusData] = useState<FinancialStatusReportModel>();
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const getTable = async () => {
    try {
      setIsLoaderActive(true);
      const headers = BaseConfig.utilities.authorizedHeader();

      const apiClient = axios.create();

      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };

      const response = await apiClient.post(
        BaseConfig.api.user('/api/Finance/GetFinancialStatus'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      // create new array to push coming value to inside
      setFinancialStatusData(response.data.data);
      setIsLoaderActive(false);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        sx={{ flex: '1 1 100%', marginBottom: 2 }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {t('financialStatusReport.FinancialStatus')}
      </Typography>
      {isLoaderActive ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (false)}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('financialStatusReport.Partner')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.netsisDebt')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.reservationSum')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.commissionSum')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.checkinReservationSum')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.checkinCommissionSum')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.generalDebt')}
              </TableCell>
              <TableCell>
                {t('financialStatusReport.statusTotalForCheckin')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {financialStatusData !== undefined && (
              <TableRow
                style={{ width: 10 }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {financialStatusData.partnerName}
                  <br />
                  {financialStatusData.partnerId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.netsisDebtSum, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.reservationSum, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.commissionSum, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.checkinReservationSum, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.checkinCommissionSum, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.generalDebt, 'TL', 2)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {localePrice('tr', financialStatusData.statusTotalForCheckin, 'TL', 2)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export { FinancialStatusReport as default };
