import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseConfig from '../../config/BaseConfig';
import { IApplicationInput } from '../../models/UserServiceModel';
import { ServerError } from '../../models/AxiosModel';
import { Service } from '../../services';
import { Country } from '../../models/CountriesModel';

const FormSectionDiv = styled.div`
  margin:0;
  padding:0 10px;
  .title{
    font-size:26px;
    font-weight:700;
    margin-bottom:24px;
  }
  form{
    padding:12px 24px!important;
    .form-group{
      min-height:56px;
      input,select, option{
        height:40px;
      }
    }
  }
  .banner{
    background-color: rgb(245, 245, 255);
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media(max-width:550px){
    .title{
      font-size:20px;
      font-weight:700;
      margin-bottom:24px;
      padding-right:30px;
    }
    form{
      padding:24px;
    }
    .banner{
      display:none;
    }
  }
`;

interface Props {
    type: number;
}

export const FormSection: React.FC<Props> = ({ type }: Props) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const { t } = useTranslation();
  const apiClient = axios.create();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IApplicationInput>();

  const createUser = async (applicationInput: IApplicationInput) => {
    try {
      const lang = localStorage.getItem('language');
      if (lang) {
        applicationInput.lang = lang;
      }
      const response = await apiClient.post<IApplicationInput>(
        BaseConfig.api.user('/api/Application/CreateRequest'),
        applicationInput,
      );
      if (response.data.error) {
        toast.error(response.data.message);
      } else {
        toast.success(t('landing.formSuccess'));
        reset();
      }
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IApplicationInput) => {
    createUser(data);
  };

  useEffect(() => {
    Service.User.GetCountriesRequest().then(res => {
      setCountries(res.data.data);
    });
  }, []);

  return (
    <FormSectionDiv className="row">
      <form className="col-6 col-sm-12" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 title">
          Affilate/White Label
          {' '}
          {t('landing.partnerApplyForm')}
        </div>
        <input
          type="hidden"
          {...register('partner_type')}
          value={type}
        />
        {(type === 3 || type === 1) && (
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                type="text"
                autoComplete="off"
                {...register('company_name', {
                  required: true,
                })}
                placeholder={t('landing.companyName')}
              />
              {errors?.company_name?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <input
                type="text"
                placeholder="Web Site"
                {...register('company_web_address', {
                  required: true,
                })}
                autoComplete="off"
              />
              {errors?.company_web_address?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
        </div>
        )}

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                type="text"
                {...register('firstname', {
                  required: true,
                })}
                placeholder={type === 3 || type === 1 ? t('landing.authorizedFirstname') : t('label.firstname')}
                autoComplete="off"
              />
              {errors?.firstname?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <input
                type="text"
                {...register('surname', {
                  required: true,
                })}
                placeholder={type === 3 || type === 1 ? t('landing.authorizedLastname') : t('label.lastname')}
                autoComplete="off"
              />
              {errors?.surname?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-sm-4">
            <div className="form-group">
              <select
                {...register('mobile_code', {
                  required: true,
                })}
                defaultValue="90"
                placeholder={t('label.countryCode')}
              >
                {countries.map((option, key) => (
                  <option key={key} value={option.phoneCode}>
                    {`+${option.phoneCode} (${option.id})`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-9 col-sm-8">
            <div className="form-group">
              <input
                type="text"
                placeholder={t('label.phone')}
                {...register('mobile_number', {
                  required: true,
                })}
              />
              {errors?.mobile_number?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
        </div>
        {type === 4 && (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                {...register('social_medial_link')}
                placeholder={t('landing.socialMediaLink')}
                autoComplete="off"
              />
              {errors?.social_medial_link?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
        </div>
        )}

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                {...register('email', {
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                placeholder="E-mail"
                autoComplete="off"
              />
              {errors?.email?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
              {errors?.email?.type === 'pattern' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register('application_note')}
                placeholder={t('landing.yourMessage')}
                rows={4}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
      <div className="col-6"></div>
      <div className="col-6"></div>
    </div> */}
        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-purple big block">
              {t('landing.apply')}
            </button>
          </div>
        </div>
      </form>
      <div className="col-6 banner" style={{ backgroundImage: `url(/img/landing/partner_type_${type}.png)` }} />
    </FormSectionDiv>
  );
};
export default FormSection;
