import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import trLocale from 'date-fns/locale/tr';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IProfile, IProfileSettingsSaveRequest } from '../../models/ProfileModel';
import { ServerError } from '../../models/AxiosModel';
import BaseConfig from '../../config/BaseConfig';
import { Country } from '../../models/CountriesModel';
import { Service } from '../../services';

interface Props {
  // eslint-disable-next-line no-unused-vars
  profileInfo: IProfile;
}

export const Form: React.FC<Props> = ({ profileInfo }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<Country[]>([]);
  const [birthDate, setBirthDate] = useState<any>(null);
  const [locale] = useState<Locale>(trLocale);
  const [birtDateError, setBirtDateError] = useState<boolean>(false);

  // const [inputs, setInputs] = useState<IProfile>(profileInfo);
  // const handleChange = (event: { target: { name: any; value: any"; }; }) => {
  //   setInputs({
  //     ...inputs,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const editProfile = async (profileFormInput: IProfileSettingsSaveRequest) => {
    try {
      const momentBirtDate = moment(birthDate);

      // checks birthdate is empty
      if (birthDate === null || birthDate === undefined || !momentBirtDate.isValid()) {
        // shows error message for fill the field
        setBirtDateError(true);
        // exit
        return false;
      }

      profileFormInput.birthDate = birthDate;

      const apiClient = axios.create();
      const headers = BaseConfig.utilities.authorizedHeader();
      const response = await apiClient.post<IProfileSettingsSaveRequest>(BaseConfig.api.user('/api/Profile/SaveUserProfile'), profileFormInput, {
        headers: JSON.parse(headers),
      });

      if (!response.data.error) {
        toast.success(t('operation.operationSuccess'));
      } else {
        toast.error(response.data.message);
      }
      setBirtDateError(false);

      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IProfileSettingsSaveRequest) => {
    editProfile(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProfileSettingsSaveRequest>();

  useEffect(() => {
    setBirthDate(moment(profileInfo.birthDate).toDate());
  }, [profileInfo]);

  useEffect(() => {
    Service.User.GetCountriesRequest().then(res => {
      setCountries(res.data.data);
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  {...register('firstName', {
                    required: true,
                  })}
                  fullWidth
                  label={t('label.firstname')}
                  name="firstName"
                  defaultValue={profileInfo.firstName}
                  variant="outlined"
                  className="w-100"
                />
                {errors?.firstName?.type === 'required' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">Bu alan boş bırakılamaz.</span>
                </Box>
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  {...register('lastName', {
                    required: true,
                  })}
                  fullWidth
                  label={t('label.lastname')}
                  name="lastName"
                  defaultValue={profileInfo.lastName}
                  variant="outlined"
                  className="w-100"
                />
                {errors?.firstName?.type === 'required' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">Bu alan boş bırakılamaz.</span>
                </Box>
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('label.emailAddress')}
                  name="email"
                  disabled
                  defaultValue={profileInfo.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <LocalizationProvider sx={{ mb: 3 }} locale={locale} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    mask="__.__.____"
                    label={t('label.birthdate')}
                    value={birthDate}
                    onChange={newValue => {
                      setBirthDate(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        className="w-100"
                        {...params as any}
                      />
                    )}
                  />
                </LocalizationProvider>
                {birtDateError && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">{t('error.inputRequired')}</span>
                </Box>
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <div style={{ width: '100%' }}>
                  <Box
                    sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }}
                  >
                    <TextField
                      {...register('mobileCode')}
                      id="outlined-select-currency"
                      select
                      label={t('label.countryCode')}
                      defaultValue={profileInfo.mobileCode}
                      sx={{ width: 4 / 12 }}
                    >
                      {countries.map((option, key) => (
                        <MenuItem key={key} value={option.phoneCode}>
                          {`+${option.phoneCode} (${option.id})`}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...register('mobileNumber', {
                        pattern: /^(\+9)?0?[5][03-5]\d{8}$/,
                      })}
                      sx={{ width: 8 / 12, marginLeft: '10px' }}
                      fullWidth
                      label={t('label.mobilePhone')}
                      helperText="Örn: 5xxxxxxxxx"
                      defaultValue={profileInfo.mobileNumber}
                      name="mobileNumber"
                      type="text"
                      variant="outlined"
                    />
                    {errors?.mobileNumber?.type === 'pattern' && (
                    <Box sx={{ mt: 1 }}>
                      <span className="error-validation">Geçerli bir telefon numarası girin!</span>
                    </Box>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <div style={{ width: '100%' }}>
                  <Box
                    sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }}
                  >
                    <TextField
                      {...register('phoneCode')}
                      id="outlined-select-currency"
                      sx={{ width: 4 / 12 }}
                      fullWidth
                      select
                      label={t('label.countryCode')}
                      defaultValue={profileInfo.phoneCode}
                      className="w-100"
                    >
                      {countries.map((option, key) => (
                        <MenuItem key={key} value={option.phoneCode}>
                          {`+${option.phoneCode} (${option.id})`}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...register('phoneNumber', {
                      })}
                      sx={{ width: 8 / 12, marginLeft: '10px' }}
                      fullWidth
                      label={t('label.phone')}
                      helperText="Örn: xxxxxxxxxx"
                      defaultValue={profileInfo.phoneNumber}
                      type="text"
                      variant="outlined"
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {t('button.save')}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};
