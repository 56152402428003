import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import RootContext from '../../context/root/RootContext';
import { RootProps } from '../../context/root/RootProps';
import { DASHBOARD, LOGIN_PATH } from '../../routes/Paths';
import Home from '../dashboard';

export const Content = (): JSX.Element => {
  const { authorized } = useContext(AuthContext);
  const { routes } = useContext<RootProps>(RootContext);
  const base = '/:locale(tr|en)?';
  // filters menus that have submenus
  const complexSubMenus = routes.filter(f => f.sub?.length > 0).map(obj => obj.sub);
  let subMenusRoutes: any[] = [];
  complexSubMenus.forEach(curSet => {
    subMenusRoutes = [...subMenusRoutes, ...curSet];
  });

  return (
    <>
      {authorized === true
        ? (
          <Switch>
            {/* submenus */}
            <Route key={DASHBOARD} path={`${base}${DASHBOARD}`} render={() => <Home />} />
            {subMenusRoutes.map(item => (
              <Route key={item.path} path={`${base}${item.path}`} render={() => item.component} />
            ))}
            {/* menus */}
            {routes.map(item => (
              <Route key={item.path} path={`${base}${item.path}`} render={() => item.component} />
            ))}
          </Switch>
        )
        :
          <Redirect to={LOGIN_PATH} />
      }
    </>
  );
};

export default Content;
