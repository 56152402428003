import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { Country } from '../../models/CountriesModel';
import { Service } from '../../services';

const ContactForm = styled.div`
  .title{
    line-height:100px;
    font-size:36px;
    text-align:center ;
    background: rgb(223,225,231);
    background: linear-gradient(180deg, rgba(223,225,231,1) 0%, rgba(244,246,255,1) 30%);
    margin-bottom:24px;
  }
  .form-title{
    font-size:16px;
    text-align:center;
    margin-bottom:24px;
    color:#69717E;
  }
  form{
    margin-bottom:48px;
  }

  @media(max-width:550px) {
    .form-title, .offset-4{
      margin-left:0!important;
    }
  }
`;

export const ContactSection: React.FC = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<Country[]>([]);
  const apiClient = axios.create();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstname: '',
      surname: '',
      mobile_code: '90',
      mobile_number: '',
      email: '',
      application_note: '',
      time: 'Bugün',
      hour: 'Hemen',
    },
  });

  const onSubmit = async (data:any) => {
    try {
      const response = await apiClient.post(
        BaseConfig.api.user('/api/Support/SendTicket'),
        {
          firstName: data.firstname,
          lastName: data.surname,
          phoneCode: data.mobile_code,
          phone: data.mobile_number,
          email: data.email,
          message: data.application_note,
          callTime: data.time,
          callTimeClock: data.hour,
        },
      );
      toast.success(t('operation.operationSuccess'));
      reset();
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  useEffect(() => {
    Service.User.GetCountriesRequest().then(res => {
      setCountries(res.data.data);
    });
  }, []);

  return (
    <ContactForm className="container-fluid">
      <div className="title col-12">{t('contactUs.title')}</div>
      <div className="container">
        <div className="form-title col-6 offset-3 col-sm-12">{t('contactUs.desc')}</div>
        <div className="col-4 offset-4 col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                type="text"
                {...register('firstname', {
                  required: true,
                })}
                placeholder={t('label.firstname')}
                autoComplete="off"
              />
              {errors?.firstname?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                {...register('surname', {
                  required: true,
                })}
                placeholder={t('label.lastname')}
                autoComplete="off"
              />
              {errors?.surname?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-sm-4">
                <div className="form-group">
                  <select
                    {...register('mobile_code', {
                      required: true,
                    })}
                    defaultValue="90"
                    placeholder={t('label.countryCode')}
                  >
                    {countries.map((option, key) => (
                      <option key={key} value={option.phoneCode}>
                        {`+${option.phoneCode} (${option.id})`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-8 col-sm-8">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder={t('label.phone')}
                    {...register('mobile_number', {
                      required: true,
                    })}
                  />
                  {errors?.mobile_number?.type === 'required' && (
                  <span className="error-validation">
                    {t('error.inputRequired')}
                  </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                {...register('email', {
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                placeholder="E-mail"
                autoComplete="off"
              />
              {errors?.email?.type === 'required' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
              {errors?.email?.type === 'pattern' && (
              <span className="error-validation">
                {t('error.inputRequired')}
              </span>
              )}
            </div>
            <div className="form-group">
              <textarea
                {...register('application_note')}
                placeholder={t('landing.yourMessage')}
                rows={4}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <select
                    {...register('time', {
                      required: true,
                    })}
                    defaultValue="Bugün"
                  >
                    <option value="Bugün">{t('contactUs.today')}</option>
                    <option value="Yarın">{t('contactUs.tomorrow')}</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <select
                    {...register('hour', {
                      required: true,
                    })}
                    defaultValue="Hemen"
                  >
                    <option value="Hemen">{t('contactUs.now')}</option>
                    {Array.from(new Array(14).keys()).map((index: number) => (
                      <option value={`${index + 9}:00`}>{`${index + 9}:00`}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="btn-group">
              <button type="submit" className="btn btn-purple block big">{t('contactUs.sendButton')}</button>
            </div>
          </form>
        </div>
      </div>
    </ContactForm>
  );
};
export default ContactSection;
