import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import OtelzLogo from '../images/otelz-logo';
import { CONTACT_PATH, FAQ_PATH } from '../../routes/Paths';

const FooterSectionDiv = styled.div`
  padding-top: 100px;
  background: rgb(84, 98, 217);
  .row {
    .socials {
      font-size: 18px;
      font-weight: 400;
      color: white;
      background: rgb(107, 118, 223);
      border-radius: 16px;
      padding: 32px 16px;
      .links {
        float: right;
        margin: -6px 0 0 0;
        a {
          margin: 0 4px 0 0;
          &:last-child {
            margin: 0 0 0 0;
          }
        }
      }
    }
    .text {
      margin: 8px 0 0 0;
      font-size: 16px;
      line-height: 2.06;
      letter-spacing: 0.18px;
      font-weight: normal;
      text-align: left;
      color: #fff;
    }
    .col-6 {
      ul {
        li {
          padding: 6px 12px;
          a {
            color: White;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    &:nth-child(2) {
      margin-top: 48px;
      margin-bottom: 48px;
      border-bottom: 1px solid white;
      text-align: center;
      svg {
        background-color: rgb(84, 98, 217);
        margin: 0 0 -25px 0;
        padding: 0 24px;
      }
    }
  }
  .container-fluid {
    background-color: white;
    padding: 24px 0.75rem;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: rgb(107, 119, 140);
  }
  @media (max-width: 550px) {
    padding-top: 20px;
    .col-5 {
      order: 2;
    }
    .col-6 {
      order: 1;
      margin: 0 !important;
      > .row {
        ul {
          li {
            margin-bottom: 6px;
            a {
              font-size: 14px;
            }
          }
        }
        .col-sm-12 {
          ul {
            li {
              width: 50%;
              display: inline-block;

              &:nth-child(2) {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
    .row {
      .socials {
        background-color: transparent;
        span {
          display: none;
        }
        .links {
          float: unset;
          margin: -6px 0 0 0;
          a {
            margin: 0 8px 0 0;
            &:last-child {
              margin: 0 0 0 0;
            }
          }
        }
      }
      .text {
        display: none;
      }
      &:nth-child(2) {
        margin-top: 12px;
        margin-bottom: 48px;
        .col-12 {
          svg {
            height: 28px;
            margin: 0 0 -15px 0;
          }
        }
      }
    }
  }
`;

export const FooterSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FooterSectionDiv>
      <div className="container">
        <div className="row">
          <div className="col-5 col-sm-12">
            <div className="socials">
              <span>{t('landing.footerDescSocialMedia')}</span>
              <div className="links">
                <a
                  href="https://www.facebook.com/otelzcom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon fontSize="large" sx={{ color: '#ffffff' }} />
                </a>
                <a
                  href="https://twitter.com/otelzcom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <XIcon fontSize="large" sx={{ color: '#ffffff' }} />
                </a>
                <a
                  href="https://www.instagram.com/otelzcom/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon fontSize="large" sx={{ color: '#ffffff' }} />
                </a>
                <a
                  href="https://www.youtube.com/user/otelzcom?sub_confirmation=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon fontSize="large" sx={{ color: '#ffffff' }} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-12 offset-1">
            <div className="row">
              <div className="col-4 col-sm-6">
                <ul>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/company/about"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkOne')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/legal/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkTwo')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/legal/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkThree')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-4 col-sm-6">
                <ul>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/legal/legal-informations"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkFour')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/legal/legal-text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkFive')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.otelz.com/corporate/legal/cookie-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('landing.footerLinkSix')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-4 col-sm-12">
                <ul>
                  <li>
                    <Link to={FAQ_PATH}>{t('landing.footerLinkSeven')}</Link>
                  </li>
                  <li>
                    <Link to={CONTACT_PATH}>
                      {t('landing.footerLinkEight')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <OtelzLogo height="50" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12">
          {'Copyright © '}
          2012-
          {' '}
          {new Date().getFullYear()}
          {' '}
          Otelz.com
          {' '}
          {t('footer.allRightsReserved')}
          {' '}
          Domestic Turizm - 7882
        </div>
      </div>
    </FooterSectionDiv>
  );
};
export default FooterSection;
