import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CountUp } from 'use-count-up';
import ThumbnailYTPlayer from './YTPlayer';

const VideoSectionDiv = styled.div`
  padding:80px 0;
  background-color: rgb(83, 98, 217);
  .thumbnail-yt-player-landing{
    border: 12px solid rgb(107, 118, 223);
    border-radius: 20px;
    min-width: 0px !important;
  }
  .note{
    padding-top:100px;
    background:url('img/landing/note.svg') .75rem 10px no-repeat;
    background-size: 104px 77px;
    color:var(--oz-white);
    .title{
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 3.6px;
    }
    .counter{
      font-size: 50px;
      font-weight: 800;
      letter-spacing: 7.5px;
      margin-top:12px;
    }
    .desc{
      font-size: 20px;
      line-height: 30px;
      margin-top:18px;
    }
  }
  @media(max-width:550px) {
    padding:30px 0;
    .thumbnail-yt-player-landing{
      min-height:210px;
    }
    .note{
      padding-top:20px;
      text-align:center;
      background:unset ;
      .title{
        font-size:24px;
        letter-spacing: 2.4px;
        line-height:32px ;
      }
      .counter{
        font-size:40px;
      }
      .desc{
      font-size: 16px;
      line-height: 24px;
      margin-top:18px;
    }
    }
  }
`;

export const VideoSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <VideoSectionDiv>
      <div className="container">
        <div className="row">
          <div className="col-6 col-sm-12">
            <ThumbnailYTPlayer
              videoId={t('dashboard.promotionVideoId')}
              thumbnailImage={`https://i.ytimg.com/vi/${t('dashboard.promotionVideoId')}/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLByzjR2IfZRslPNwDqXdCbXkLKSYw`}
              thumbnailTitle={t('dashboard.promotionVideoHeader')}
            />
          </div>
          <div className="col-6 col-sm-12 note">
            <div className="title">
              {t('landing.current')}
              <br />
              {t('landing.totalMember')}
            </div>
            <div className="counter">
              <CountUp isCounting end={1936} duration={5} />
            </div>
            <div className="desc">
              {t('landing.totalMemberDescOne')}
              <br />
              {t('landing.totalMemberDescTwo')}
            </div>
          </div>
        </div>

      </div>
    </VideoSectionDiv>
  );
};
export default VideoSection;
