/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';

const FAQSection = styled.div`
  margin-bottom:24px;
  .title{
    line-height:100px;
    font-size:36px;
    text-align:center ;
    background: rgb(223,225,231);
    background: linear-gradient(180deg, rgba(223,225,231,1) 0%, rgba(244,246,255,1) 30%);
    margin-bottom:24px;
  }
  .form-title{
    font-size:16px;
    text-align:center;
    margin-bottom:24px;
    color:#69717E;
  }
  form{
    margin-bottom:48px;
  }

  @media(max-width:550px) {
    .form-title, .offset-2{
      margin-left:0!important;
    }
  }
`;

const Box = styled.div`
  border:1px solid var(--border-color);
  border-radius:8px;
  margin-top:16px;
  max-height:60px;
  overflow:hidden;
  transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  .question{
    position: relative;
    background-color:#F4F6FF;
    line-height:60px;
    font-size:18px;
    padding:0 16px;
    border-radius:8px 8px 0 0;
    font-weight:var(--font-semi-bold);
    cursor:pointer;
    .arrow{
      position:absolute;
      right:16px;
      top:15px;
      color:#A9B5CB;
      transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
  }
  .answer{
    padding:16px;
    font-size:15px;
  }
  &.active{
    max-height:500px;
    .question{
      font-weight:var(--font-semi-bold);
      .arrow{
        color:var(--font-color);
        transform: rotate(180deg);
      }
    }
  }
`;

export const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const defaultArray = new Array(14).fill(false);
  defaultArray[0] = true;
  const [array, setArray] = useState<Array<boolean>>(defaultArray);

  const setStatus = (i: number, x: boolean) => {
    if (x) {
      array[i] = false;
    } else {
      array.forEach((xx, ii) => {
        if (i !== ii) {
          array[ii] = false;
        }
        array[i] = true;
      });
    }
    setArray([...array]);
  };

  return (
    <FAQSection className="container-fluid">
      <div className="title col-12">{t('landing.faq.title')}</div>
      <div className="container">
        <div className="form-title col-12">{t('landing.faq.subTitle')}</div>
        <div className="col-8 offset-2 col-sm-12">
          {array.map((x, i: number) => (
            <Box className={`box ${x ? 'active' : ''}`}>
              <div className="question" onClick={() => { setStatus(i, x); }} aria-hidden="true">
                {t(`landing.faq.questions.${i}.q`)}
                <KeyboardArrowDownIcon fontSize="large" className="arrow" />
              </div>
              <div className="answer" dangerouslySetInnerHTML={{ __html: t(`landing.faq.questions.${i}.a`) }} />
            </Box>
          ))}
        </div>
      </div>
    </FAQSection>
  );
};
export default FAQ;
