import { IGeneralResponse } from './GeneralModel';

export interface IProfile extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCode: string;
  mobileNumber: string;
  mobileCode: string;
  email: string;
  birthDate?: string;
  userName: string;
  createdOn: string;
  lastModifiedOn: string;
}

export const defaultProfile: IProfile = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneCode: '',
  mobileNumber: '',
  mobileCode: '',
  email: '',
  birthDate: '',
  userName: '',
  createdOn: '',
  lastModifiedOn: '',
} as IProfile;

export interface IProfileSettingsSaveRequest extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  firstName: string;
  lastName: string;
  birthDate?: Date;
  phoneNumber: string;
  phoneCode: string;
  mobileNumber: string;
  mobileCode: string;
}
