import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const InfoSectionDiv = styled.div`
  .container{
    .row{
      padding:100px 0;
      .col-6{
        .title{
          line-height: 1.5;
          letter-spacing: 0.00938em;
          font-size: 34px;
          font-weight: 800;
        }
        .desc{
          margin: 40px 0px 0px;
          font-weight: 400;
          line-height: 1.5;
          text-align: left;
          color: rgb(105, 113, 126);
          font-size: 16px;
          letter-spacing: 0.2px;
          margin-bottom:12px;
          max-width: 540px;
        }
        &:nth-child(2){
          background:url('img/landing/woman.svg') no-repeat center right ;
          background-size:auto 100% ;
          min-height:380px ;
        }
      }
    }
  }
  @media(max-width:550px) {
    .container{
      .row{
        padding:20px 0;
        .col-6{
          text-align:center;
          .title{
            font-size:18px;
            line-height:24px;
          }
          .desc{
            font-size:14px;
            font-weight:500;
            margin-bottom:12px;
            text-align:center;
          }
          &:nth-child(2){
            display:none;
          }
        }
      }
    }
  }
`;

export const InfoSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <InfoSectionDiv>
      <div className="container">
        <div className="row">
          <div className="col-6 col-sm-12">
            <div className="title">
              {t('landing.infoHeaderOne')}
              <br />
              {t('landing.infoHeaderTwo')}
              <br />
              {t('landing.infoHeaderThree')}
            </div>
            <div className="desc">
              {t('landing.infoBodyOne')}
              {' '}
              {t('landing.infoBodyTwo')}
              {' '}
              {t('landing.infoBodyThree')}
              {' '}
              {t('landing.infoBodyFour')}
              {' '}
            </div>
            <Link className="transparent-bg" to="options" spy smooth duration={500}>
              <div className="btn btn-purple big">
                {t('landing.applyNow')}
              </div>
            </Link>
          </div>
          <div className="col-6" />
        </div>
      </div>
    </InfoSectionDiv>
  );
};
export default InfoSection;
