/* eslint-disable no-unused-vars */
import { Alert, Box, Divider, Grid, Paper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/order
import { Breadcrumb, ContactUs } from '../../components/support';
import '../../styles/components/ContactUs.scss';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Support = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb />
      <Box sx={{ mb: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ContactUs />

          </Grid>

          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <Alert severity="info" icon={<CallIcon fontSize="inherit" />}>
              <h4>{t('support.callCenter')}</h4>
              <br />
              <span>
                {t('support.phoneNumbers')}
                {' '}
                <span className="callCenterPhoneNumber">0850 333 0 220</span>
              </span>

            </Alert>
            <Divider />
            <Alert sx={{ marginTop: 2 }} severity="info" icon={<EmailIcon fontSize="inherit" />} variant="outlined">
              <h4>{t('support.phoneNumbers')}</h4>
              <br />
              <span>
                {t('support.emailAddressHaveDepartment')}
              </span>
              <br />
              <br />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                <Grid item xs={6}>
                  <Item>
                    {t('support.partnerSupport')}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>{t('support.partnerSupportMail')}</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    {t('support.finance')}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>{t('support.financeMail')}</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    {t('support.marketing')}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>marketing@otelz.com</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    {t('support.suggestionsAndComplaints')}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>{t('support.suggestionsAndComplaintsMail')}</Item>
                </Grid>
              </Grid>
            </Alert>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Support;
