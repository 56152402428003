/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material/';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SubjectIcon from '@mui/icons-material/Subject';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { green } from '@material-ui/core/colors';
import { Line } from '@reactchartjs/react-chart.js';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUp from '@mui/icons-material/TrendingUp';
import moment from 'moment';
import {
  LatestReservations,
  SalesCancellationGraph,
} from '../../components/dashboard';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { IGeneralResponse } from '../../models/GeneralModel';
import localePrice from '../../utilities/localePriceHelper';
import { RESERVATION_REPORT_PATH } from '../../routes/Paths';

interface IRevenueProfit extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  reservationTotal: number;
  usedReservationTotal: number;
  unUsedReservationTotal: number;
  commissionTotal: number;
  usedCommisionTotal: number;
  unUsedCommisionTotal: number;
}

export const defaultIRevenueProfitProps: IRevenueProfit = {
  // kazanç
  commissionTotal: 0,
  usedCommisionTotal: 0,
  unUsedCommisionTotal: 0,
  // satış
  reservationTotal: 0,
  usedReservationTotal: 0,
  unUsedReservationTotal: 0,

} as IRevenueProfit;

function getMonthAndYear(month: number, year:number): string {
  return `${month}/${year.toString().slice(-2)}`;
}

export const Home: React.FC = () => {
  const { t } = useTranslation();

  const [revenueProfit, setRevenueProfit] = React.useState<IRevenueProfit>(
    defaultIRevenueProfitProps,
  );
  const [thisMonthCommissionTotal, setThisMonthCommissionTotal] = useState<number>(0);
  const [thisMonthReservationTotal, setThisMonthReservationTotal] = useState<number>(0);
  const [salesAmount, setSalesAmount] = useState<number>(0);
  const [cancelAmount, setCancelAmount] = useState<number>(0);
  const [totalProfitGraph, setTotalProfitGraph] = useState<Array<any>>(
    [],
  );
  const [totalRevenueGraph, setTotalRevenueGraph] = useState<Array<any>>(
    [],
  );
  const [labelProfitGraph, setLabelProfitGraph] = useState<any>([]);
  const [labelRevenueGraph, setLabelRevenueGraph] = useState<any>([]);

  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [years, setYears] = useState<number[]>([]);
  const currentYear = Number(moment(new Date()).format('YYYY'));
  const [selectedYear, setSelectedYear] = React.useState<number>();

  const currencyLocale = Intl.NumberFormat('tr-TR');

  const getdashboardReservationSummary = async (filterYear:number) => {
    try {
      setLoading(true);
      setTotalProfitGraph([]);
      setTotalRevenueGraph([]);
      const apiClient = axios.create();
      const headers = BaseConfig.utilities.authorizedHeader();

      const param = {
        partnerId: Number.isNaN(BaseConfig.utilities.partnerId()) ? BaseConfig.utilities.partnerIdInUserObject() : BaseConfig.utilities.partnerId(),
        year: filterYear,
      };

      const response = await apiClient.post(
        BaseConfig.api.user('/api/Report/dashboardReservationSummary'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      const responseThisMonth = await apiClient.post(
        BaseConfig.api.user('/api/Report/dashboardSummaryForCurrentYear'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );

      setThisMonthCommissionTotal(responseThisMonth.data.data.thisMontSummaryViewModel.thisMonthCommissionTotal);
      setThisMonthReservationTotal(responseThisMonth.data.data.thisMontSummaryViewModel.thisMonthReservationTotal);

      const labelProfitGraphArray = [];
      for (let i = 0; i < response.data.data?.totalProfitGraph.length; i++) {
        labelProfitGraphArray.push(getMonthAndYear(response.data.data?.totalProfitGraph[i].month, response.data.data?.totalProfitGraph[i].year));
      }
      setLabelProfitGraph(labelProfitGraphArray);

      const totalProfitGraphArray = [];
      for (let i = 0; i < response.data.data?.totalProfitGraph.length; i++) {
        totalProfitGraphArray.push(Object.values(response.data.data?.totalProfitGraph[i])[0]);
      }
      setTotalProfitGraph(totalProfitGraphArray);

      const labelRevenueGraphArray = [];
      for (let i = 0; i < response.data.data?.totalRevenueGraph.length; i++) {
        labelRevenueGraphArray.push(getMonthAndYear(response.data.data?.totalRevenueGraph[i].month, response.data.data?.totalRevenueGraph[i].year));
      }
      setLabelRevenueGraph(labelRevenueGraphArray);

      const totalRevenueGraphArray = [];
      for (let i = 0; i < response.data.data?.totalRevenueGraph.length; i++) {
        totalRevenueGraphArray.push(Object.values(response.data.data?.totalRevenueGraph[i])[0]);
      }
      setTotalRevenueGraph(totalRevenueGraphArray);

      setRevenueProfit(response.data.data?.revenueProfit);
      setSalesAmount(
        response.data.data?.salesCancellationGraph?.definiteReservationCount,
      );
      setCancelAmount(
        response.data.data?.salesCancellationGraph?.canceledReservationCount,
      );
      setIsRequest(true);
      setLoading(false);
      return true;
    } catch (err) {
      if (err && err.response && totalProfitGraph && totalRevenueGraph) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const loadYears = () => {
    const yearList = [];
    for (let index = currentYear; index > 2018; index--) {
      yearList.push(index);
    }
    setYears(yearList);
  };

  const handleChange = (event: SelectChangeEvent) => {
    getdashboardReservationSummary(Number(event.target.value));
    setSelectedYear(Number(event.target.value));
  };

  useEffect(() => {
    loadYears();
    setSelectedYear(currentYear);
    getdashboardReservationSummary(currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataProfit = {
    labels: labelProfitGraph,
    datasets: [
      {
        label: `${t('dashboard.revenue')}(₺)`,
        data: totalProfitGraph,
        borderColor: '#5664d2',
        borderWidth: 2,
        pointBorderWidth: 3,
        pointHoverBorderWidth: 8,
      },
    ],
  };

  const dataRevenue = {
    labels: labelRevenueGraph,
    datasets: [
      {
        label: `${t('dashboard.profit')}(₺)`,
        data: totalRevenueGraph,
        borderColor: '#5664d2',
        borderWidth: 2,
        pointBorderWidth: 3,
        pointHoverBorderWidth: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label(x:any, y:any) {
          const xLabel = y.datasets[x.datasetIndex].label;
          const yLabel = currencyLocale.format(x.yLabel);
          return `${xLabel}: ${yLabel}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  if (!loading) {
    return (
      <>
        <Grid container spacing={3}>
          {/* Bu ay kazanç */}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card sx={{ background: '#80f4ff' }}>
              <CardContent sx={{ padding: 0, paddingBottom: '10px !important' }}>
                <Box sx={{ background: '#32c5d2', color: '#fff4f4' }}>
                  <Container maxWidth={false}>
                    <Grid container>
                      <Grid item lg={6} sm={6} xl={6} xs={6}>
                        <AssessmentIcon sx={{ fontSize: '100px', color: '#2aa9b5' }} />
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={6} sx={{ textAlign: 'right' }}>
                        <Typography sx={{ fontSize: '40px', paddingTop: '39px' }}>
                          {localePrice('tr', thisMonthCommissionTotal, 'TL', 2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={3} sx={{ flexDirection: 'row-reverse', paddingTop: '27px' }}>
                      <Typography sx={{ fontSize: '17px' }}>
                        {t('dashboard.thisMonthCommTotal')}
                      </Typography>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Bu ay satış */}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card sx={{ background: '#fa989f' }}>
              <CardContent sx={{ padding: 0, paddingBottom: '10px !important' }}>
                <Box sx={{ background: '#e7505a', color: '#fff4f4' }}>
                  <Container maxWidth={false}>
                    <Grid container>
                      <Grid item lg={6} sm={6} xl={6} xs={6}>
                        <TrendingUp sx={{ fontSize: '100px', color: '#c3363f' }} />
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={6} sx={{ textAlign: 'right' }}>
                        <Typography sx={{ fontSize: '40px', paddingTop: '39px' }}>
                          {localePrice('tr', thisMonthReservationTotal, 'TL', 2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={3} sx={{ flexDirection: 'row-reverse', paddingTop: '27px' }}>
                      <Typography sx={{ fontSize: '17px' }}>
                        {t('dashboard.thisMonthResTotal')}
                      </Typography>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Kazanç Panosu */}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card>
              <CardHeader
                title={(
                  <Typography color="text.secondary" variant="h5">
                    {t('dashboard.commissionDashboard')}
                  </Typography>
                  )}
              />
              <Divider />
              <CardContent>
                <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>

                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.commissionTotal')}
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                  {localePrice('tr', revenueProfit.commissionTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: 'primary.main',
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <SubjectIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.usedCommisionTotal')}
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                  {localePrice('tr', revenueProfit.usedCommisionTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: green[600],
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <CheckIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        {' '}
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.unUsedCommisionTotal')}
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                  {localePrice('tr', revenueProfit.unUsedCommisionTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: '#fcf75e',
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <HourglassEmptyIcon
                                    sx={{ color: 'text.secondary' }}
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                              <Line
                                data={dataRevenue}
                                options={options}
                                type={undefined}
                              />
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Satış Panosu */}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card>
              <CardHeader
                sx={{ paddingTop: '5px', paddingBottom: '5px' }}
                title={(
                  <Typography color="text.secondary" variant="h5">
                    {t('dashboard.reservationDashboard')}
                  </Typography>
                  )}
                action={(
                  <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                    <InputLabel id="demo-simple-select-label">Yıl</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedYear?.toString()}
                      label="Yıl"
                      onChange={handleChange}
                    >
                      {years.map(year => (
                        <MenuItem
                          key={year}
                          value={year}
                        >
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                    )}
              />
              <Divider />
              <CardContent>
                <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.reservationTotal')}
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                  {localePrice('tr', revenueProfit.reservationTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: 'primary.main',
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <SubjectIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.usedResTotal')}
                                </Typography>
                                <Typography color="textPrimary" variant="h3">
                                  {localePrice('tr', revenueProfit.usedReservationTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: green[600],
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <CheckIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  color="text.secondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  {t('dashboard.unUsedResTotal')}
                                </Typography>
                                <Typography color="text.primary" variant="h3">
                                  {localePrice('tr', revenueProfit.unUsedReservationTotal, 'TL', 2)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar
                                  sx={{
                                    backgroundColor: '#fcf75e',
                                    height: 56,
                                    width: 56,
                                  }}
                                >
                                  <HourglassEmptyIcon
                                    sx={{ color: 'text.secondary' }}
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                              <Line
                                data={dataProfit}
                                options={options}
                                type={undefined}
                              />
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Rezervasyon Panosu */}
          <Grid item lg={8} sm={8} xl={8} xs={12}>
            <Card>
              <CardHeader
                title={(
                  <Typography color="text.secondary" variant="h5">
                    {t('dashboard.latestReservations')}
                  </Typography>
                  )}
                action={(
                  <Link to={RESERVATION_REPORT_PATH}>
                    <Button
                      className="w-100"
                      size="medium"
                      type="submit"
                      variant="outlined"
                      color="primary"
                      style={{ lineHeight: '28px' }}
                    >
                      {t('dashboard.goToAllReservation')}
                    </Button>
                  </Link>
                  )}
              />
              <Divider />
              <CardContent>
                <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <LatestReservations />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Satış/İptal Panosu */}
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Card>
              <CardHeader
                title={(
                  <Typography color="text.secondary" variant="h5">
                    {t('dashboard.sellCancellationRate')}
                  </Typography>
                  )}
              />
              <Divider />
              <CardContent>
                <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
                  <Container maxWidth={false}>
                    <Grid container spacing={3}>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        {isRequest && (
                        <SalesCancellationGraph
                          salesAmount={salesAmount}
                          cancelAmount={cancelAmount}
                        />
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Grid
      style={{
        position: 'absolute',
        left: '42%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default Home;
