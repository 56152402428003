import { Link, useLocation } from 'react-router-dom';
import ScrollBar from 'react-perfect-scrollbar';
import { useContext, useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DrawerContext from '../../context/drawer/DrawerContext';
import { AuthContext, Context } from '../../context/AuthContext';
import { DrawerProps } from '../../context/drawer/DrawerContextProps';
import DropDownMenu from './DropdownMenu';
import { classList } from '../../helpers/Utils';
import { RootProps } from '../../context/root/RootProps';
import RootContext from '../../context/root/RootContext';
import { DASHBOARD, PROFILE_PATH } from '../../routes/Paths';
import PartnerLandingLogo from '../images/logo-landing';
import Language from './language/Language';
// import { AppLanguage } from '../../const/app-languages';

export default function LayoutSidebar() {
  const { roleId, authorized } = useContext<Context>(AuthContext);
  const { routes } = useContext<RootProps>(RootContext);
  const [fullname, setFullname] = useState <String>('');
  const [selectedItem, setSelectedItem] = useState<any>({});
  const context = useContext<DrawerProps>(DrawerContext);
  const { t } = useTranslation();
  const onMainItemMouseEnter = (item: any) => {
    context.toggleSecondary(true);
    if (item.type === 'dropDown') {
      setSelectedItem(item);
    } else {
      setSelectedItem(item);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (window.screen.width <= 550) {
      context.close();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, window]);

  useEffect(() => {
    if (authorized) {
      if (localStorage.getItem('user') !== null) {
        setFullname(JSON.parse(localStorage.getItem('user') || '').name);
      }
    }
  }, [authorized]);

  const onMainItemMouseLeave = () => {
    context.toggleSecondary(false);
  };

  // function generatePath(path: string) {
  //   const langPath = window.location.pathname.split('/')[1];

  //   if ((Object.values(AppLanguage) as string[]).includes(langPath)) {
  //     return `/${langPath}${path}`;
  //   }
  //   return path;
  // }

  if (selectedItem) {
    return (
      <div className="side-content-wrap">
        <ScrollBar
          className={classList({
            'sidebar-left o-hidden rtl-ps-none': true,
            open: context.isShow,
          })}
        >
          <ul className="navigation-left">
            {authorized === true
              && routes.filter(item => item.roles.includes(roleId))
                .map((item, i) => (
                  <li
                    className={classList({
                      'nav-item': true,
                      active: item.title === selectedItem.title,
                    })}
                    onMouseEnter={() => {
                      onMainItemMouseEnter(item);
                    }}
                    onMouseLeave={onMainItemMouseLeave}
                    key={i}
                  >
                    {item.path && item.type !== 'extLink' && (
                      <div className="nav-item-hold">
                        {item.icon}
                        <span className="nav-text">
                          {t(`partnerMenu.${item.title}`)}
                        </span>
                      </div>
                    )}
                    {item.path && item.type === 'extLink' && (
                      <a className="nav-item-hold" href={item.path}>
                        {item.icon}
                        <span className="nav-text">{t(`partnerMenu.${item.title}`)}</span>
                      </a>
                    )}
                    {!item.path && (
                      <div className="nav-item-hold">
                        {item.icon}
                        <span className="nav-text">{t(`partnerMenu.${item.title}`)}</span>
                      </div>
                    )}
                    <div className="triangle" />
                  </li>
                ))
            }
          </ul>
        </ScrollBar>

        <ScrollBar
          className={classList({
            'sidebar-left-secondary o-hidden rtl-ps-none': true,
            'sidenav-open': context.secondaryIsShow,
          })}
        >
          <div className="close" onClick={() => context.toggle()} aria-hidden="true">X</div>
          <div className="logo">
            <a href="/">
              <PartnerLandingLogo height="48" />
            </a>
          </div>
          <ul className="childNav">
            <li className="nav-item">
              <Link className="nav-item" to={DASHBOARD}>
                <HomeIcon />
                <span className="nav-text">
                  {t('partnerMenu.mainPage')}
                </span>
              </Link>
            </li>
          </ul>
          <div className="header">
            <h5 className="font-weight-bold">{selectedItem.name}</h5>
            {
              (selectedItem.description !== undefined) ? <p>{t(`partnerMenu.${selectedItem.description}`)}</p> : false
            }
          </div>
          {selectedItem && selectedItem.sub && <DropDownMenu menu={selectedItem.sub} />}
          <Language />
          <div className="profile-bar" style={{ marginRight: '15px', marginLeft: '15px' }}>

            <Link to={PROFILE_PATH}>
              <Button startIcon={<AccountCircleIcon />} color="inherit">
                {fullname}
              </Button>
            </Link>
          </div>
        </ScrollBar>
        <div
          className={classList({
            'sidebar-overlay': true,
            open: context.isShow,
          })}
        />
      </div>
    );
  }
  return <Divider />;
}
