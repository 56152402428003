import { Box, Button, Card, CardContent, Divider, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { IContactUsRequest } from '../../models/ContactUsModel';
import '../../styles/components/ContactUs.scss';

export const ContactUs = (): JSX.Element => {
  const { t } = useTranslation();
  const apiClient = axios.create();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IContactUsRequest>();

  const onSubmit = async (data: IContactUsRequest) => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();
      data.name = BaseConfig.utilities.user().name;
      data.partnerId = BaseConfig.utilities.partnerId();
      data.globalName = BaseConfig.utilities.partner().globalName;
      data.email = BaseConfig.utilities.user().email;

      const response = await apiClient.post<IContactUsRequest>(BaseConfig.api.user('/api/Support/SendContactMessage'), data, {
        headers: JSON.parse(headers),
      });

      toast.success(t('operation.operationSuccess'));
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Typography sx={{ padding: 1, marginLeft: 1 }} variant="h5" component="div">
            {t('support.canHelpDesc')}
          </Typography>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  {...register('subject', {
                    required: true,
                  })}
                  fullWidth
                  label={t('support.subject')}
                  name="subject"
                  variant="outlined"
                />
                {errors?.subject?.type === 'required' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">
                    {t('error.inputRequired')}
                  </span>
                </Box>
                )}
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  {...register('phoneNumber', {
                    required: true,
                    pattern: /^(\+9)?0?[5][03-5]\d{8}$/,
                  })}
                  fullWidth
                  label={t('label.mobilePhone')}
                  name="phoneNumber"
                  variant="outlined"
                />
                {errors?.phoneNumber?.type === 'required' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">
                    {t('error.inputRequired')}
                  </span>
                </Box>
                )}
                {errors?.phoneNumber?.type === 'pattern' && (
                <Box sx={{ mt: 1 }}>
                  <span className="error-validation">Geçerli bir telefon numarası girin!</span>
                </Box>
                )}
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Box>
                  <TextareaAutosize
                    minRows={15}
                    {...register('message', {
                      required: true,
                    })}
                    placeholder={t('support.messages')}
                    className="customTextArea"
                  />
                  {errors?.message?.type === 'required' && (
                  <Box sx={{ mt: 1 }}>
                    <span className="error-validation">
                      {t('error.inputRequired')}
                    </span>
                  </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {t('support.send')}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ContactUs;
