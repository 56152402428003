export const BaseConfig = {
  config: {
    debug: () => {
      if (/.dev./.test(window.location.href)) {
        return 'dev';
      }
      if (/.stg./.test(window.location.href)) {
        return 'stage';
      }
      if (/.app./.test(window.location.href)) {
        return 'app';
      }
      if (/.contact./.test(window.location.href)) {
        return 'contact';
      }
      if (/.www4./.test(window.location.href)) {
        return 'otelz';
      }
      // @ts-ignore:next-line
      // eslint-disable-next-line no-unused-vars
      if (/param/.test((param: string) => {})) {
        return 'debug';
      }
      return 'release';
    },
  },
  api: {
    dev: {
      user: 'https://partner.dev.otelz.com',
    },
    stage: {
      user: 'https://pre-prod-partner.oz-stg.otelz.com',
    },
    app: {
      user: 'https://partner-app.otelz.com',
    },
    contact: {
      user: 'https://contact.otelz.com',
    },
    otelz: {
      user: 'https://www4.otelz.com',
    },
    release: {
      user: 'https://partner.otelz.com',
    },
    debug: {
      user: 'http://localhost:5023',
      // user: 'https://partner.dev.otelz.com',
    },
    // @ts-ignore:next-line
    user: (url: string) =>
      // @ts-ignore:next-line
      BaseConfig.api[BaseConfig.config.debug()].user.concat(url),
  },
  request: {
    axios: () => [
      {
        baseURL: BaseConfig.api.user(''),
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ],
  },
  utilities: {
    authorizedHeader: (): string => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      if (Object.keys(user).length !== 0) {
        return JSON.stringify({ Authorization: `Bearer ${user?.token}` });
      }
      return JSON.stringify({ 'Content-Type': 'application/json' });
    },
    user: () => JSON.parse(localStorage.getItem('user') || '[]'),
    partnerIdInUserObject: () : number => Number(JSON.parse(localStorage.getItem('user') || '[]').partnerId),
    partnerId: () : number => Number(JSON.parse(localStorage.getItem('partner') || '[]').partnerId),
    partner: () => JSON.parse(localStorage.getItem('partner') || '[]'),
    userToken: () => JSON.parse(localStorage.getItem('user') || '[]').token,
    roles: () => JSON.parse(localStorage.getItem('user') || '[]').roleList,
    securityId: () : number => Number(JSON.parse(localStorage.getItem('user') || '').securityId),
    currentCode: () : string => JSON.parse(localStorage.getItem('partner') || '').currentCode,
  },
};

export default BaseConfig;

export interface IncomingHttpHeaders {
  authorization?: string;
  [header: string]: string | string[] | undefined;
}
