interface Props {
    height: string;
  }

export const PartnerLandingLogo: React.FC<Props> = ({ height }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 272.456 66">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2171" data-name="Rectangle 2171" width="126.259" height="40.114" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_5468" data-name="Group 5468" transform="translate(-360 -12.5)">
        <g id="Group_5380" data-name="Group 5380" transform="translate(360 24)">
          <g id="Group_5376" data-name="Group 5376" transform="translate(0 0)" clipPath="url(#clip-path)">
            <path id="Path_3561" data-name="Path 3561" d="M178.423,20.928A15.764,15.764,0,0,1,183.216,32.5a15.745,15.745,0,0,1-4.793,11.552,16.317,16.317,0,0,1-23.1,0A15.746,15.746,0,0,1,150.526,32.5a15.765,15.765,0,0,1,4.793-11.568,16.354,16.354,0,0,1,23.1,0m-17.4,5.716a8.287,8.287,0,0,0,0,11.7A8.271,8.271,0,0,0,175.142,32.5a8.275,8.275,0,0,0-14.123-5.852" transform="translate(-150.526 -8.726)" fill="#e0004d" />
            <path id="Path_3562" data-name="Path 3562" d="M237.037,8.453h7.258a.428.428,0,0,1,.484.484v5.171a2.428,2.428,0,0,1-2.434,2.419h-5.307V28.653a3.165,3.165,0,0,0,.847,2.45,4.615,4.615,0,0,0,3.205.937,10.961,10.961,0,0,0,2.587-.215c.233-.034.585-.207.831-.1a.432.432,0,0,1,.273.423v7.133a.43.43,0,0,1-.378.469,14.825,14.825,0,0,1-3.311.363,12.3,12.3,0,0,1-8.664-3.084,10.827,10.827,0,0,1-3.462-8.377V.485A.427.427,0,0,1,229.447,0h1.028a6.356,6.356,0,0,1,4.642,1.92,6.357,6.357,0,0,1,1.92,4.641Z" transform="translate(-192.907 0)" fill="#e0004d" />
            <path id="Path_3563" data-name="Path 3563" d="M295.317,20.928a11.844,11.844,0,0,1,2.751,4.385,9.148,9.148,0,0,1,.515,5.111,5.75,5.75,0,0,1-2.148,3.508,6.366,6.366,0,0,1-3.975,1.254H275.933a8.19,8.19,0,0,0,1.981,3.161,8.287,8.287,0,0,0,11.7,0q.225-.227.423-.468a.488.488,0,0,1,.377-.167h8.15a.438.438,0,0,1,.392.212.46.46,0,0,1,.061.453,16.05,16.05,0,0,1-3.7,5.671,16.318,16.318,0,0,1-23.1,0A15.746,15.746,0,0,1,267.42,32.5a15.765,15.765,0,0,1,4.793-11.568,16.354,16.354,0,0,1,23.1,0m-11.552,3.3A8.262,8.262,0,0,0,276.1,29.38h13.26a1.059,1.059,0,0,0,1.089-.68q.349-.892-.83-2.056a7.974,7.974,0,0,0-5.852-2.419" transform="translate(-213.687 -8.726)" fill="#e0004d" />
            <path id="Path_3564" data-name="Path 3564" d="M343.553.485A.484.484,0,0,1,344.037,0h1.113a6.477,6.477,0,0,1,6.477,6.477V38.6a.484.484,0,0,1-.484.484h-7.107a.484.484,0,0,1-.484-.484Z" transform="translate(-254.823 0)" fill="#e0004d" />
            <path id="Path_3565" data-name="Path 3565" d="M370.359,18.87a.484.484,0,0,1,.484-.484H393.99a.524.524,0,0,1,.473.237.7.7,0,0,1-.04.606L382,40.946h8.974a2.471,2.471,0,0,1,1.7.65,2.342,2.342,0,0,1,.772,1.769v5.171a.484.484,0,0,1-.484.484H368.907a.454.454,0,0,1-.423-.242.468.468,0,0,1,0-.484l12.49-21.834h-4.2a6.416,6.416,0,0,1-6.416-6.416Z" transform="translate(-268.258 -9.934)" fill="#e0004d" />
          </g>
        </g>
        <line id="Line_3" data-name="Line 3" y2="66" transform="translate(503.718 12.5)" fill="none" stroke="#c3cad6" strokeWidth="1" />
        <path id="Path_3594" data-name="Path 3594" d="M-52.344,0V-9.54h6.66c4.752,0,7.524-3.312,7.524-7.236s-2.7-7.236-7.524-7.236h-9.648V0Zm11.088-16.776A4.463,4.463,0,0,1-46.044-12.2h-6.3v-9.144h6.3A4.463,4.463,0,0,1-41.256-16.776ZM-21.456,0V-11.952c0-4.212-3.06-5.868-6.732-5.868a9.1,9.1,0,0,0-6.948,2.88l1.26,1.872a6.907,6.907,0,0,1,5.328-2.412c2.52,0,4.392,1.332,4.392,3.672v3.132a7.27,7.27,0,0,0-5.76-2.376C-32.868-11.052-36-9.216-36-5.328c0,3.78,3.132,5.76,6.084,5.76a7.521,7.521,0,0,0,5.76-2.412V0Zm-2.7-3.672a5.823,5.823,0,0,1-4.788,2.16c-2.52,0-4.284-1.584-4.284-3.78,0-2.232,1.764-3.816,4.284-3.816a5.823,5.823,0,0,1,4.788,2.16ZM-13.356,0V-12.312A6.361,6.361,0,0,1-8.64-15.084a4.759,4.759,0,0,1,1.08.108v-2.772a7.391,7.391,0,0,0-5.8,3.168v-2.808h-2.7V0ZM1.44.432A4.413,4.413,0,0,0,4.68-.684L3.888-2.7a2.605,2.605,0,0,1-1.836.72C.864-1.98.288-2.916.288-4.212v-10.8H3.816v-2.376H.288V-22.14h-2.7v4.752h-2.88v2.376h2.88V-3.636C-2.412-1.044-1.116.432,1.44.432ZM23.292,0V-12.2c0-3.78-1.908-5.616-5.544-5.616a8.625,8.625,0,0,0-6.192,2.952v-2.52h-2.7V0h2.7V-12.708a6.713,6.713,0,0,1,5.148-2.7c2.34,0,3.888.972,3.888,4.032V0Zm4.392-8.712c0,5.436,3.708,9.144,8.856,9.144a9.446,9.446,0,0,0,6.912-2.664L42.156-4a7.6,7.6,0,0,1-5.364,2.2,6.127,6.127,0,0,1-6.264-6.012H44.6V-8.5c0-5.22-3.1-9.324-8.352-9.324C31.284-17.82,27.684-13.752,27.684-8.712Zm8.532-6.876a5.636,5.636,0,0,1,5.724,5.8H30.528C30.672-12.456,32.508-15.588,36.216-15.588ZM51.66,0V-12.312a6.361,6.361,0,0,1,4.716-2.772,4.759,4.759,0,0,1,1.08.108v-2.772a7.391,7.391,0,0,0-5.8,3.168v-2.808h-2.7V0Z" transform="translate(575 59)" fill="#243645" />
      </g>
    </svg>
  </>
);

export default PartnerLandingLogo;
