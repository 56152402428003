import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios, { AxiosError } from 'axios';
import { TableHead, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseConfig from '../../config/BaseConfig';
import { Breadcrumb } from '../../components/current-account';
import localePrice from '../../utilities/localePriceHelper';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line no-unused-vars
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="ilk sayfa"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="sıradaki sayfa"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="son sayfa"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

type ServerError = {
  code: string;
  description: string;
};

type CurrentAccountTypes = {
  // eslint-disable-next-line camelcase
  belge_No: string
  aciklama: string
  bakiye: number
  borc: number
  alacak: number
  tarih: string
};

export const CurrentAccount = (): JSX.Element => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentAccountRows, setCurrentAccountRows] = React.useState <Array<CurrentAccountTypes>>([]);
  const [debtTotalLabel, setDebtTotalLabel] = React.useState<string>('0');
  const [creditTotalLabel, setCreditTotalLabel] = React.useState<string>('0');
  const [balanceLabel, setBalanceLabel] = React.useState<string>('0');

  const getTable = async () => {
    try {
      const apiClient = axios.create();

      const headers = BaseConfig.utilities.authorizedHeader();
      const param = {
        currentCode: BaseConfig.utilities.currentCode(),
      };
      const response = await apiClient.post(BaseConfig.api.user('/api/Finance/GetCurrentAccountList'), param, {
        headers: JSON.parse(headers),
      });

      setDebtTotalLabel(response.data.data?.debtTotalLabel);
      setCreditTotalLabel(response.data.data?.creditTotalLabel);
      setBalanceLabel(response.data.data?.balanceLabel);
      // create new array to push coming value to inside
      setCurrentAccountRows(response.data.data?.currentAccounts === undefined ? [] : response.data.data?.currentAccounts);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentAccountRows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Breadcrumb />

      <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>

        <Typography
          sx={{ flex: '1 1 100%', marginBottom: 2 }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {t('currentAccount.currentAccountList')}
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('currentAccount.date')}</TableCell>
                <TableCell align="left">{t('currentAccount.documentNumber')}</TableCell>
                <TableCell align="left">{t('common.description')}</TableCell>
                <TableCell align="left">{t('currentAccount.debt')}</TableCell>
                <TableCell align="left">{t('currentAccount.receivable')}</TableCell>
                <TableCell align="left">{t('currentAccount.balance')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? currentAccountRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : currentAccountRows
              ).map(row => (
                <TableRow key={row.belge_No}>
                  <TableCell style={{ width: 160 }} align="left">
                    {moment(row.tarih).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.belge_No}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.aciklama}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.borc, 'TL', 2)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.alacak, 'TL', 2)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.bakiye, 'TL', 2)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell style={{ fontWeight: 'bold' }}>{t('currentAccount.summary')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  { localePrice('tr', debtTotalLabel, 'TL', 2) }
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  { localePrice('tr', creditTotalLabel, 'TL', 2) }
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  {localePrice('tr', balanceLabel, 'TL', 2)}
                  {(parseInt(debtTotalLabel, 10) - (parseInt(creditTotalLabel, 10)) > 0)
                    ? ` ${t('currentAccount.debtor')}`
                    : ` ${t('currentAccount.receivable')}`
                  }
                </TableCell>
              </TableRow>
              {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={currentAccountRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CurrentAccount;
