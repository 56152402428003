import { ToastContainer } from 'react-toastify';
import HeaderSection from '../../components/newLanding/Header';
import ContactSection from '../../components/newLanding/Contact';
import FooterSection from '../../components/newLanding/Footer';

export const Contact: React.FC = () => (
  <main className="landing">
    <HeaderSection />
    <ContactSection />
    <FooterSection />
    <ToastContainer />
  </main>
);
export default Contact;
