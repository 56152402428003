interface Props {
  height: string;
}

export const OtelzLogo: React.FC<Props> = ({ height }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 281.452 45.45">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2205" data-name="Rectangle 2205" className="cls-1" width="221.452" height="45.45" fill="#fff" />
        </clipPath>
      </defs>
      <g id="Group_5465" data-name="Group 5465" className="cls-2">
        <path id="Path_3592" data-name="Path 3592" className="cls-1" d="M182.134,21.563a17.861,17.861,0,0,1,5.431,13.107,17.839,17.839,0,0,1-5.431,13.088,18.488,18.488,0,0,1-26.177,0,17.841,17.841,0,0,1-5.431-13.088,17.862,17.862,0,0,1,5.431-13.107,18.529,18.529,0,0,1,26.177,0m-19.718,6.476a9.389,9.389,0,0,0,0,13.26,9.371,9.371,0,0,0,16-6.63,9.376,9.376,0,0,0-16-6.631" transform="translate(-72.129 -7.739)" fill="#fff" />
        <path id="Path_3593" data-name="Path 3593" className="cls-1" d="M238.111,9.577h8.223a.485.485,0,0,1,.548.548v5.859a2.751,2.751,0,0,1-2.758,2.741h-6.013v13.74a3.586,3.586,0,0,0,.959,2.775A5.228,5.228,0,0,0,242.7,36.3a12.419,12.419,0,0,0,2.931-.243c.264-.039.663-.234.941-.113a.49.49,0,0,1,.309.48v8.082a.487.487,0,0,1-.429.532,16.8,16.8,0,0,1-3.752.411,13.941,13.941,0,0,1-9.817-3.495,12.267,12.267,0,0,1-3.923-9.491V.549A.484.484,0,0,1,229.511,0h1.165a7.2,7.2,0,0,1,5.26,2.175,7.2,7.2,0,0,1,2.175,5.259Z" transform="translate(-109.714 0)" fill="#fff" />
        <path id="Path_3594" data-name="Path 3594" className="cls-1" d="M299.028,21.563a13.419,13.419,0,0,1,3.117,4.968,10.365,10.365,0,0,1,.584,5.791,6.515,6.515,0,0,1-2.434,3.975,7.213,7.213,0,0,1-4.5,1.421H277.065a9.279,9.279,0,0,0,2.244,3.581,9.389,9.389,0,0,0,13.261,0q.255-.257.48-.531a.553.553,0,0,1,.428-.189h9.235a.5.5,0,0,1,.444.24.521.521,0,0,1,.069.514,18.185,18.185,0,0,1-4.2,6.425,18.488,18.488,0,0,1-26.178,0A17.841,17.841,0,0,1,267.42,34.67a17.862,17.862,0,0,1,5.431-13.107,18.53,18.53,0,0,1,26.178,0M285.939,25.3a9.361,9.361,0,0,0-8.686,5.842h15.024a1.2,1.2,0,0,0,1.233-.771q.4-1.01-.941-2.33a9.034,9.034,0,0,0-6.631-2.741" transform="translate(-128.142 -7.739)" fill="#fff" />
        <path id="Path_3595" data-name="Path 3595" className="cls-1" d="M343.553.549A.548.548,0,0,1,344.1,0h1.261A7.339,7.339,0,0,1,352.7,7.34v36.4a.548.548,0,0,1-.548.548H344.1a.548.548,0,0,1-.548-.548Z" transform="translate(-164.623 0)" fill="#fff" />
        <path id="Path_3596" data-name="Path 3596" className="cls-1" d="M370.617,18.934a.548.548,0,0,1,.548-.548h26.226a.594.594,0,0,1,.536.268.788.788,0,0,1-.045.687L383.81,43.947h10.168a2.8,2.8,0,0,1,1.925.737,2.654,2.654,0,0,1,.875,2v5.859a.548.548,0,0,1-.548.548H368.973a.515.515,0,0,1-.48-.274.531.531,0,0,1,0-.548l14.152-24.738h-4.758a7.269,7.269,0,0,1-7.269-7.27Z" transform="translate(-176.537 -8.81)" fill="#fff" />
      </g>
    </svg>

  </>
);

export default OtelzLogo;
