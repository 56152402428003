/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { DASHBOARD, LANDING_PATH } from '../../routes/Paths';
import { AuthContext } from '../../context/AuthContext';
import { Service } from '../../services';
import {
  defaultLoginFormInput,
  IForgotPasswordInput,
  ILoginFormInput,
} from '../../models/UserServiceModel';
import { ServerError } from '../../models/AxiosModel';
import PartnerLandingLogo from '../../components/images/logo-landing';
// import '../../styles/webapp/global.scss';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const routerHistory = useHistory();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const lang = localStorage.getItem('language') || 'tr';

  const [inputs, setInputs] = React.useState<ILoginFormInput>(
    defaultLoginFormInput,
  );
  const [formType, setFormType] = React.useState<string>('login');

  const handleChange =
    (prop: keyof ILoginFormInput) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, [prop]: event.target.value });
      };

  const handleClickShowPassword = () => {
    setInputs({
      ...inputs,
      showPassword: !inputs.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ILoginFormInput>();

  const handleFormType = (data: string) => {
    setFormType(data);
  };

  const onSubmit = (data: ILoginFormInput) => {
    if (formType === 'login') {
      setIsLoaderActive(true);
      Service.User.Login(data).then(res => {
        if (res?.status === 200) {
          localStorage.setItem('user', JSON.stringify(res?.data));
          setAuth(true);
          toast.success(t('operation.loginOperationSuccess'));
          routerHistory.push(DASHBOARD);
        } else {
          toast.error(t('operation.loginOperationFailed'));
          setAuth(false);
        }
        setIsLoaderActive(false);
      }).catch(error => {
        if (error.response.data === 'SubscriptionError') {
          toast.error(t('login.subscriptionError'));
        } else {
          toast.error(t('operation.loginOperationFailed'));
        }
        setIsLoaderActive(false);
      });
    } else {
      forgotPassword();
    }
  };

  const forgotPassword = async () => {
    try {
      setIsLoaderActive(true);
      if (inputs.email !== '') {
        const model: IForgotPasswordInput = {
          email: inputs.email,
        } as IForgotPasswordInput;

        Service.User.ForgotPassword(model).then(res => {
          if (!res?.data?.error) {
            toast.success(t('operation.sendPasswordOperationSuccess'));
            setIsLoaderActive(false);
          } else {
            toast.error(res?.data?.message);
            setIsLoaderActive(false);
          }
        });
      }
    } catch (err) {
      if (err && err.response) {
        toast.error(t('operation.operationFailed'));
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const bannerStyle = {
    backgroundImage: `url(img/login_banner_${lang}.png)`,
    backgroundSize: 'auto 90%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <main className="landing login">
      <Grid container spacing={0.5} className="auth-layout-wrapper">
        <Grid xs={12} md={5} lg={5} p={isSmall ? 1 : 16} className="login-form align-center">
          <div>
            <a href="/">
              <PartnerLandingLogo height="60" />
            </a>
          </div>
          <div className="form-wrapper">
            <div>
              {formType === 'login' ? (
                <h3>{t('login.partnerLogin')}</h3>
              ) : (
                <h3>{t('login.forgotMyPassword')}</h3>
              )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <Controller
                  control={control}
                  name="email"
                  render={() => (
                    <TextField
                      {...register('email', {
                        required: true,
                        pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      variant="outlined"
                      label={t('label.emailAddress')}
                      size="medium"
                      onChange={handleChange('email')}
                      className="w-100"
                      autoFocus
                    />
                  )}
                />
                {errors?.email?.type === 'required' && (
                  <Box sx={{ mt: 1 }}>
                    <span className="error-validation">
                      {t('error.inputRequired')}
                    </span>
                  </Box>
                )}
                {errors?.email?.type === 'pattern' && (
                  <Box sx={{ mt: 1 }}>
                    <span className="error-validation">
                      {t('error.emailFormatError')}
                    </span>
                  </Box>
                )}
              </div>
              {formType === 'login' && (
                <div className="form-group">
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t('label.pass')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field: { onChange, value } }) => (
                        <OutlinedInput
                          {...register('password', { required: true })}
                          id="outlined-adornment-password"
                          type={inputs.showPassword ? 'text' : 'password'}
                          value={value}
                          onChange={onChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {inputs.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                      }
                          label={t('label.pass')}
                        />
                      )}
                    />
                  </FormControl>
                  {errors.password && (
                    <span className="error-validation">
                      {t('error.inputRequired')}
                    </span>
                  )}
                </div>
              )}
              <Box sx={{ my: 2, textAlign: 'right' }}>
                <span>
                  {formType === 'login' && (
                    <Button onClick={() => handleFormType('forgot-password')}>
                      {t('login.forgetPassword')}
                    </Button>
                  )}
                  {formType === 'forgot-password' && (
                    <Button onClick={() => handleFormType('login')}>
                      {t('login.goToLoginForm')}
                    </Button>
                  )}
                </span>
              </Box>
              <button
                className={`btn btn-purple block big${isLoaderActive ? ' loading' : ''}`}
                type="submit"
              >
                {formType === 'login' ? (
                  <div>{t('login.login')}</div>
                ) : (
                  <div>{t('login.send')}</div>
                )}
              </button>
            </form>
            <Divider />
            <div>
              {formType === 'login' ? (
                <NavLink exact to={LANDING_PATH}>
                  <div className="btn btn-border block big">
                    <DriveFileRenameOutlineIcon />
                    {' '}
                    {t('login.doApply')}
                  </div>
                </NavLink>
              ) : (
                false
              )}
            </div>
          </div>
          <div />
        </Grid>
        <Grid item xs={12} md={7} lg={7} className="login-content" style={bannerStyle} />
      </Grid>
    </main>
  );
};

export default Login;
