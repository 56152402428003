import { CircularProgress, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Form, Breadcrumb } from '../../components/partner-contact';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { IPartnerContact, IPartnerContactGetRequest } from '../../models/PartnerContactModel';

const Loader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '50px',
});
export const PartnerContact = (): JSX.Element => {
  const { t } = useTranslation();
  const [partnerContactInfo, setPartnerContactInfo] = useState<IPartnerContact>();
  const apiClient = axios.create();

  const getPartnerContact = async () => {
    try {
      const partnerGetModel: IPartnerContactGetRequest = {
        partnerId: BaseConfig.utilities.partnerId(),
      };

      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post<IPartnerContact>(BaseConfig.api.user('/api/PartnerContact/GetPartnerContact'), partnerGetModel, {
        headers: JSON.parse(headers),
      });
      setPartnerContactInfo(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getPartnerContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Header = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    fontWeight: 'bold',
  }));

  return (
    <>
      <Breadcrumb />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Header>
            {t('partnerContact.partnerContactInformation')}
          </Header>
          {partnerContactInfo ? (<Form partnerContactInfo={partnerContactInfo} />) : (
            <Loader>
              <CircularProgress />
            </Loader>
          )
        }

        </Grid>
      </Grid>

    </>
  );
};

export default PartnerContact;
