/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import { classList } from '../../helpers/Utils';

export const DropDownMenuItem = ({ data, children }:any) => {
  const [collapsed, setcollapsed] = useState(true);
  const { title, icon } = data;

  const onItemClick = () => {
    setcollapsed(!collapsed);
  };

  return (
    <li
      className={classList({
        'nav-item dropdown-sidemenu': true,
        open: !collapsed,
      })}
    >
      <div onClick={onItemClick}>
        {icon}
        <span className="item-name">{title}</span>
        <i className="dd-arrow i-Arrow-Down" />
      </div>
      <ul className="submenu" style={collapsed ? { maxHeight: '0px' } : { maxHeight: '1000px' }}>
        {children}
      </ul>
    </li>
  );
};

export default DropDownMenuItem;
