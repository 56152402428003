import HeaderSection from '../../components/newLanding/Header';
import BannerSection from '../../components/newLanding/Banner';
import VideoSection from '../../components/newLanding/Video';
import OptionsSection from '../../components/newLanding/Options';
import InfoSection from '../../components/newLanding/Info';
import FooterSection from '../../components/newLanding/Footer';

export const Landing: React.FC = () => (
  <main className="landing">
    <HeaderSection />
    <BannerSection />
    <VideoSection />
    <OptionsSection />
    <InfoSection />
    <FooterSection />
  </main>
);
export default Landing;
