import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ConditionsDiv = styled.div`
    padding-bottom:40px;
    .banner{
        height:250px ;
        background-color: rgb(245, 245, 255);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center center;
    &.blue{
      background-color:#bad0f7;
    }
  }
  .text{
    padding:40px;
    ul{
        padding:0 40px;
      li{
        position:relative;
        font-weight:600;
        font-size:15px;
        line-height: 46px;
        height:46px;
        margin-bottom:12px;
        padding-left:54px;
        .circle{
            float:left;
            border-radius:50%;
            background-color:var(--oz-purple) ;
            width:46px;
            height:46px;
            text-align:center;
            position:relative ;
            margin-left: -56px;
            img{
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        span{
          display: inline-block;
          vertical-align: middle;
          line-height: 18px;
        }
      }
      &.grid{
        li{
            float:left;
            width:50%;
            &:last-child{
                margin-bottom:80px;
            }
        }
      }
    }
  }
  @media(max-width:550px){
    .banner {
      height: 140px;
      background-size: 100% auto;
      background-position: center bottom;
    }
    .text{
      padding:24px;
      ul{
        padding:0 0;
        li{
          font-size:13px;
        }
        &.grid{
          li{
              float:unset;
              width:100%;
              &:last-child{
                  margin-bottom:20px;
              }
          }
        }
      }
    }
  }
`;

interface Props {
    type: number;
}

export const ConditionsSection: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation();
  const types = [{ key: '', icons: [] }, { key: 'affiliate', icons: ['circle', 'suitcase', 'gear', 'hand'] }, { key: '', icons: [] }, { key: 'api', icons: ['circle', 'gear', 'suitcase'] }, { key: 'influencer', icons: ['user', 'circle', 'tick', 'hand'] }];

  return (
    <ConditionsDiv>
      <div className={`banner${type === 4 ? ' blue' : ''}`} style={{ backgroundImage: `url(/img/landing/partner_condition_type_${type}.png)` }} />
      <div className="text">
        <ul className={type !== 3 ? 'grid' : ''}>
          {Array.from(new Array(type === 3 ? 3 : 4).keys()).map((index: number) => (
            <li>
              <div className="circle">
                <img src={`img/landing/${types[type].icons[index]}.png`} alt="" />
              </div>
              <span>{t(`landing.conditionsRules.${types[type].key}.rules.${index}`)}</span>
            </li>
          ))}

        </ul>
      </div>
    </ConditionsDiv>
  );
};
export default ConditionsSection;
