import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, FacilitySearchBar } from '../../components/sale-link';
import BaseConfig from '../../config/BaseConfig';
import { IPartnerChannel } from '../../models/PartnerChannelModel';
import { ServerError } from '../../models/AxiosModel';
import { IFacilitySearchBoxOption } from '../../models/FacilitySearchBoxOption';

export const SaleLink = (): JSX.Element => {
  const { t } = useTranslation();
  const otelzURL = 'https://www.otelz.com';
  const partnerId = BaseConfig.utilities.partnerId();
  const apiClient = axios.create();

  const [partnerChannels, setPartnerChannels] = useState <Array <IPartnerChannel>>([]);
  const [selectedPartnerChannel, setSelectedPartnerChannel] = useState<IPartnerChannel>();

  const [placeholder, setPlaceholder] = useState <any>('Otel / Bölge Seçimi');
  const [facilityUrl, setfacilityUrl] = useState <string>('');
  // const [urlHotelIsActive, setUrlHotelIsActive] = useState <boolean>(true);

  const placeholderParam = (value: IFacilitySearchBoxOption): void => {
    setPlaceholder(value.name);
    setfacilityUrl(value.code);
  };

  const getPartnerChannels = async () => {
    try {
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post(
        BaseConfig.api.user('/api/PartnerChannel/GetPartnerChannelList'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      const partnerChannelList : Array <IPartnerChannel> = response.data.data === null ? [] : response.data.data;
      partnerChannelList.unshift(
        { partnerChannelId: 0,
          name: t('banner.salesChannelNotSelected'),
          partnerId: param.partnerId,
          website: '',
          referenceCode: '',
          data: '',
          error: false,
          message: '' },
      );
      setPartnerChannels(partnerChannelList);

      setSelectedPartnerChannel(partnerChannelList[0]);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getPartnerChannels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function homeLink(): string {
    const path = `?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}`;
    return otelzURL.concat(path);
  }

  function earlyBookingLink(): string {
    const path = `/erken-rezervasyon-otelleri?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}`;
    return otelzURL.concat(path);
  }

  function hourlyRoomLink(): string {
    const path = `/saatlik-oda?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}`;
    return otelzURL.concat(path);
  }

  function zparaHotelsLink(): string {
    const path = `/zpara-kazandiran-oteller?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}`;
    return otelzURL.concat(path);
  }
  function facilityLink(): string {
    return (otelzURL.concat(`/${facilityUrl}?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}`));
  }

  const handleCopy = () => {
    toast.success(t('operation.operationCopied'));
  };

  if (selectedPartnerChannel) {
    return (
      <>
        <Breadcrumb />

        <Box sx={{ mb: 3 }}>
          <Alert variant="outlined" severity="info">
            {t('saleLink.saleLinkInfo')}
          </Alert>
        </Box>

        <Divider />

        <Box>
          <Grid item xs={12} md={3} sx={{ marginTop: 2, marginBottom: 2 }}>
            <TextField
              id="outlined-select-partner-channel"
              select
              size="small"
              defaultValue={0}
              label={t('banner.saleChannels')}
              className="w-100"
            >
              {partnerChannels.map(option => (
                <MenuItem onClick={() => setSelectedPartnerChannel(option)} key={option.partnerChannelId} value={option.partnerChannelId}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Box>

        <Divider />

        <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>
          <Typography
            sx={{ flex: '1 1 100%', marginBottom: 2 }}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {t('saleLink.salesLinkTitle')}
          </Typography>

          <Stack
            justifyContent="center"
            divider={<Divider flexItem />}
            spacing={2}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Grid xs={2}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="body2"
                  color="text.secondary"
                  id="tableTitle"
                  component="div"
                >
                  {t('partnerMenu.mainPage')}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={homeLink()}
                />
              </Grid>
              <Grid xs={2}>
                <CopyToClipboard text={homeLink()} onCopy={() => handleCopy()}>
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                  >
                    {t('saleLink.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Grid xs={2}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="body2"
                  color="text.secondary"
                  id="tableTitle"
                  component="div"
                >
                  {t('saleLink.earlyBookingPage')}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={earlyBookingLink()}
                />
              </Grid>
              <Grid xs={2}>
                <CopyToClipboard
                  text={earlyBookingLink()}
                  onCopy={() => handleCopy()}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                  >
                    {t('saleLink.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Grid xs={2}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="body2"
                  color="text.secondary"
                  id="tableTitle"
                  component="div"
                >
                  {t('saleLink.hourlyRoomPage')}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={hourlyRoomLink()}
                />
              </Grid>
              <Grid xs={2}>
                <CopyToClipboard
                  text={hourlyRoomLink()}
                  onCopy={() => handleCopy()}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                  >
                    {t('saleLink.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Grid xs={2}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="body2"
                  color="text.secondary"
                  id="tableTitle"
                  component="div"
                >
                  {t('saleLink.zparaWinningHotelsPage')}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={zparaHotelsLink()}
                />
              </Grid>
              <Grid xs={2}>
                <CopyToClipboard
                  text={zparaHotelsLink()}
                  onCopy={() => handleCopy()}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                  >
                    {t('saleLink.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Stack>
          </Stack>
        </Box>

        <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>
          <Typography
            sx={{ flex: '1 1 100%', marginBottom: 2 }}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {t('saleLink.creategoToPlaceAndHotelLink')}
          </Typography>

          <Stack
            justifyContent="center"
            divider={<Divider flexItem />}
            spacing={2}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Grid xs={2}>
                <FacilitySearchBar placeholder={placeholder} placeholderParam={placeholderParam} />
              </Grid>
              <Grid xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={facilityLink()}
                />
              </Grid>
              <Grid xs={2}>
                <CopyToClipboard text={facilityLink()} onCopy={() => handleCopy()}>
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                  >
                    {t('saleLink.copyLink')}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Stack>
          </Stack>

        </Box>

      </>
    );
  }
  return (
    <Grid
      style={{
        position: 'absolute',
        left: '42%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default SaleLink;
