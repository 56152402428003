import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { Box, Button, Chip, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import TablePagination from '@mui/material/TablePagination';
import { IReservationReport, IReservationReportFilter, reservationState } from '../../models/ReservationReportModel';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { Lookup } from '../../models/GeneralModel';
import Summary from './Summary';
import localePrice from '../../utilities/localePriceHelper';

interface Props {
  reservationReportFilterInput: IReservationReportFilter;
  paymentTypeLookup: Array<Lookup>;
}

type Order = 'asc' | 'desc';
interface EnhancedTableProps {
  // eslint-disable-next-line no-unused-vars
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IReservationReport) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { t } = useTranslation();
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof IReservationReport) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {t('dashboard.resNo')}
        </TableCell>
        <TableCell
          sx={{ minWidth: '125px', textAlign: 'center' }}
          key="reservationDate"
          sortDirection="desc"
        >
          <TableSortLabel
            active={orderBy === 'reservationDate'}
            direction={orderBy === 'reservationDate' ? order : 'asc'}
            onClick={createSortHandler('reservationDate')}
          >
            {t('reservationReport.resDate')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ minWidth: '150px' }}>{t('dashboard.facilityName')}</TableCell>
        <TableCell>{t('reservationReport.customerName')}</TableCell>
        <TableCell
          key="checkinDate"
          sortDirection={orderBy === 'checkinDate' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'checkinDate'}
            direction={orderBy === 'checkinDate' ? order : 'asc'}
            onClick={createSortHandler('checkinDate')}
          >
            {t('dashboard.checkInDate')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="checkoutDate"
          sortDirection={orderBy === 'checkoutDate' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'checkoutDate'}
            direction={orderBy === 'checkoutDate' ? order : 'asc'}
            onClick={createSortHandler('checkoutDate')}
          >
            {t('dashboard.checkOutDate')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ minWidth: '125px' }}>{t('reservationReport.roomInfo')}</TableCell>
        <TableCell>{t('reservationReport.paymentMethod')}</TableCell>
        <TableCell
          key="reservationPrice"
          sortDirection={orderBy === 'reservationPrice' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'reservationPrice'}
            direction={orderBy === 'reservationPrice' ? order : 'asc'}
            onClick={createSortHandler('reservationPrice')}
          >
            {t('dashboard.amount')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{t('reservationReport.currencyType')}</TableCell>
        <TableCell
          key="reservationPriceTRY"
          sortDirection={orderBy === 'reservationPriceTRY' ? order : false}
          sx={{ minWidth: '95px' }}
        >
          <TableSortLabel
            active={orderBy === 'reservationPriceTRY'}
            direction={orderBy === 'reservationPriceTRY' ? order : 'asc'}
            onClick={createSortHandler('reservationPriceTRY')}
          >
            {t('reservationReport.totalPriceTRY')}
          </TableSortLabel>
        </TableCell>
        <TableCell>{t('reservationReport.commissionRate')}</TableCell>
        <TableCell
          key="agentCommision"
          sortDirection={orderBy === 'agentCommision' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'agentCommision'}
            direction={orderBy === 'agentCommision' ? order : 'asc'}
            onClick={createSortHandler('agentCommision')}
          >
            {t('reservationReport.commissionAmount')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="agentCommisionTRY"
          sortDirection={orderBy === 'agentCommisionTRY' ? order : false}
        >

          <TableSortLabel
            active={orderBy === 'agentCommisionTRY'}
            direction={orderBy === 'agentCommisionTRY' ? order : 'asc'}
            onClick={createSortHandler('agentCommisionTRY')}
          >
            {t('reservationReport.agentCommisionTRY')}
          </TableSortLabel>
        </TableCell>
        <TableCell>{t('label.operations')}</TableCell>
      </TableRow>
    </TableHead>
  );
}

export const List: React.FC<Props> = ({ reservationReportFilterInput, paymentTypeLookup }) => {
  const { t } = useTranslation();
  const [reservationListRows, setReservationListRows] = React.useState<IReservationReport[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IReservationReport>('reservationDate');

  const [totalRow, settotalRow] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isExportLoaderActive, setIsExportLoaderActive] = useState(false);

  function CustomToolbar() {
    return (
      <LoadingButton onClick={() => downloadExcel()} type="submit" variant="outlined" loading={isExportLoaderActive} color="success" sx={{ marginRight: 1, float: 'right' }}>
        Excel
      </LoadingButton>
    );
  }

  const handleDetailClick = (guid : any) => {
    const lang = localStorage.getItem('language');
    const rootPath = window.location.origin;
    const url = `${rootPath}/${lang}/reservation-detail/${guid}`;
    window.open(
      url,
      '_blank',
    );
  };

  const apiClient = axios.create();

  const getReservationList = async (reservationReportFilter: IReservationReportFilter) => {
    try {
      setIsLoaderActive(true);
      reservationReportFilter.currencyType = Number(reservationReportFilterInput.currencyType);
      reservationReportFilter.reservationState = Number(reservationReportFilterInput.reservationState);
      reservationReportFilter.paymentType = Number(reservationReportFilterInput.paymentType);
      reservationReportFilter.reservationDateType = Number(reservationReportFilterInput.reservationDateType);
      reservationReportFilterInput.partnerId = BaseConfig.utilities.partnerId();

      apiClient.post(BaseConfig.api.user('/api/Report/GetReservationList'), reservationReportFilter).then(response => {
        settotalRow(response.data.data?.find(Boolean)?.totalResults !== undefined ? response.data.data?.find(Boolean)?.totalResults : 0);
        setPage(reservationReportFilter.pageNumber - 1);
        setReservationListRows(response.data.data);
        setIsLoaderActive(false);
      });

      return true;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const downloadExcel = () => {
    if (reservationListRows.length > 0) {
      toast.info(t('operation.waitExportExcel'));
      setIsExportLoaderActive(true);
      const totalResult = reservationListRows[0].totalResults;
      reservationReportFilterInput.pageSize = totalResult;
    } else {
      toast.error(t('operation.operationFailed'));
      return;
    }

    apiClient.post(BaseConfig.api.user('/api/Report/GetReservationListExport'), reservationReportFilterInput).then(response => {
      const list:IReservationReport[] = response.data.data;
      const data = list?.map(item => ({
        'Rez. No': item.reservationNumber,
        'Rez. Tarihi': moment(item.reservationDate).format('DD.MM.YYYY'),
        'İptal Tarihi': item.status === 3 ? moment(item.cancelationDate).format('DD.MM.YYYY') : '',
        'Rez. Durumu': t(`${reservationState.find((x => x.value === String(item.status)))?.label}`),
        'Tesis Adı': item.facilityName,
        'Müşteri Adı': item.customerName,
        'Giriş Tarihi': moment(item.checkinDate).format('DD.MM.YYYY'),
        'Çıkış Tarihi': moment(item.checkoutDate).format('DD.MM.YYYY'),
        'Toplam Gece': item.totalNight,
        'Toplam Oda': item.totalRooms,
        'Toplam Yetişkin': item.adults,
        'Toplam Çocuk': item.children,
        'Ödeme Şekli': t(`${paymentTypeLookup.find((x => x.id === String(item.paymentType)))?.label}`),
        // eslint-disable-next-line quote-props
        'Tutar': Number(item.reservationPrice.toFixed(2)),
        'Döviz Tipi': item.currency,
        'Tutar TL': Number(item.reservationPriceTRY.toFixed(2)),
        'Döviz Kuru': Number(item.crossRate.toFixed(2)),
        'Komisyon Oranı': item.agentCommisionRate.toString(),
        'Komisyon Tutarı': Number(item.agentCommision.toFixed(2)),
        'Komisyon TL': Number(item.agentCommisionTRY.toFixed(2)),
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Rezervasyon Raporu');
      XLSX.writeFile(workbook, 'Rezervasyon Raporu.xlsx');
      toast.success(t('operation.successExportExcel'));
      setIsExportLoaderActive(false);
    });
  };

  const handleChangePage = (event:React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage:number) => {
    reservationReportFilterInput.pageNumber = newPage + 1;
    reservationReportFilterInput.isRequest = false;
    getReservationList(reservationReportFilterInput);
  };

  const handleChangeRowsPerPage = (event:any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    reservationReportFilterInput.pageSize = parseInt(event.target.value, 10);
    reservationReportFilterInput.isRequest = false;
    getReservationList(reservationReportFilterInput);
  };

  React.useEffect(() => {
    getReservationList(reservationReportFilterInput);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationReportFilterInput]);

  const renderStatus = (
    row: any,
  ) => {
    const selectedReservationState = reservationState.find((x => x.value === String(row.status)));
    if (selectedReservationState) {
      if (row.status === 3) {
        return `${t(selectedReservationState?.label)} ${moment(row.cancelationDate).format('DD.MM.YYYY HH:mm') || ''}`;
      }
      return (t(selectedReservationState?.label));
    }
    return '';
  };

  const renderPaymentType = (
    row: any,
  ) => {
    const selectedPaymentType : Lookup | undefined = paymentTypeLookup.find((x => x.id === String(row.paymentType)));
    if (selectedPaymentType) {
      return (t(selectedPaymentType?.label));
    }
    return '';
  };

  const renderOperation = (
    row: any,
  ) => (
    <>
      <Tooltip title="Detay">
        <Button
          variant="outlined"
          size="small"
          sx={{ marginBottom: 1 }}
          onClick={() => {
            handleDetailClick(row.reservationGuid);
          }}
        >
          {t('label.detail')}
        </Button>
      </Tooltip>
    </>
  );

  const statusStyle = (
    status: any,
  ) => {
    switch (status) {
      case 2:
        return '#effffa';
      case 3:
        return '#ffdcdb';
      default:
        return '';
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IReservationReport,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    reservationReportFilterInput.field = property;
    reservationReportFilterInput.sort = order;
    reservationReportFilterInput.isRequest = false;
    getReservationList(reservationReportFilterInput);
  };

  return (
    <>
      <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>

        <Typography
          sx={{ flex: '1 1 100%', marginBottom: 2 }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {t('reservationReport.reservationList')}
          {CustomToolbar()}
        </Typography>
        {isLoaderActive ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (false)}

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {!isLoaderActive ? (
              <>
                <TableBody>
                  {reservationListRows
                    .map(row => (
                      <TableRow sx={{
                        backgroundColor: statusStyle(row.status),
                      }}
                      >
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {row.reservationNumber}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {moment(row.reservationDate).format('DD.MM.YYYY HH:mm')}
                          <br />
                          <Chip variant="outlined" label={renderStatus(row)} />
                        </TableCell>
                        <TableCell>
                          {row.facilityName}
                        </TableCell>
                        <TableCell>
                          {row.customerName}
                        </TableCell>
                        <TableCell>
                          {row.checkinDate ? (moment(row.checkinDate).format('DD.MM.YYYY')) : (false)}
                        </TableCell>
                        <TableCell>
                          {row.checkoutDate ? (moment(row.checkoutDate).format('DD.MM.YYYY')) : (false)}
                        </TableCell>
                        <TableCell>
                          {`${row.totalRooms || 0} ${t('reservationReport.room')}`}
                          <br />
                          {`${row.totalNight || 0} ${t('reservationReport.night')}`}
                          <br />
                          {`${row.adults || 0} ${t('reservationReport.adult')}`}
                          <br />
                          {row.children > 0 ? (
                            <>
                              {row.children}
                              {' '}
                              {t('reservationReport.child')}
                            </>
                          ) : (false)}
                        </TableCell>
                        <TableCell>
                          {renderPaymentType(row)}
                        </TableCell>
                        <TableCell>
                          {`${localePrice('tr', row.reservationPrice, row.currency, 2) || ''}`}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {`${row.currency.toUpperCase() || ''}`}
                          {row.currency !== 'try' ? (
                            <>
                              {` (${localePrice('tr', row.crossRate, 'TL', 2) || ''})`}
                            </>
                          ) : (false)}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {`${localePrice('tr', row.reservationPriceTRY, 'TL', 2) || ''}`}
                          {row.calculatedCancelFeeTRY > 0 ? (
                            <>
                              <br />
                              {t('reservationReport.punishment')}
                              {' '}
                              :
                              {`${localePrice('tr', row.calculatedCancelFeeTRY, 'TL', 2) || ''}`}
                            </>
                          ) : (false)}
                        </TableCell>
                        <TableCell>
                          {`%${row.agentCommisionRate || ''}`}
                        </TableCell>
                        <TableCell>
                          {`${localePrice('tr', row.agentCommision, row.currency, 2) || ''}`}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {`${localePrice('tr', row.agentCommisionTRY, 'TL', 2) || ''}`}
                        </TableCell>
                        <TableCell>
                          { renderOperation(row) }
                        </TableCell>
                      </TableRow>

                    ))}

                  <TableRow>

                    <TableCell colSpan={3}>
                      {t('reservationReport.totalReservation')}
                      :
                      {' '}
                      {totalRow}
                    </TableCell>
                    <Summary
                      reservationReportFilterInput={reservationReportFilterInput}
                    />
                  </TableRow>

                </TableBody>
              </>
            ) : (
              false
            )}
          </Table>
        </TableContainer>
        <>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Box>
    </>
  );
};

export default List;
