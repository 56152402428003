import { IGeneralResponse } from './GeneralModel';

export interface IPartnerChannel extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  partnerChannelId: number;
  partnerId: number;
  name: string;
  referenceCode: string;
  website: string;
}

export const defaultIPartnerChannel: IPartnerChannel = {
  partnerChannelId: 0,
  partnerId: 0,
  name: '',
  referenceCode: '',
  website: '',
  data: '',
  error: false,
  message: '',
} as IPartnerChannel;

export interface IPartnerChannelDeleteRequest {
  partnerChannelId: number;
}
