import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Breadcrumb } from '../../components/change-password';

export const ChangePassword = (): JSX.Element => (
  <>

    <Breadcrumb />

    <Box sx={{ mb: 3 }}>
      <Typography
        sx={{ mb: 3 }}
        variant="h4"
      />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Form />
        </Grid>
      </Grid>
    </Box>
  </>
);

export default ChangePassword;
