/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { BaseConfig } from '../../config/BaseConfig';
// import { IPartnerChannel, defaultIPartnerChannel } from '../../models/PartnerChannelModel';
import { ServerError } from '../../models/AxiosModel';
import { defaultInsertUserFormInput, IInsertUserFormInput } from '../../models/UserServiceModel';

  interface Props {
    // eslint-disable-next-line no-unused-vars
    refreshTable: (arg: boolean) => void;
  }

export const InsertModal: React.FC<Props> = ({ refreshTable }) => {
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState<Array<any>>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IInsertUserFormInput>();

  const apiClient = axios.create();

  const insertUser = async (userFormInput: IInsertUserFormInput) => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      userFormInput.partnerId = BaseConfig.utilities.partnerId();

      const response = await apiClient.post<IInsertUserFormInput>(BaseConfig.api.user('/api/User/InsertPartnerUser'), userFormInput, {
        headers: JSON.parse(headers),
      });

      toast.success(t('operation.operationSuccess'));
      reset(defaultInsertUserFormInput);
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const getRoles = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/User/GetRoles'), {
        headers: JSON.parse(headers),
      });
      setRoles(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const onSubmit = (data: IInsertUserFormInput) => {
    insertUser(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('name', {
                  required: true,
                })}
                variant="outlined"
                label={t('common.name')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.name?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('surname', {
                  required: false,
                })}
                variant="outlined"
                label={t('common.surname')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.surname?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('email', {
                  required: true,
                })}
                variant="outlined"
                label={t('common.email')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
              />
              {errors?.email?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('role')}
                id="outlined-select-role"
                select
                label="role"
                className="w-100"
              >
                {roles.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3, textAlign: 'right' }}>
          <Button
            onClick={() => {
              refreshTable(true);
            }}
            size="large"
            type="submit"
            variant="contained"
          >
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default InsertModal;
