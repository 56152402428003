/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import styled from 'styled-components';
import YoutubeIframePlayer from './YoutubeIframePlayer';
import PlayIconSvg from './PlayIconSvg';

const YTPlayer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    min-height: 300px;
    min-width: 25vw;

    .iframe,
    .thumb {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    .iframe {
      z-index: 1;
      width: 100%;
    }
  
    .thumb {
      z-index: 5;
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
      .title {
        text-align: center;
      }
    }
  
`;

export interface IThumbnailYTPlayerProps {
videoId: string;
thumbnailImage?: string;
thumbnailTitle?: string;
}

export const ThumbnailYTPlayer: React.FunctionComponent<IThumbnailYTPlayerProps> = ({ videoId, thumbnailImage, thumbnailTitle }) => {
  const [play, setPlay] = React.useState(false);
  const onThumbLayerClick = () => setPlay(true);

  const thumbStyle: React.CSSProperties = {
    backgroundImage: `url(${thumbnailImage})`,
  };

  return (
    <YTPlayer className="thumbnail-yt-player-landing">
      {thumbnailImage && !play && (
      <div className="thumb" onClick={onThumbLayerClick} style={thumbStyle}>
        <div className="icon">
          <PlayIconSvg width="50px" />
        </div>
        <div className="title">
          {thumbnailTitle}
        </div>
      </div>
      )}

      {play && (
      <YoutubeIframePlayer
        className="iframe"
        videoId={videoId}
        disableControls
        autoplay
      />
      )}
    </YTPlayer>
  );
};

export default ThumbnailYTPlayer;
