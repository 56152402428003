import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LinkIcon from '@mui/icons-material/Link';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PasswordIcon from '@mui/icons-material/Password';
import GroupIcon from '@mui/icons-material/Group';
import PercentIcon from '@mui/icons-material/Percent';
import { ContactPhone, Sell, Storefront } from '@mui/icons-material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import PartnerChannel from '../views/partner-channel';
import CurrentAccount from '../views/current-account';
import Invoice from '../views/invoice';
import BankInfo from '../views/bank-info';
import { CARI_HESAP_PATH, CHANGE_PASSWORD_PATH, INVOICE_LIST_PATH, PROFILE_PATH, RESERVATION_REPORT_PATH, FIRM_PATH, FIRM_USERS_PATH, FIRM_CONTACT_PATH, BANNER_PATH, RESERVATION_BUTTON_PATH, SALE_LINK_PATH, BANK_INFO_PATH, COMMISSION_REPORT_PATH, PARTNER_CHANNEL_PATH, FINANCIAL_STATUS_REPORT, RESERVATION_CANCEL_REPORT_PATH } from './Paths';
import { ReservationReport } from '../views/reservation-report';
import Profile from '../views/profile';
import ChangePassword from '../views/change-password';
import Firm from '../views/firm';
import FirmUsers from '../views/firm-users';
import { SaleLink } from '../views/sale-link';
import ReservationButton from '../views/reservation-button';
import Banner from '../views/banner';
import { CommissionReport } from '../views/commission-report';
import FirmContact from '../views/partner-contact';
import Support from '../views/support';
import { FinancialStatus } from '../views/financial-status';
import { ReservationCancelReport } from '../views/reservation-cancel-report';

export interface AppRouteModel {
  id: number;
  component: JSX.Element | JSX.Element[];
  path: string;
  title: string;
  icon: JSX.Element | JSX.Element[];
  roles: number[];
  type: string;
  sub?: any;
  description?: string;
}

export const MAP: AppRouteModel[] = [
  // {
  //   id: 1,
  //   icon: <HourglassTopIcon />,
  //   title: 'Anasayfa',
  //   description: 'Anasayfa Kategorisi',
  //   path: HOME_PATH,
  //   type: 'link',
  //   component: <Home />,
  //   roles: [1, 2],
  //   sub: [
  //     {
  //       id: 1_1,
  //       icon: <HourglassTopIcon />,
  //       title: 'Anasayfa',
  //       path: HOME_PATH,
  //       type: 'link',
  //       component: <Home />,
  //       roles: [1, 2],
  //     },
  //   ],
  // },
  {
    id: 2,
    icon: <Sell />,
    title: 'sale',
    description: 'saleCategory',
    path: PARTNER_CHANNEL_PATH,
    type: 'link',
    component: <PartnerChannel />,
    roles: [1, 2],
    sub: [
      {
        id: 2_1,
        icon: <Storefront />,
        title: 'saleChannel',
        path: PARTNER_CHANNEL_PATH,
        type: 'link',
        component: <PartnerChannel />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 3,
    icon: <HomeRepairServiceIcon />,
    title: 'tools',
    description: 'toolCategory',
    path: SALE_LINK_PATH,
    type: 'link',
    component: <SaleLink />,
    roles: [1, 2],
    sub: [
      {
        id: 3_1,
        icon: <LinkIcon />,
        title: 'saleLink',
        path: SALE_LINK_PATH,
        type: 'link',
        component: <SaleLink />,
        roles: [1, 2],
      },
      {
        id: 3_2,
        icon: <ViewCarouselIcon />,
        title: 'banner',
        path: BANNER_PATH,
        type: 'link',
        component: <Banner />,
        roles: [1, 2],
      },
      {
        id: 3_3,
        icon: <RoomServiceIcon />,
        title: 'reservationButton',
        path: RESERVATION_BUTTON_PATH,
        type: 'link',
        component: <ReservationButton />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 4,
    icon: <CalculateIcon />,
    title: 'finance',
    description: 'financeCategory',
    path: BANK_INFO_PATH,
    type: 'link',
    component: <BankInfo />,
    roles: [1, 2],
    sub: [
      {
        id: 4_1,
        icon: <AccountBalanceIcon />,
        title: 'bankAccounts',
        path: BANK_INFO_PATH,
        type: 'link',
        component: <BankInfo />,
        roles: [1, 2],
      },
      {
        id: 4_2,
        icon: <AccountBalanceWalletIcon />,
        title: 'cariHesap',
        path: CARI_HESAP_PATH,
        type: 'link',
        component: <CurrentAccount />,
        roles: [1, 2],
      },
      {
        id: 4_3,
        icon: <ReceiptIcon />,
        title: 'invoiceList',
        path: INVOICE_LIST_PATH,
        type: 'link',
        component: <Invoice />,
        roles: [1, 2],
      },
      {
        id: 4_3,
        icon: <LocalAtmIcon />,
        title: 'financialStatus',
        path: FINANCIAL_STATUS_REPORT,
        type: 'link',
        component: <FinancialStatus />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 5,
    icon: <AssessmentIcon />,
    title: 'reports',
    description: 'reportCategory',
    path: RESERVATION_REPORT_PATH,
    type: 'link',
    component: <ReservationReport />,
    roles: [1, 2],
    sub: [
      {
        id: 5_1,
        icon: <RoomServiceIcon />,
        title: 'reservationReport',
        path: RESERVATION_REPORT_PATH,
        type: 'link',
        component: <ReservationReport />,
        roles: [1, 2],
      },
      {
        id: 5_1,
        icon: <PhonelinkEraseIcon />,
        title: 'reservationCancelReport',
        path: RESERVATION_CANCEL_REPORT_PATH,
        type: 'link',
        component: <ReservationCancelReport />,
        roles: [1, 2],
      },
      {
        id: 5_1,
        icon: <PercentIcon />,
        title: 'commissionReport',
        path: COMMISSION_REPORT_PATH,
        type: 'link',
        component: <CommissionReport />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 6,
    icon: <ApartmentIcon />,
    title: 'partner',
    description: 'partnerCategory',
    path: FIRM_PATH,
    type: 'link',
    component: <Firm />,
    roles: [1, 2],
    sub: [
      {
        id: 6_1,
        icon: <ApartmentIcon />,
        title: 'partnerInfo',
        path: FIRM_PATH,
        type: 'link',
        component: <Firm />,
        roles: [1, 2],
      },
      {
        id: 6_2,
        icon: <GroupIcon />,
        title: 'partnerUsers',
        path: FIRM_USERS_PATH,
        type: 'link',
        component: <FirmUsers />,
        roles: [1, 2],
      },
      {
        id: 6_3,
        icon: <ContactPhone />,
        title: 'partnerContact',
        path: FIRM_CONTACT_PATH,
        type: 'link',
        component: <FirmContact />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 7,
    icon: <AssignmentIndIcon />,
    title: 'profile',
    description: 'profileCategory',
    path: PROFILE_PATH,
    type: 'link',
    component: <Profile />,
    roles: [1, 2],
    sub: [
      {
        id: 7_1,
        icon: <AssignmentIndIcon />,
        title: 'profileInfo',
        path: PROFILE_PATH,
        type: 'link',
        component: <Profile />,
        roles: [1, 2],
      },
      {
        id: 7_2,
        icon: <PasswordIcon />,
        title: 'changePassword',
        path: CHANGE_PASSWORD_PATH,
        type: 'link',
        component: <ChangePassword />,
        roles: [1, 2],
      },
    ],
  },
  {
    id: 8,
    icon: <LocalPhoneIcon />,
    title: 'support',
    description: 'supportCategory',
    path: '/support',
    type: 'link',
    component: <Support />,
    roles: [1, 2],
    sub: [
      {
        id: 8_1,
        icon: <LocalPhoneIcon />,
        title: 'support',
        path: '/support',
        type: 'link',
        component: <Support />,
        roles: [1, 2],
      },
    ],
  },
];

export default MAP;
