import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { IProfile } from '../../models/ProfileModel';
import { CHANGE_PASSWORD_PATH } from '../../routes/Paths';

interface Props {
  // eslint-disable-next-line no-unused-vars
  profileInfo: IProfile;
}
export const ProfileCard: React.FC<Props> = ({ profileInfo }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Avatar
            src="/static/images/avatars/avatar_6.png"
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                {t('common.userName')}
                :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {`${profileInfo.userName}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t('firm.membershipStartDate')}
                :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {moment(profileInfo.createdOn).format('DD.MM.YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t('firm.lastUpdateDate')}
                :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {moment(profileInfo.lastModifiedOn).format('DD.MM.YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <NavLink
            color="primary"
            to={CHANGE_PASSWORD_PATH}
          >
            <Button
              color="primary"
              fullWidth
              variant="text"
            >
              {t('button.changePassword')}
            </Button>
          </NavLink>
        </Grid>
      </CardActions>
    </Card>
  );
};
