/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import styled from 'styled-components';

// #region CSS
const PopupWrapper = styled.div`
  /* #region DESKTOP CSS */
  max-height: 100vh;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: rgba(36, 54, 69, 0.75);
  z-index: 9999;
  overflow: hidden;
  .container {
    padding-top:10vh;
    /* max-width:1100px; */
    .view {
      position: relative;
      border-radius: 8px;
      max-height: 60vh;
      .popup-title {
        border-bottom: 1px solid var(--border-color);
        padding: 10px 0.75rem;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        margin-bottom: 0;
        margin-top: 0;
        background-color: white;
        font-size: 20px;
        height:45px;
        display:none;
      }
      .closeCircle {
      }
      > .popup_content {
        overflow-y:auto ;
        overflow-x: hidden;
        max-height: calc(80vh - 40px);
        background-color: white;
        border-radius: 8px ;

        ::-webkit-scrollbar-thumb {
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
      }
    }
  }

  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.full {
    .container {
      height: 90vh;
      .popup_content {
        max-height: calc(80vh - 40px);
      }
    }
  }
  /* #endregion */

  @media (max-width:550px) {
    .container {
      padding-top:0!important;
      width:calc(100% - 1.5rem)!important;
      max-width:100%!important;
      padding:0!important;
      margin-left:0;
      margin-right:0;
      .view {
        height:100vh;
        max-height:100vh;
        .popup-title {
          padding-right: 30px;
        }
        .popup_content{
          height:calc(100vh);
          max-height:calc(100vh);
          border-radius: 0;
        }
      }
    }
    &.map-pin-popup,
    &.full {
      .container {
        width: 100%;
        height: 100%;
        padding-left: 0;
        padding-right: 0;

        .view {
          border-radius: 0;
          margin-top: 0;
          max-height: 100vh;

          .close {
            position: absolute;
            cursor: pointer;
            display: block;
            right: 15px;
            top: 25px;
          }
        }

        .popup-title {
          padding: 25px 40px 30px 10px;
          border-bottom: 0;
          font-size: 20px;
          font-weight: var(--font-bold);
          color: var(--font-color);
          text-align: center;
        }

        .popup_content {
          height: calc(100vh - 78px);
          max-height: calc(100vh - -78px);
          border-radius: 0;
        }
      }
    }
  }
`;
// #endregion
interface Props {
  tooglePopup: (status: boolean) => void;
  children: React.ReactNode;
}

const Popup: React.FC<Props> = ({ children, tooglePopup }: Props) => {
  const popupDiv = React.createRef<HTMLDivElement>();

  useEffect(() => {
    function closePopupDialog(event: MouseEvent) {
      if (
        popupDiv.current &&
        event.target &&
        !popupDiv.current.contains(event.target as HTMLDivElement)
      ) {
        tooglePopup(false);
      }
    }
    window.addEventListener('click', closePopupDialog);
    return () => window.removeEventListener('click', closePopupDialog);
  }, [popupDiv]);

  return (
    <PopupWrapper className="container-fluid popup">
      <div className="container">
        <div className="view" ref={popupDiv}>
          <h3 className="popup-title">{' '}</h3>
          <div
            className="closeCircle"
            aria-hidden="true"
            onClick={() => tooglePopup(false)}
          />
          <div className="popup_content">{children}</div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Popup;
