export const HELP_PATH = '/help';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const DASHBOARD = '/dashboard';
export const MY_FACILITIES = '/my-facilities';
export const LOGOUT_PATH = '/logout';
export const RESET_PASSWORD_PATH = '/reset-password';
export const LANDING_PATH = '/';
export const CONTACT_PATH = '/contact-us';
export const FAQ_PATH = 'frequently-asked-question';
export const RESERVATION_REPORT_PATH = '/reservation-report';
export const CARI_HESAP_PATH = '/current-account';
export const BANK_INFO_PATH = '/bank-info';
export const INVOICE_LIST_PATH = '/invoices';
export const PROFILE_PATH = '/profile';
export const CHANGE_PASSWORD_PATH = '/change-password';
export const FIRM_PATH = '/firm';
export const FIRM_CONTACT_PATH = '/firm-contact';
export const FIRM_USERS_PATH = '/firm-users';
export const SALE_LINK_PATH = '/sale-link';
export const BANNER_PATH = '/banner';
export const RESERVATION_BUTTON_PATH = '/reservation-button';
export const COMMISSION_REPORT_PATH = '/commission-report';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const PARTNER_CHANNEL_PATH = '/partnerchannels';
export const RESERVATION_DETAIL_PATH = '/reservation-detail';
export const FINANCIAL_STATUS_REPORT = '/financial-status-report';
export const RESERVATION_CANCEL_REPORT_PATH = '/reservation-cancel-report';
