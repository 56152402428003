import './styles/app.scss';
import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Layout } from './views/layout/Layout';
import theme from './theme/index';
import { AuthProvider, AuthConsumer } from './context/AuthContext';
import { Login } from './views/login';
import RootContextProvider from './context/root/RootContextProvider';
import { MAP } from './routes/Map';
import {
  CONTACT_PATH,
  FAQ_PATH,
  FORGOT_PASSWORD_PATH,
  LANDING_PATH,
  LOGIN_PATH,
  RESERVATION_DETAIL_PATH,
} from './routes/Paths';
import { Landing } from './views/landing';
import { Contact } from './views/contact';
import { FAQ } from './views/faq';
import { OzProgress } from './components/progress/OzProgress';
import DrawerContextProvider from './context/drawer/DrawerContextProvider';
import { ForgotPassword } from './views/forgot-password';
import { ReservationDetail } from './views/reservation-detail';
// import { LocaleProvider } from './context/LocaleContext';

export default function App() {
  const base = '/:locale(tr|en)?';
  const { pathname } = useLocation();

  return (
  // <LocaleProvider>
    <AuthProvider>
      <RootContextProvider routes={MAP}>
        <Suspense fallback={<OzProgress />}>
          <AuthConsumer>
            {({ authorized }) => (
              <ThemeProvider theme={theme}>
                <DrawerContextProvider>
                  {authorized === true ? (
                    <>
                      {!pathname.includes(RESERVATION_DETAIL_PATH) && <Layout />}
                      <Switch>
                        <Route path={`${base}${RESERVATION_DETAIL_PATH}`} component={ReservationDetail} />
                      </Switch>
                    </>
                  ) : (
                    <>
                      <Switch>
                        <Route exact path={`${base}${LOGIN_PATH}`} component={Login} />
                        <Route path={`${base}${CONTACT_PATH}`} component={Contact} />
                        <Route path={`${base}${FAQ_PATH}`} component={FAQ} />
                        <Route path={`${base}${FORGOT_PASSWORD_PATH}`} component={ForgotPassword} />
                        <Route path={`${base}${LANDING_PATH}`} component={Landing} />
                      </Switch>
                    </>
                  )}
                  <ToastContainer />
                </DrawerContextProvider>
              </ThemeProvider>
            )}
          </AuthConsumer>
        </Suspense>
      </RootContextProvider>
    </AuthProvider>
  // </LocaleProvider>
  );
}
