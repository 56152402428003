import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const BannerSectionDiv = styled.div`
  background:url('img/landing/handback.svg') right top no-repeat ;
  background-size: auto 100%;
  min-height:calc(100vh - 350px);
  padding-top:100px;
  padding-bottom:100px;
  h1{
    margin:0;
    font-size:34px;
    font-weight:var(--font-extra-bold);
    line-height:50px;
    span{
      color:#5664d2;
    }
  }
  .desc{
    font-size: 16px;
    color: #7b8493;
    line-height: 1.5;
    margin-top:20px;
    margin-bottom:12px; 
  }
  @media(max-width:550px) {
    background-size: 100% auto;
    background:url('img/landing/handback.png') center bottom no-repeat ;
    text-align:center ;
    padding-top:20px;
    padding-bottom:20px;
    h1{
      font-size:20px;
      line-height:30px ;
      div{
        display:inline-block ;
      }
    }
    .desc{
      margin-bottom:16px;
    }
  }
`;

export const BannerSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BannerSectionDiv className="container-fluid">
      <div className="container">
        <div className="text">
          <h1>
            <div>
              {t('landing.bannerHeaderOne')}
              {' '}
              <span>
                {t('landing.bannerHeaderTwo')}
              </span>
            </div>
            <div>
              {t('landing.bannerHeaderThree')}
            </div>
            <div>
              {t('landing.bannerHeaderFour')}
            </div>
          </h1>
        </div>
        <div className="desc">

          {t('landing.bannerDescOne')}
          <br />
          {t('landing.bannerDescTwo')}
        </div>
        <Link className="transparent-bg" to="options" spy smooth duration={500}>
          <div className="btn btn-purple big">
            {t('landing.bannerButton')}
          </div>
        </Link>
      </div>
    </BannerSectionDiv>
  );
};
export default BannerSection;
