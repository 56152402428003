import { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../models/AxiosModel';
import BaseConfig from '../../config/BaseConfig';
import { IPartnerContact, IPartnerContactSaveRequest } from '../../models/PartnerContactModel';

const Loader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '50px',
});

const states = [
  {
    value: '90',
    label: '90',
  },
];

interface Props {
  // eslint-disable-next-line no-unused-vars
  partnerContactInfo: IPartnerContact;
}

export const Form: React.FC<Props> = ({ partnerContactInfo }) => {
  const { t } = useTranslation();

  const editPartnerContact = async (partnerContactFormInput: IPartnerContactSaveRequest) => {
    try {
      const apiClient = axios.create();
      const headers = BaseConfig.utilities.authorizedHeader();
      partnerContactFormInput.partnerId = BaseConfig.utilities.partnerId();
      partnerContactFormInput.securityId = BaseConfig.utilities.securityId();
      const response = await apiClient.post<IPartnerContactSaveRequest>(BaseConfig.api.user('/api/PartnerContact/SavePartnerContact'), partnerContactFormInput, {
        headers: JSON.parse(headers),
      });

      if (!response.data.error) {
        toast.success(t('operation.operationSuccess'));
      } else {
        toast.error(response.data.message);
      }

      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IPartnerContactSaveRequest) => {
    editPartnerContact(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPartnerContactSaveRequest>();

  useEffect(() => {
  }, [partnerContactInfo]);

  if (partnerContactInfo.email !== undefined) {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    {...register('email')}
                    fullWidth
                    label={t('label.emailAddress')}
                    name="email"
                    defaultValue={partnerContactInfo.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ marginTop: '1px' }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction="row"
                >
                  <div style={{ width: '100%' }}>
                    <Box
                      sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }}
                    >
                      <TextField
                        {...register('mobileCode')}
                        id="outlined-select-mobile-code"
                        select
                        label={t('label.countryCode')}
                        defaultValue={partnerContactInfo.mobileCode}
                        sx={{ width: 1 / 4 }}
                      >
                        {states.map((option, key) => (
                          <MenuItem key={key} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        {...register('mobileNumber', {
                          pattern: /^(\+9)?0?[5][03-5]\d{8}$/,
                        })}
                        sx={{ width: 3 / 4, marginLeft: '10px' }}
                        fullWidth
                        label={t('label.mobilePhone')}
                        helperText="Örn: 5xxxxxxxxx"
                        defaultValue={partnerContactInfo.mobileNumber}
                        name="mobileNumber"
                        type="text"
                        variant="outlined"
                      />
                      {errors?.mobileNumber?.type === 'pattern' && (
                      <Box sx={{ mt: 1 }}>
                        <span className="error-validation">Geçerli bir telefon numarası girin!</span>
                      </Box>
                      )}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {t('button.save')}
              </Button>
            </Box>
          </Card>
        </form>
      </>
    );
  }

  return (
    <Loader>
      <CircularProgress />
    </Loader>
  );
};
