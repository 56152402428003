import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { defaultReservationListSummary, IReservationListSummary, IReservationReportFilter } from '../../models/ReservationReportModel';
import localePrice from '../../utilities/localePriceHelper';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';

interface Props {
  reservationReportFilterInput: IReservationReportFilter;
}

export const Summary: React.FC<Props> = ({ reservationReportFilterInput }) => {
  const { t } = useTranslation();
  const [reservationListSummary, setReservationListSummary] = React.useState<IReservationListSummary>(defaultReservationListSummary);
  const apiClient = axios.create();

  const getReservationListSummary = async (reservationReportFilter: IReservationReportFilter) => {
    try {
      reservationReportFilter.currencyType = Number(reservationReportFilterInput.currencyType);
      reservationReportFilter.reservationState = Number(reservationReportFilterInput.reservationState);
      reservationReportFilter.paymentType = Number(reservationReportFilterInput.paymentType);
      reservationReportFilter.reservationDateType = Number(reservationReportFilterInput.reservationDateType);
      reservationReportFilterInput.partnerId = BaseConfig.utilities.partnerId();

      apiClient.post(BaseConfig.api.user('/api/Report/GetReservationListSummary'), reservationReportFilter).then(response => {
        localStorage.setItem('summaryData', JSON.stringify(response.data.data));
        setReservationListSummary(response.data.data);
      });

      return true;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  React.useEffect(() => {
    if (reservationReportFilterInput.isRequest) {
      getReservationListSummary(reservationReportFilterInput);
    } else {
      const data = localStorage.getItem('summaryData');
      if (data) {
        setReservationListSummary(JSON.parse(data));
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationReportFilterInput]);

  return (
    <>
      <TableCell colSpan={3} />
      <TableCell>
        {reservationListSummary.totalRooms}
        {' '}
        {t('reservationReport.room')}
        <br />
        {reservationListSummary.totalNight}
        {' '}
        {t('reservationReport.night')}
        <br />
        {reservationListSummary.totalAdults}
        {' '}
        {t('reservationReport.adult')}
      </TableCell>
      <TableCell colSpan={3} align="right">
        {t('reservationReport.totalAmount')}
        {' '}
        :
        <br />
        {t('reservationReport.cancelFeeTotal')}
        {' '}
        :
      </TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>
        {localePrice('tr', reservationListSummary.totalTRY, 'TL', 2)}
        {' '}
        <br />
        {localePrice('tr', reservationListSummary.totalCancelFeeTRY, 'TL', 2)}

      </TableCell>
      <TableCell colSpan={2} align="right">
        {t('reservationReport.totalCommission')}
        {' '}
        :
      </TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>
        {localePrice('tr', reservationListSummary.totalAgencyCommision, 'TL', 2)}
      </TableCell>
    </>
  );
};

export default Summary;
