import {
  Box,
  styled,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Breadcrumb from '../../components/financial-status/Breadcrumb';
import FinancialStatusReport from '../../components/financial-status/FinancialStatusReport';

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export const FinancialStatus = (): JSX.Element => (
  <>
    <Breadcrumb />

    <Box sx={{ mb: 3 }}>
      <Grid
        container
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <FinancialStatusReport />
        </Grid>
      </Grid>
    </Box>
  </>
);
