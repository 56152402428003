/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BaseConfig from '../../config/BaseConfig';
import { AppLanguage } from '../../const/app-languages';
import { classList } from '../../helpers/Utils';
import { ServerError } from '../../models/AxiosModel';
import { IPartnerExistingSetting, IPartnerExistingSettingGetRequest } from '../../models/PartnerExistingSettingGetRequest';
import { COMMISSION_REPORT_PATH, FINANCIAL_STATUS_REPORT } from '../../routes/Paths';
import DropDownMenuItem from './DropdownMenuItem';
import PartnerType from '../../utilities/partnerType';

interface Props{
  menu: any
}
export const DropDownMenu = ({ menu }:Props) => {
  const [open] = useState(false);
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [menuFilter, setMenuFilter] = useState(false);
  const apiClient = axios.create();
  function generatePath(path: string) {
    const langPath = window.location.pathname.split('/')[1];

    if ((Object.values(AppLanguage) as string[]).includes(langPath)) {
      return `/${langPath}${path}`;
    }

    return path;
  }

  const getNetWorkingType = async () => {
    const filter = localStorage.getItem('existingSettingFilter');
    if (filter) {
      const value = filter === 'true';
      setMenuFilter(value);
    }
    try {
      const headers = BaseConfig.utilities.authorizedHeader();
      const netWorkingType: IPartnerExistingSettingGetRequest = {
        id: BaseConfig.utilities.partnerId(),
      };

      const response = await apiClient.post<IPartnerExistingSetting>(BaseConfig.api.user('/api/Lookup/GetExistingSettingList'), netWorkingType, {
        headers: JSON.parse(headers),
      });
      localStorage.setItem('existingSettingFilter', response.data.data);
      setMenuFilter(response.data.data);
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  useEffect(() => {
    getNetWorkingType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul className="childNav">
      {menu.map((item:any, i:number) => {
        if (item && item.sub) {
          return (
            <DropDownMenuItem key={i} data={item} />
          );
        }
        if ((item.path === COMMISSION_REPORT_PATH && menuFilter) || (item.path === FINANCIAL_STATUS_REPORT && BaseConfig.utilities.partner().partnerType !== PartnerType.API)) {
          return (null);
        }
        return (
          <li key={i} className={classList({ 'nav-item': true, open })} onClick={menu.closeSecSidenav}>
            <NavLink activeClassName="selected" exact to={generatePath(item.path)}>
              {item.icon}
              <span style={{ marginLeft: '5px' }} className="item-name">
                {t(`partnerMenu.${item.title}`)}
              </span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default DropDownMenu;
