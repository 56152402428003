import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppLanguage } from '../const/app-languages';
import { CONTACT_PATH, DASHBOARD, FAQ_PATH, FORGOT_PASSWORD_PATH, LANDING_PATH, LOGIN_PATH, RESERVATION_DETAIL_PATH } from '../routes/Paths';
import { Service } from '../services';

export type Props = {
  children: React.ReactNode;
};

const initialContext: Context = {
  authorized: undefined,
  roleId: 1,
  setAuth: () => {},
};

export interface Context {
  roleId: number;
  authorized: boolean | undefined;
  // eslint-disable-next-line
  setAuth: (status: boolean) => void;
}

const AuthContext = React.createContext<Context>(initialContext);
const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({ children }: Props): JSX.Element => {
  const routerHistory = useHistory();
  const [roleId] = useState<number>(initialContext.roleId);
  const [authorized, setAuthorized] = useState<boolean | undefined>(
    initialContext.authorized,
  );

  useEffect(() => {
    Service.User.CheckAuth().then((res: any) => {
      const lang = window.location.pathname.split('/')[1];
      if (res?.status === 200 && !res?.data?.error) {
        setAuth(true);
        const paths = window.location.pathname.split('/');
        if (`${paths[1]}/${paths[2]}` !== `${lang}${RESERVATION_DETAIL_PATH}`) {
          routerHistory.push(DASHBOARD);
        }
      } else {
        setAuth(false);
        if ((Object.values(AppLanguage) as string[]).includes(lang)) {
          if (
            (Object.values(AppLanguage) as string[]).includes(window.location.pathname.split('/')[1]) === false &&
            window.location.pathname !== `/${lang}${LOGIN_PATH}` &&
            window.location.pathname !== `/${lang}${LANDING_PATH}` &&
            window.location.pathname !== `/${lang}${CONTACT_PATH}` &&
            window.location.pathname !== `/${lang}${FORGOT_PASSWORD_PATH}` &&
            window.location.pathname !== `/${lang}${FAQ_PATH}`
          ) {
            routerHistory.push(LOGIN_PATH);
          }
        } else if (
          window.location.pathname !== LOGIN_PATH &&
            window.location.pathname !== LANDING_PATH &&
            window.location.pathname !== CONTACT_PATH &&
            window.location.pathname !== FORGOT_PASSWORD_PATH &&
            window.location.pathname !== `/${lang}${FAQ_PATH}`
        ) {
          routerHistory.push(LOGIN_PATH);
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized]);

  const setAuth = (status: boolean): void => {
    setAuthorized(status);
  };

  return (
    <AuthContext.Provider
      value={{
        roleId,
        authorized,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthConsumer, AuthProvider };
