// eslint-disable-next-line quotes
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const copyToClipBoard = (text: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  navigator.clipboard.writeText(text);

  /* Alert the copied text */
  toast.success(t('operation.operationCopied'));
};

export { copyToClipBoard };
