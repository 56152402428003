import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, styled, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import FindInPageIcon from '@mui/icons-material/FindInPage';
// eslint-disable-next-line no-unused-vars
import trLocale from 'date-fns/locale/tr';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { CurrencyLookup, defaultReservationReportFilter, IReservationReportFilter, reservationState, paymentTypes } from '../../models/ReservationReportModel';
import { List, Breadcrumb } from '../../components/reservation-report';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
import { IPartnerChannel } from '../../models/PartnerChannelModel';

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export const ReservationReport = (): JSX.Element => {
  const { t } = useTranslation();

  const apiClient = axios.create();

  const nextDay = moment(new Date()).add(1, 'days').format('MM.DD.YYYY');
  const lastYear = moment(new Date()).format('MM.DD.YYYY');

  const [locale] = React.useState<Locale>(trLocale);
  const [value, setValue] = React.useState<DateRange<string>>([lastYear, nextDay]);
  const [currencyLookup, setCurrencyLookup] = React.useState <Array <CurrencyLookup>>([]);
  const [partnerChannels, setPartnerChannels] = React.useState <Array <IPartnerChannel>>([]);

  const [filterValue, setfilterValue] = React.useState <IReservationReportFilter>(defaultReservationReportFilter);

  const {
    register,
    handleSubmit,
  } = useForm<IReservationReportFilter>();

  // eslint-disable-next-line consistent-return
  const getReservationList = (reservationReportFilterInput: IReservationReportFilter) => {
    try {
      reservationReportFilterInput.startDate = moment(value[0]).format('DD.MM.YYYY');
      reservationReportFilterInput.endDate = moment(value[1]).format('DD.MM.YYYY');
      reservationReportFilterInput.pageNumber = 1;
      reservationReportFilterInput.pageSize = 10;

      setfilterValue(reservationReportFilterInput);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const onSubmit = (data: IReservationReportFilter) => {
    data.isRequest = true;
    getReservationList(data);
  };

  const getPartnerChannels = async () => {
    try {
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post(
        BaseConfig.api.user('/api/PartnerChannel/GetPartnerChannelList'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      const partnerChannelList : Array <IPartnerChannel> = response.data.data;
      // adding all currencies option to the beginning of the array

      setPartnerChannels(partnerChannelList);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const getCurrencyLookup = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/Finance/GetCurrencyLookup'), {
        headers: JSON.parse(headers),
      });
      setCurrencyLookup(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getCurrencyLookup();
    getPartnerChannels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <SearchIcon sx={{ marginTop: '3px' }} />
          {' '}
          <Typography sx={{ marginLeft: 1 }}>{t('common.search')}</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 2, boxShadow: 5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      {...register('reservationNumber')}
                      variant="outlined"
                      label={t('reservationReport.reservationNumber')}
                      size="small"
                      autoComplete="off"
                      className="w-100"
                      autoFocus
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      {...register('facilityName')}
                      variant="outlined"
                      label={t('reservationReport.facilityName')}
                      size="small"
                      autoComplete="off"
                      className="w-100"
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      {...register('reservationDateType')}
                      id="outlined-select-currency"
                      select
                      defaultValue={filterValue.reservationDateType}
                      size="small"
                      required
                      label={t('reservationReport.dateRangeType')}
                      className="w-100"
                    >
                      <MenuItem key="1" value="1">
                        {t('reservationReport.accordingResDateRange')}
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        {t('reservationReport.checkinDateRange')}
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        {t('reservationReport.checkInOutDate')}
                      </MenuItem>
                    </TextField>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <LocalizationProvider sx={{ mb: 3 }} locale={locale} dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        startText={t('reservationReport.startDate')}
                        endText={t('reservationReport.endDate')}
                        value={value}
                        onChange={newValue => {
                          setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField required sx={{ mx: 2, width: '100%' }} size="small" {...startProps} />
                            <TextField required sx={{ mx: 2, width: '100%' }} size="small" {...endProps} />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      required
                      {...register('reservationState')}
                      id="outlined-select-currency"
                      size="small"
                      select
                      defaultValue={filterValue.reservationState}
                      label={t('reservationReport.reservationStatus')}
                      className="w-100"
                    >
                      {reservationState.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {t(option.label)}
                        </MenuItem>
                      ))}
                    </TextField>

                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      required
                      {...register('currencyType')}
                      id="outlined-select-currency"
                      size="small"
                      select
                      defaultValue={filterValue.currencyType}
                      label={t('reservationReport.crossRate')}
                      type="number"
                      className="w-100"
                    >
                      <MenuItem key={0} value={0}>
                        {t('reservationReport.allCurrencies')}
                      </MenuItem>
                      {currencyLookup?.map(option => (
                        <MenuItem key={option.enumId} value={option.enumId}>
                          {option.id}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      required
                      {...register('paymentType')}
                      id="outlined-select-currency"
                      select
                      size="small"
                      defaultValue={filterValue.paymentType}
                      label={t('reservationReport.paymentType')}
                      className="w-100"
                    >
                      {paymentTypes.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {t(option.label)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <TextField
                      required
                      {...register('partnerChannelId')}
                      id="outlined-select-partner-channel"
                      select
                      size="small"
                      defaultValue={filterValue.partnerChannelId}
                      label={t('reservationReport.salesChannels')}
                      className="w-100"
                    >
                      <MenuItem key={0} value={0}>
                        {t('common.all')}
                      </MenuItem>
                      {partnerChannels?.map(option => (
                        <MenuItem key={option.partnerChannelId} value={option.partnerChannelId}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Item>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box sx={{ flexGrow: 0, marginTop: 2, textAlign: 'right', width: 1, height: 1 }}>
                    <Button style={{ fontSize: '12px' }} startIcon={<FindInPageIcon />} size="small" type="submit" variant="contained">
                      {t('reservationReport.bringReservations')}
                    </Button>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      <List reservationReportFilterInput={filterValue} paymentTypeLookup={paymentTypes} />
    </>
  );
};
