import React, { useContext } from 'react';
import { Box } from '@mui/system';
import LayoutSidebar from '../../components/layout/LayoutSidebar';
import LayoutMain from '../../components/layout/LayoutMain';
import LayoutHeader from '../../components/layout/LayoutHeader';
import { classList } from '../../helpers/Utils';
import { DrawerProps } from '../../context/drawer/DrawerContextProps';
import DrawerContext from '../../context/drawer/DrawerContext';

export const Layout = (): JSX.Element => {
  const context = useContext<DrawerProps>(DrawerContext);

  return (
    <>
      <div
        className={classList({
          'app-admin-wrap layout-sidebar-compact sidebar-dark-purple': true,
          'sidenav-open': !context.isShow,
        })}
      >
        <LayoutSidebar />
        <div
          className={classList({
            'main-content-wrap': true,
            'sidenav-open': !context.isShow,
          })}
        >
          <LayoutHeader />
          <Box sx={{ flexGrow: 1, px: 3 }}>
            <LayoutMain />
          </Box>
        </div>
      </div>
    </>
  );
};

export default Layout;
