/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { BaseConfig } from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';
// eslint-disable-next-line no-unused-vars
import { IEditUserFormInput, IUser } from '../../models/UserServiceModel';

interface Props {
  // eslint-disable-next-line no-unused-vars
  refreshTable: (arg: boolean) => void;
  selectedUser: IUser;
}

export const EditModal: React.FC<Props> = ({ refreshTable, selectedUser }) => {
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState<Array<any>>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>();

  const apiClient = axios.create();

  // eslint-disable-next-line no-unused-vars, consistent-return
  const editUser = async (userFormInput: IUser) => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      userFormInput.partnerId = BaseConfig.utilities.partnerId();
      userFormInput.securityId = BaseConfig.utilities.securityId();

      const response = await apiClient.post<IEditUserFormInput>(BaseConfig.api.user('/api/User/UpdatePartnerUser'), userFormInput, {
        headers: JSON.parse(headers),
      });
      if (response.data.error || !response.data.data) {
        toast.error(t('operation.operationFailed'));
      }
      toast.success(t('operation.operationSuccess'));
      return response;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        toast.error(t('operation.operationFailed'));
        return axiosError.response?.data;
      }
      throw err;
    }
  };

  const getRoles = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.get(BaseConfig.api.user('/api/User/GetRoles'), {
        headers: JSON.parse(headers),
      });
      setRoles(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const onSubmit = (data: IUser) => {
    editUser(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid sx={{ display: 'none' }}>
              <TextField
                {...register('userId', {
                  required: true,
                })}
                value={selectedUser?.userId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('name', {
                  required: true,
                })}
                variant="outlined"
                label={t('common.name')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
                defaultValue={selectedUser?.name}
              />
              {errors?.name?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('surName', {
                  required: false,
                })}
                variant="outlined"
                label={t('common.surname')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
                defaultValue={selectedUser?.surName}
              />
              {errors?.surName?.type === 'required' && (
              <Box sx={{ mt: 1 }}>
                <span className="error-validation">
                  {t('error.inputRequired')}
                </span>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label={t('common.email')}
                size="medium"
                className="w-100"
                autoComplete="off"
                autoFocus
                defaultValue={selectedUser?.email}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('role')}
                id="outlined-select-role"
                select
                label={t('common.role')}
                className="w-100"
                defaultValue={selectedUser?.role}
              >
                {roles.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3, textAlign: 'right' }}>
          <Button onClick={() => refreshTable(true)} size="large" type="submit" variant="contained">
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditModal;
