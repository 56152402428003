import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios, { AxiosError } from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableHead, Typography, Divider, DialogProps } from '@mui/material';
import AddCardIcon from '@mui/icons-material/AddCard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import BaseConfig from '../../config/BaseConfig';
import { Breadcrumb, InsertModal, DeleteModal, EditModal } from '../../components/firm-users';
import { defaultUser, IUser } from '../../models/UserServiceModel';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line no-unused-vars
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

type ServerError = {
  code: string;
  description: string;
};

export const FirmUsers = (): JSX.Element => {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<Array<IUser>>([]);
  const [refreshStatus, setRefreshStatus] = useState<boolean>(false);
  const refreshTable = (refreshParam: boolean): void => {
    setRefreshStatus(refreshParam);
  };

  const [openInsert, setOpenInsert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IUser>(
    defaultUser,
  );

  const getTable = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();
      const apiClient = axios.create();
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };
      const response = await apiClient.post(
        BaseConfig.api.user('/api/Firm/GetUserList'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      setRows(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getTable();
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = (type?: string, selectedRowId?: any) => {
    if (type?.toLowerCase() === 'edit') {
      const rowValue =
      rows.find(x => x.userId === selectedRowId) ?? defaultUser;
      setSelectedRow(rowValue);
      setOpenEdit(true);
    } else if (type?.toLowerCase() === 'delete') {
      const rowValue = rows.find(x => x.userId === selectedRowId) ?? defaultUser;
      setSelectedRow(rowValue);
      setOpenDelete(true);
    } else {
      setOpenInsert(true);
    }
  };

  const handleClose = () => {
    if (refreshStatus) {
      getTable();
      setRefreshStatus(!refreshStatus);
    }
    setOpenInsert(false);
    setOpenEdit(false);
    setOpenDelete(false);
  };
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps['maxWidth']>('md');
  return (
    <>
      <Breadcrumb />
      <Box sx={{ mb: 3 }}>
        <Button
          startIcon={<AddCardIcon />}
          variant="outlined"
          onClick={() => handleClickOpen('insert')}
        >
          {t('firm.addNewUser')}
        </Button>
        <Dialog
          open={openInsert}
          onClose={handleClose}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle>
            {t('firm.addNewUser')}
          </DialogTitle>
          <DialogContent>
            <InsertModal refreshTable={refreshTable} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('button.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Dialog
          open={openEdit}
          onClose={handleClose}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle>
            {t('firm.editUser')}
          </DialogTitle>
          <DialogContent>
            <EditModal
              refreshTable={refreshTable}
              selectedUser={selectedRow}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('button.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Dialog
          open={openDelete}
          onClose={handleClose}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{t('operation.confirmDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t('partnerUser.wantToDeletePartnerUser')}
              <br />
              {t('operation.thisActionCannotBeUndone')}
            </DialogContentText>
            <DeleteModal
              refreshTable={refreshTable}
              selectedUser={selectedRow}
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      </Box>

      <Divider />

      <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>
        <Typography
          sx={{ flex: '1 1 100%', marginBottom: 2 }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {t('partnerUser.userList')}
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('partnerUser.userId')}</TableCell>
                <TableCell align="left">{t('partnerUser.userName')}</TableCell>
                <TableCell align="left">{t('partnerUser.name')}</TableCell>
                <TableCell align="left">{t('partnerUser.surname')}</TableCell>
                <TableCell align="left">{t('common.email')}</TableCell>
                <TableCell align="left">{t('label.operations')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
                : rows
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.userId}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.userName}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.surName}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.email}
                  </TableCell>
                  <TableCell style={{ width: '5%' }} align="left">
                    <Button
                      sx={{ mb: 2, minWidth: '100%' }}
                      startIcon={<EditIcon />}
                      variant="outlined"
                      onClick={() => handleClickOpen('edit', row.userId)}
                    >
                      {t('button.edit')}
                    </Button>
                    <Button
                      sx={{
                        mb: 2,
                        minWidth: '100%',
                        textAlign: 'left',
                        color: 'red',
                        borderColor: 'red',
                      }}
                      startIcon={<DeleteForeverIcon />}
                      variant="outlined"
                      onClick={() =>
                        handleClickOpen('delete', row.userId)
                        }
                    >
                      {t('button.delete')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default FirmUsers;
