/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../../../styles/components/SearchBar.css';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ISearchBoxOption } from '../../../models/SearchBoxModel';
import { DASHBOARD } from '../../../routes/Paths';
import BaseConfig from '../../../config/BaseConfig';
import { ISearchBoxOptionRequest } from '../../../models/PartnerSearchBoxListModel';
import { Service } from '../../../services';

interface Props {
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  placeholderParam: (value: ISearchBoxOption) => void;
}

export const SearchBar: React.FC<Props> = ({ placeholder, placeholderParam }) => {
  const [wordEntered, setWordEntered] = useState('');
  const [data, setData] = useState<Array<any>>([]);

  const handleFilter = (event: { target: { value: any; }; }) => {
    const searchWord = event.target.value;
    const param = {
      term: searchWord,
      lang: 'tr',
      securityId: BaseConfig.utilities.securityId(),
    } as ISearchBoxOptionRequest;

    Service.User.GetPartner(param).then(res => {
      // takes the first element at the top of the list as a current" partner
      setData(res?.data.data);
    });
    if (searchWord === '') {
      setWordEntered('');
    } else {
      setWordEntered(searchWord);
    }
  };

  const handleSelect = (value : ISearchBoxOption) => {
    setWordEntered(value.name);
    placeholderParam(value);
    window.location.href = BaseConfig.api.user(DASHBOARD);
  };

  const clearInput = () => {
    setData([]);
    setWordEntered('');
  };

  return (
    <>
      <div className="search">
        <div className="searchInputs" style={{ borderRadius: '0.75em' }}>
          <input
            type="text"
            placeholder={'Partner: '.concat(placeholder)}
            value={wordEntered}
            onChange={handleFilter}
            onFocus={handleFilter}
            onBlur={() => {
              setTimeout(() => {
                setData([]);
              }, 200);
            }
            }
          />
          <div className="searchBoxIcon">
            {data.length === 0 ? (
              <SearchIcon sx={{ fontSize: 25 }} />
            ) : (
              <SearchOffIcon sx={{ fontSize: 25 }} id="clearSearchBox" onClick={clearInput} />
            )}
          </div>
        </div>
        {data.length !== 0 && (
        <div className="dataResult">
          {data.map((value : any, key: any) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              key={key}
              onClick={() => { handleSelect(value); }}
            >
              <a className="dataItem">
                <p>
                  {value.name ?? value.globalName}
                </p>
              </a>
            </div>
          ))}
        </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
