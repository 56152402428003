import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios, { AxiosError } from 'axios';
import { TableHead, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseConfig from '../../config/BaseConfig';
import localePrice from '../../utilities/localePriceHelper';

type ServerError = {
  code: string;
  description: string;
};

type CommissionReport = {
  // eslint-disable-next-line camelcase
  commissionTotal: number;
  month: number;
  year: number;
};

export const CommissionReportEstimated: React.FC = () => {
  const { t } = useTranslation();

  const [rows, setRows] = React.useState<Array<CommissionReport>>([]);

  const getTable = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();

      const apiClient = axios.create();

      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };

      const response = await apiClient.post(
        BaseConfig.api.user('/api/Report/GetCommissionReportEstimated'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );

      // create new array to push coming value to inside
      setRows(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  function getPeriod(value: number): string {
    const monthNamesTR = [
      t('months.january'),
      t('months.february'),
      t('months.march'),
      t('months.april'),
      t('months.may'),
      t('months.june'),
      t('months.july'),
      t('months.august'),
      t('months.september'),
      t('months.october'),
      t('months.november'),
      t('months.december'),
    ];
    return `${monthNamesTR[value]} ${t('commissionReport.earnedPeriod')}`;
  }

  useEffect(() => {
    getTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        sx={{ flex: '1 1 100%', marginBottom: 2 }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {t('commissionReport.estimatedMonthly')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('commissionTotal.year')}
              </TableCell>
              <TableCell align="right">
                {t('commissionTotal.period')}
              </TableCell>
              <TableCell align="right">
                {t('commissionTotal.profit')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0 &&
            rows.map((row, index) => (
              <TableRow
                style={{ width: 10 }}
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.year}
                </TableCell>
                <TableCell align="center">{getPeriod(row.month - 1)}</TableCell>
                <TableCell align="right">
                  {localePrice('tr', row.commissionTotal, 'TL', 2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export { CommissionReportEstimated as default };
