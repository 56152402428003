import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend'; // <---- add this

const options = {
  order: ['path', 'querystring', 'localStorage'],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lang',
  lookupLocalStorage: 'language',
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({

    // lng: 'tr',
    ns: ['translations'],
    defaultNS: 'translations',
    // detection: {
    //   lookupFromPathIndex: 0,
    //   checkWhitelist: true,
    // },
    detection: options,
    supportedLngs: ['tr', 'en'],
    fallbackLng: 'tr',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
      // eslint-disable-next-line global-require
        translations: require('../../locales/tr.json'),
      },
      en: {
      // eslint-disable-next-line global-require
        translations: require('../../locales/en.json'),
      },
    },
  });

i18n.languages = ['tr', 'en'];

export default i18n;
