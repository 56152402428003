import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export type OzProgressProps = CircularProgressProps & {};

const OzProgress = (props: OzProgressProps): JSX.Element => (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress {...props} />
  </Box>
);

export { OzProgress };
