/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../../styles/components/SearchBar.css';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import axios from 'axios';
import PlaceIcon from '@mui/icons-material/Place';
import HotelIcon from '@mui/icons-material/Hotel';
import { IFacilitySearchBoxOption } from '../../models/FacilitySearchBoxOption';
import { IFacilitySearchBoxOptionRequest } from '../../models/FacilitySearchBoxOptionRequest';
import BaseConfig from '../../config/BaseConfig';

interface Props {
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  placeholderParam: (value: IFacilitySearchBoxOption) => void;
}

export const FacilitySearchBar: React.FC<Props> = ({ placeholder, placeholderParam }) => {
  const [wordEntered, setWordEntered] = useState('');
  const [data, setData] = useState<Array<any>>([]);
  const apiClient = axios.create();

  const handleFilter = (event: { target: { value: any; }; }) => {
    const searchWord = event.target.value;

    const lang = localStorage.getItem('language');
    const param = {
      term: searchWord,
      locale: lang,
    } as IFacilitySearchBoxOptionRequest;

    const headers = BaseConfig.utilities.authorizedHeader();

    apiClient.post(
      BaseConfig.api.user('/api/Lookup/GetFacilitySearchBoxList'),
      param,
      {
        headers: JSON.parse(headers),
      },
    ).then(res => {
      setData(res?.data.data);
    });

    if (searchWord === '') {
      setWordEntered('');
    } else {
      setWordEntered(searchWord);
    }
  };

  const handleSelect = (value : IFacilitySearchBoxOption) => {
    setWordEntered(value.name);
    placeholderParam(value);
  };

  const clearInput = () => {
    setData([]);
    setWordEntered('');
  };

  return (
    <>
      <div className="search">
        <div className="searchInputs">
          <input
            type="text"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
            onFocus={handleFilter}
          />
          <div className="facilitySearchBoxIcon">
            {data.length === 0 ? (
              <SearchIcon sx={{ fontSize: 25 }} />
            ) : (
              <SearchOffIcon sx={{ fontSize: 25 }} id="clearSearchBox" onClick={clearInput} />
            )}
          </div>
        </div>
        {data.length > 0 && (
        <div className="facilityDataResult">

          {data.map((value : any, key: any) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              key={key}
              onClick={() => { handleSelect(value); }}
            >

              <a className="dataItem">
                <div className="logo">
                  {value.group === 6 || value.group === 8 || value.group === 200 ? <HotelIcon sx={{ fontSize: 25, color: '#5a5a5a' }} /> : <PlaceIcon sx={{ fontSize: 25, color: '#5a5a5a' }} /> }
                </div>
                <p>
                  {value.name ?? value.globalName}
                </p>
              </a>
            </div>
          ))}
        </div>
        )}
      </div>
    </>
  );
};

export default FacilitySearchBar;
