import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import {
  Alert,
  Button,
  Card,
  CardContent,
  createTheme,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ColorPicker } from 'mui-color';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../components/reservation-button';
import { IPartnerChannel } from '../../models/PartnerChannelModel';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';

const textFieldTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'none',
        size: 'small',
        variant: 'standard',
      },
    },
  },
});

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const buttonLabels = [
  {
    value: 'Rezervasyon Yap',
  },
  {
    value: 'Online Rezervasyon',
  },
  {
    value: 'Hemen Yer Ayırt',
  },
  {
    value: 'Book Now',
  },
];

export const ReservationButton = (): JSX.Element => {
  const { t } = useTranslation();
  const [buttonColor, setButtonColor] = useState<string>('#00ACFF');
  const [buttonLabel, setButtonLabel] = useState<string>('Rezervasyon Yap');
  const [tabValue, setTabValue] = useState(0);

  const partnerId = BaseConfig.utilities.partnerId();

  const apiClient = axios.create();

  const [partnerChannels, setPartnerChannels] = useState <Array <IPartnerChannel>>([]);
  const [selectedPartnerChannel, setSelectedPartnerChannel] = useState<IPartnerChannel>();

  const handleChange = (newValue: any) => {
    setButtonColor(`#${newValue.hex}`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const spanRef = useRef<HTMLAnchorElement>(null);

  const buttonCode = `<a href='http://www.otelz.com/?to=${partnerId}&cid=${selectedPartnerChannel?.partnerChannelId}' style='text-decoration:none !important; padding:7px 14px !important; font-size:14px !important;color:#fff !important; background-color:${buttonColor} !important; border:none !important;background-image: none !important;'>${buttonLabel}</a>`;

  const getPartnerChannels = async () => {
    try {
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
      };
      const headers = BaseConfig.utilities.authorizedHeader();

      const response = await apiClient.post(
        BaseConfig.api.user('/api/PartnerChannel/GetPartnerChannelList'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      const partnerChannelList : Array <IPartnerChannel> = response.data.data;
      partnerChannelList.unshift(
        { partnerChannelId: 0,
          name: 'Satış Kanalı Seçili Değil',
          partnerId: param.partnerId,
          website: '',
          referenceCode: '',
          data: '',
          error: false,
          message: '' },
      );
      setPartnerChannels(partnerChannelList);

      setSelectedPartnerChannel(partnerChannelList[0]);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const changeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonColor(event.target.value);
  };

  const changeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonLabel(event.target.value);
  };

  const handleCopy = () => {
    toast.success(t('operation.operationCopied'));
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = buttonCode;
    }
  }, [buttonCode]);
  useEffect(() => {
    getPartnerChannels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb />
      <Box sx={{ mb: 3 }}>
        <Alert variant="outlined" severity="info">
          {t('reservationButton.reservationButtonInfo')}
        </Alert>
      </Box>
      <Divider />
      <Box>
        <Grid item xs={12} md={3} sx={{ marginTop: 2, marginBottom: 2 }}>
          <TextField
            id="outlined-select-partner-channel"
            select
            size="small"
            defaultValue={0}
            label="Satış Kanalları"
            className="w-100"
          >
            {partnerChannels.map(option => (
              <MenuItem onClick={() => setSelectedPartnerChannel(option)} key={option.partnerChannelId} value={option.partnerChannelId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>
        <Typography
          sx={{ flex: '1 1 100%', marginBottom: 2 }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {t('reservationButton.createResButtonTitle')}
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item lg={3} md={6} xs={12}>
              <Stack
                justifyContent="center"
                divider={<Divider flexItem />}
                spacing={2}
              >
                <Box>
                  <Card>
                    <CardContent>
                      <Typography
                        sx={{ flex: '1 1 100%', marginBottom: 2 }}
                        variant="body2"
                        component="div"
                      >
                        {t('reservationButton.preview')}
                        :
                      </Typography>
                      <span ref={spanRef} />
                    </CardContent>
                  </Card>
                </Box>
                <Box>
                  <Card>
                    <CardContent>
                      <Typography
                        sx={{ flex: '1 1 100%', marginBottom: 2 }}
                        variant="body2"
                        component="div"
                      >
                        {t('reservationButton.kod')}
                        :
                      </Typography>
                      <Stack spacing={2}>
                        <TextField
                          fullWidth
                          size="small"
                          disabled
                          value={buttonCode}
                        />
                        <CopyToClipboard
                          text={buttonCode}
                          onCopy={() => handleCopy()}
                        >
                          <Button
                            variant="contained"
                            endIcon={<ContentCopyIcon />}
                          >
                            {t('reservationButton.copyCode')}
                          </Button>
                        </CopyToClipboard>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              </Stack>
            </Grid>
            <Grid item lg={9} md={6} xs={12}>
              <Card>
                <CardContent>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label={t('reservationButton.general')} {...a11yProps(0)} />
                      <Tab label={t('reservationButton.specific')} {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    <form>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ flex: '1 1 100%', marginRight: 2 }}
                            variant="body2"
                            component="a"
                          >
                            {t('reservationButton.color')}
                            :
                          </Typography>
                          <label htmlFor="field-blue">
                            <input
                              defaultChecked
                              type="radio"
                              name="size"
                              onChange={changeColor}
                              value="#00ACFF"
                              id="blue"
                            />
                            {t('reservationButton.blue')}
                          </label>
                          <label htmlFor="field-green">
                            <input
                              type="radio"
                              name="size"
                              onChange={changeColor}
                              value="#3A9893"
                              id="green"
                            />
                            {t('reservationButton.green')}
                          </label>
                          <label htmlFor="field-yellow">
                            <input
                              type="radio"
                              name="size"
                              onChange={changeColor}
                              value="#DFBA49"
                              id="yellow"
                            />
                            {t('reservationButton.yellow')}
                          </label>
                          <label htmlFor="field-336x280">
                            <input
                              type="radio"
                              name="size"
                              onChange={changeColor}
                              value="#F3565D"
                              id="red"
                            />
                            {t('reservationButton.red')}
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ flex: '1 1 100%', marginRight: 2 }}
                            variant="body2"
                            component="a"
                          >
                            {t('reservationButton.text')}
                            :
                          </Typography>
                          <TextField
                            id="outlined-select-currency"
                            size="small"
                            sx={{ width: 1 / 4 }}
                            select
                            defaultValue="Rezervasyon Yap"
                            className="w-100"
                            onChange={changeLabel}
                          >
                            {buttonLabels.map((option, key) => (
                              <MenuItem key={key} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </form>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <form>
                      <Stack justifyContent="center" spacing={2}>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                          spacing={2}
                        >
                          <Grid item xs={1}>
                            <Typography
                              sx={{ flex: '1 1 100%', marginRight: 2 }}
                              variant="body2"
                              component="a"
                            >
                              {t('reservationButton.color')}
                              :
                            </Typography>
                          </Grid>
                          <Grid item xs={11}>
                            <ThemeProvider theme={textFieldTheme}>
                              <ColorPicker
                                value={buttonColor}
                                onChange={handleChange}
                              />
                            </ThemeProvider>
                          </Grid>
                        </Stack>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                          spacing={2}
                        >
                          <Grid item xs={1}>
                            <Typography
                              sx={{ flex: '1 1 100%', marginRight: 2 }}
                              variant="body2"
                              component="a"
                            >
                              {t('reservationButton.text')}
                              :
                            </Typography>
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              fullWidth
                              onChange={changeLabel}
                              size="small"
                              defaultValue={buttonLabel}
                            />
                          </Grid>
                        </Stack>
                      </Stack>
                    </form>
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReservationButton;
