import axios from 'axios';
import BaseConfig from '../config/BaseConfig';
import { Countries } from '../models/CountriesModel';
import { ISearchBoxOptionRequest } from '../models/PartnerSearchBoxListModel';
import { ICheckApi, IForgotPasswordInput, ILoginFormInput, IRescuePasswordInput } from '../models/UserServiceModel';

export const loginURL = BaseConfig.api.user('/api/User/Login');
const checkAuthURL = BaseConfig.api.user('/api/User/Authorized');
const getPartnerURL = BaseConfig.api.user(
  '/api/Lookup/GetPartnerSearchBoxList',
);
const forgotPasswordURL = BaseConfig.api.user(
  '/api/User/ForgotPassword',
);
const rescuePasswordURL = BaseConfig.api.user(
  '/api/User/RescuePassword',
);
const checkAPIURL = BaseConfig.api.user(
  '/api/User/CheckApiKey',
);
const getLocalizationURL = BaseConfig.api.user(
  '/api/localization/localizations/{lang}.json',
);
const saveLocalizationURL = BaseConfig.api.user(
  '/api/localization/localizations',
);
const getCountriesRequest = BaseConfig.api.user(
  '/api/Lookup/GetCountriesRequest',
);

const apiClient = axios.create();

export const AuthService = {
  async Login(data: ILoginFormInput) {
    localStorage.setItem('login', 'false');
    const response = await apiClient.post<ILoginFormInput>(loginURL, data);
    return response;
  },

  // eslint-disable-next-line consistent-return
  async CheckAuth() {
    const check = localStorage.getItem('login');
    if (check !== null && check !== 'false' && new Date(check) > new Date(new Date().getTime() - 60 * 60000)) {
      return { status: 200,
        data: {
          error: false,
          message: null,
          data: null,
          common: null,
        } };
    }
    try {
      const headers = BaseConfig.utilities.authorizedHeader();
      const response = await apiClient.get(checkAuthURL, {
        headers: JSON.parse(headers),
      });
      if (response?.data.error === false) {
        localStorage.setItem('login', (new Date()).toString());
      }
      return response;
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.setItem('login', 'false');
        return {};
      }
    }
  },

  // eslint-disable-next-line consistent-return
  async GetPartner(data: ISearchBoxOptionRequest) {
    const headers = BaseConfig.utilities.authorizedHeader();
    const response = await apiClient.post<ISearchBoxOptionRequest>(
      getPartnerURL,
      data,
      {
        headers: JSON.parse(headers),
      },
    );
    return response;
  },

  // eslint-disable-next-line consistent-return
  async ForgotPassword(data: IForgotPasswordInput) {
    const response = await apiClient.post<IForgotPasswordInput>(forgotPasswordURL, data);
    return response;
  },

  // eslint-disable-next-line consistent-return
  async RescuePassword(data: IRescuePasswordInput) {
    const response = await apiClient.post<IRescuePasswordInput>(rescuePasswordURL, data);
    return response;
  },

  // eslint-disable-next-line consistent-return
  async CheckApi(data: ICheckApi) {
    const response = await apiClient.post<ICheckApi>(checkAPIURL, data);
    return response;
  },

  // eslint-disable-next-line consistent-return
  async GetLocalization(locale: string) {
    try {
      const response = await apiClient.get(getLocalizationURL.replace('{lang}', locale));
      return response;
    } catch (error) {
      if (error.response?.status === 401) {
        return {};
      }
    }
  },

  // eslint-disable-next-line consistent-return
  async GetCountriesRequest() {
    const lang = localStorage?.getItem('language') || 'tr';
    const response = await apiClient.post<Countries>(getCountriesRequest, { lang });
    return response;
  },

  // eslint-disable-next-line consistent-return
  async SaveTranslations() {
    const response = await apiClient.put(saveLocalizationURL);
    return response;
  },

};
