/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PartnerLandingLogo from '../images/logo-landing';
import { AppLanguage } from '../../const/app-languages';
import '../../styles/webapp/global.scss';
import { CONTACT_PATH, FAQ_PATH } from '../../routes/Paths';

const HeaderSectionDiv = styled.header`
  padding:12px 0;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  .container{
    .row{
      .col-6{
        &:nth-child(2){
          text-align:right;
          padding-top: 8px;
          .lang{
            position:relative ;
            border-color:#c3cad6;
            margin:0 16px 0 0;
            &:hover{
              background:white;
            }
            img{
              float: left;
              margin: 2px 4px 0 0;
            }
            .langList{
              position:absolute;
              background:white ;
              padding:12px;
              box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
              top: calc(100% + 2px);
              left: 0;
              border-radius:4px;
              ul{
                width:80px;
                li{
                  padding:4px;
                  border-radius: 4px;
                  img{
                    float: left;
                    margin: 2px 4px 0 0;
                  }
                  &:hover{
                    background:#f5f5f5;
                  }
                }
              }
            }
          }
        }
        &:nth-child(3){
          display:none;
        }
      }
    }
  }
  
  .hamburger {
    position:absolute ;
    display: flex;
    height: 26px;
    width: 32px;
    top: 12px;
    right:.75rem;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    span {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #0e2431;
      &:first-child{
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
      }
      &:nth-child(2){
        transition: transform 0.2s ease-in-out;
      }
      &:last-child{
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
      }
    }
    &.active{
      span {
        &:first-child{
          transform: rotate(45deg);
        }
        &:nth-child(2){
          transform: scaleY(0);
        }
        &:last-child{
          transform: rotate(-45deg);
        }
      }
    }
  }

  @media(max-width:550px) {
    .container{
      .row{
        .col-6{
          &:nth-child(2){
            display: none;
          }
          &:nth-child(3){
            position:relative ;
            display: flex;
            .hamburger{
              
            }
          }
        }
      }
    }
  }
`;

const HeaderLink = styled.div`
  display:inline-block ;
  font-size: 14px;
  font-weight: 600;
  margin:0 16px 0 0;
  &:hover{
    border-bottom:2px solid var(--oz-purple);
  }
`;

const MenuSection = styled.div`
  display:none;
  border-top:1px solid var(--border-color);
  padding:40px 30px 0 30px;
  position:fixed;
  top:76px;
  right:-100%;
  width:100%;
  height:calc(100vh - 76px);
  background-color:white;
  z-index:10; 
  transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  &.active{
    right:0;
  }

  ul{
    text-align:center;
    margin-bottom:48px;
    li{
      margin-bottom:12px; 
      a{
        font-size:22px;
        font-weight:500;
        line-height:34px;
      }
    }
  }
  .lang{
    line-height:36px!important;
    width:100%;
    border-width:2px!important;
    margin-bottom:12px;
    border-radius:8px;
    line-height: 47px;
    text-align:left;
    img{
      float: left;
      margin: 12px 8px 0 0;
    }
    >span{
      line-height: 46px;
    }
    >div{
      float:right;
      border-left:2px solid var(--oz-purple);
      border-radius:0;
      padding:0 6px;
      margin:-6px 0;
      line-height:56px;
      text-align:center ;
    }
    .langList{
      position:absolute;
      background:white ;
      padding:12px;
      top: calc(100% + 8px);
      left: -3px;
      width: calc(100% + 6px);
      z-index: 20;
      border-left: 0;
      box-shadow: 0 10px 40px 0 rgb(0 0 0 / 25%);
      box-shadow: 0 10px 40px 0rgba(0,0,0,0.08);
      border-radius: 8px;
      ul{
        padding-top:12px;
        margin-bottom: 12px;
        li{
          padding:4px;
          text-align:left;
          font-size:16px;
          line-height: 22px;
          img{
            margin: 2px 12px 0 0;
          }
          &:hover{
            background:#f5f5f5;
          }
        }
      }
    }
  }
  @media(max-width:550px) {
    display:block;
  }
`;

export const HeaderSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState<any>({ param: 'TR', code: 'tr', label: 'Türkçe' });
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenuFN] = useState(false);
  const dropdown = React.createRef<HTMLDivElement>();

  const countries = [
    { param: 'EN', code: 'en', flag: 'gb', label: 'English' },
    { param: 'TR', code: 'tr', flag: 'tr', label: 'Türkçe' },
  ];

  const changeLanguage = (langCode : string) => {
    i18n.changeLanguage(langCode);
    setCurrentLang(countries.find(x => x.code === langCode));
  };

  const setShowMenu = (status:boolean) => {
    setShowMenuFN(status);
    const { body } = document;
    if (status && body) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  };

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      if ((Object.values(AppLanguage) as string[]).includes(language)) {
        setCurrentLang(countries.find(x => x.code === language));
      }
    }
    return () => {
      const { body } = document;
      body.style.overflow = '';
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showDropdown) return;
    function closeDropdown(event: MouseEvent) {
      if (
        showDropdown &&
        dropdown.current &&
        event.target &&
        !dropdown.current.contains(event.target as HTMLDivElement)
      ) {
        setShowDropdown(false);
      }
    }
    window.addEventListener('click', closeDropdown);
    return () => window.removeEventListener('click', closeDropdown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <>
      <HeaderSectionDiv>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <Link to="/">
                <PartnerLandingLogo height="50" />
              </Link>
            </div>
            <div className="col-6">
              <HeaderLink>
                <Link to={FAQ_PATH}>
                  {t('landing.menu.sss')}
                </Link>
              </HeaderLink>
              <HeaderLink>
                <Link to={CONTACT_PATH}>
                  {t('landing.menu.callYou')}
                </Link>
              </HeaderLink>
              <div className={`btn btn-border lang${showDropdown ? ' show' : ''}`} onClick={() => setShowDropdown(!showDropdown)} aria-hidden>
                {currentLang && (
                <>
                  <img
                    loading="lazy"
                    style={{ width: '30px', height: '20px' }}
                    src={`https://flagcdn.com/w40/${currentLang.flag}.png`}
                    alt={`Flag of ${currentLang.label}`}
                  />
                  <span>{currentLang.param}</span>
                </>
                )}
                {showDropdown && (
                <div className="langList" ref={dropdown}>
                  <ul>
                    {countries.map(c => (
                      <li key={c.code} onClick={() => changeLanguage(c.code)} aria-hidden>
                        <img
                          loading="lazy"
                          style={{ width: '30px', height: '20px' }}
                          src={`https://flagcdn.com/w20/${c.flag}.png`}
                          srcSet={`https://flagcdn.com/w40/${c.flag}.png 2x`}
                          alt={`Flag of ${c.label}`}
                        />
                        <span>{c.param}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                )}
              </div>
              <Link className="btn btn-border" to="login">
                {t('login.login')}
              </Link>
            </div>
            <div className="col-6">
              <div className={`hamburger ${showMenu ? 'active' : ''}`} onClick={() => setShowMenu(!showMenu)} aria-hidden="true">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </HeaderSectionDiv>
      <MenuSection className={showMenu ? 'active' : ''}>
        <ul>
          <li>
            <Link to="/">
              {t('landing.menu.home')}
            </Link>
          </li>
          <li>
            <Link to={FAQ_PATH}>
              {t('landing.menu.sss')}
            </Link>
          </li>
          <li>
            <Link to={CONTACT_PATH}>
              {t('landing.menu.callYou')}
            </Link>
          </li>
        </ul>
        <div className={`btn btn-border lang${showDropdown ? ' show' : ''}`} onClick={() => setShowDropdown(!showDropdown)} aria-hidden>
          {currentLang && (
          <>
            <img
              loading="lazy"
              style={{ width: '30px', height: '20px' }}
              src={`https://flagcdn.com/w40/${currentLang.flag}.png`}
              alt={`Flag of ${currentLang.label}`}
            />
            <span>
              {' '}
              {currentLang.param}
            </span>
            <div className="btn">{t('landing.menu.change')}</div>
          </>
          )}
          {showDropdown && (
          <div className="langList" ref={dropdown}>
            <ul>
              {countries.map(c => (
                <li key={c.code} onClick={() => changeLanguage(c.code)} aria-hidden>
                  <img
                    loading="lazy"
                    style={{ width: '30px', height: '20px' }}
                    src={`https://flagcdn.com/w20/${c.flag}.png`}
                    srcSet={`https://flagcdn.com/w40/${c.flag}.png 2x`}
                    alt={`Flag of ${c.label}`}
                  />
                  <span>{c.label}</span>
                </li>
              ))}
            </ul>
          </div>
          )}
        </div>
        <Link className="btn btn-border block big" to="login">
          {t('login.login')}
        </Link>
      </MenuSection>
    </>
  );
};
export default HeaderSection;
