/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ConditionsSection from './Conditions';
import FormSection from './Form';
import Popup from './Popup';

const OptionsSectionDiv = styled.div`
  padding:90px 0 ;
  text-align:center;
  background-color: #f8f8f8;
  .container{
    h2{
      font-size: 42px;
      font-weight: bold;
      color:#5664d2;
      margin:0 0 48px 0;
    }
    >.btns{
      display:none;
      margin-bottom:24px;
    }
  }

  .box{
    padding: 0 32px;
    >div{
      padding: 60px 36px;
      border-radius: 24px;
      box-shadow: 0 12px 54px 0 #ebebeb;
      background-color: #fff;
      overflow: hidden;
      /* max-height: 470px; */
      transition:all .4s ease-in-out ;
      .icon{
        height:120px;
        background-size: auto 100%;
        background-repeat:no-repeat;
        background-position:center ;
        margin-bottom:32px;

      }
      .title{
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom:20px;
      }
      .desc{
        font-size: 20px;
        line-height: 1.5;
        color: #69717e;
        min-height: 160px;
        margin-bottom:12px;
      }
      .btn{
        margin-bottom:18px;
      }
      /* &:hover{
        max-height:1000px ;
      } */
    }
    &:first-child{
      .icon{
        background-image:url('img/landing/congratulations.svg');
      }
    }
    &:nth-child(2){
      .icon{
        background-image:url('img/landing/influencer.svg');
      }
    }
    &:last-child{
      .icon{
        background-image:url('img/landing/gear.svg');
      }
    }
  }
  .banner{
    background-size:100%;

  }
  @media(max-width:550px) {
    padding:20px 0 ;
    .container{
      h2{
        font-size: 28px;
        font-weight: bold;
        margin:0 0 20px 0;
      }
      >.btns{
        display:flex;
      }
      >.row{
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        &::-webkit-scrollbar {
          -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
          width: 0;
          height: 0;
        }
        .box{
          >div{
            /* max-height:unset; */
            padding: 20px 18px;
            .icon{
              height:80px;
            }
            .title{
              font-size:24px;
            }
            .desc{
              font-size:16px;
              min-height:80px;
              margin-bottom:24px;
            }
          }
        }
      }
    }
  }
`;

export const OptionsSection: React.FC = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopupFN] = useState(false);
  const [popupType, setPopupType] = useState(1);

  const [type, setType] = useState(0);

  const options = [
    {
      title: 'Affiliate',
      desc: `${t('landing.affiliateBodyOne')} ${t('landing.affiliateBodyTwo')} ${t('landing.affiliateBodyThree')}`,
      type: 1,
    },
    {
      title: 'Influencer',
      desc: `${t('landing.influencerBodyOne')} ${t('landing.influencerBodyTwo')} ${t('landing.influencerBodyThree')}`,
      type: 4,
    },
    {
      title: 'API',
      desc: `${t('landing.apiBodyOne')} ${t('landing.apiBodyTwo')} ${t('landing.apiBodyThree')}`,
      type: 3,
    },
  ];

  const setShowPopup = (status: boolean) => {
    setShowPopupFN(status);
    const body = document.querySelector('body');
    if (body && status) {
      body.style.overflow = 'hidden';
    } else if (body) {
      body.style.overflow = '';
    }
  };

  const showForm = (formType: number) => {
    setPopupType(1);
    setType(formType);
    setShowPopup(true);
  };

  const showConditions = (formType: number) => {
    setPopupType(2);
    setType(formType);
    setShowPopup(true);
  };

  const setActiveButton = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    options.forEach(x => {
      document.querySelector(`.${x.title}`)?.classList.remove('btn-purple');
      document.querySelector(`.${x.title}`)?.classList.add('btn-border');
    });
    e.currentTarget.classList.add('btn-purple');
    e.currentTarget.classList.remove('btn-border');
  };

  return (
    <>
      <OptionsSectionDiv id="options">
        <div className="container">
          <h2>{t('landing.partnerOptions')}</h2>
          <div className="row btns">
            {options.map((x, key) => (
              <div className="col-4" key={key}>
                <div
                  className={`btn block ${x.title} ${key === 0 ? 'btn-purple' : 'btn-border'}`}
                  onClick={e => {
                    if (document) {
                      setActiveButton(e);
                      document.getElementById(`${x.title}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                  }}
                  aria-hidden="true"
                >
                  {x.title}
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {options.map(x => (
              <div className="col-4 col-sm-12 box" key={x.type} id={x.title}>
                <div>
                  <div className="icon" />
                  <div className="title">{x.title}</div>
                  <div className="desc">{x.desc}</div>
                  <div className="btns">
                    <div className="btn btn-purple big block" onClick={() => showForm(x.type)} aria-hidden>
                      {t('landing.applyNow')}
                    </div>
                    <div className="btn btn-border big block" onClick={() => showConditions(x.type)} aria-hidden>
                      {t('landing.conditions')}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </OptionsSectionDiv>
      {showPopup && (
      <Popup tooglePopup={setShowPopup}>
        {popupType === 1 ? (
          <FormSection type={type} />
        ) : (
          <ConditionsSection type={type} />
        )}
      </Popup>
      )}

    </>
  );
};
export default OptionsSection;
