import { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFirm } from '../../models/FirmModel';

interface Props {
  // eslint-disable-next-line no-unused-vars
  firmInfo: IFirm;
}

export const Form: React.FC<Props> = ({ firmInfo }) => {
  const { t } = useTranslation();

  const partnerTypes = [
    {
      value: '1',
      label: 'API',
    },
    {
      value: '2',
      label: 'Influencer',
    },
    {
      value: '3',
      label: t('firm.advertisingChannel'),
    },
    {
      value: '4',
      label: t('firm.staff'),
    },
  ];

  const profitSharingTypes = [
    {
      value: '1',
      label: t('firm.fixedCommission'),
    },
    {
      value: '2',
      label: t('firm.profitSharing'),
    },
  ];

  const onSubmit = () => {
  };

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-empty-pattern
    formState: { },
  } = useForm<IFirm>();

  useEffect(() => {
  }, [firmInfo]);

  if (firmInfo) {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('partnerName', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    label={t('label.firstname')}
                    name="firstName"
                    defaultValue={firmInfo.partnerName !== '' ? firmInfo.partnerName : ' '}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('commercialName', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    label={t('firm.commercialName')}
                    name="commercialName"
                    defaultValue={firmInfo.commercialName !== '' ? firmInfo.commercialName : ' '}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('commissionRate', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    label={t('firm.commissionRate')}
                    name="commissionRate"
                    defaultValue={firmInfo.commissionRate}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <div style={{ width: '100%' }}>
                    <Box
                      sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }}
                    >
                      <TextField
                        {...register('profitSharingType')}
                        id="outlined-select-currency"
                        disabled
                        select
                        label={t('firm.profitSharingType')}
                        defaultValue={firmInfo.profitSharingType}
                        className="w-100"
                      >
                        {profitSharingTypes.map((option, key) => (
                          <MenuItem key={key} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('taxOfficce', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    label={t('firm.taxOfficce')}
                    name="taxOfficce"
                    defaultValue={firmInfo.taxOfficce !== '' ? firmInfo.taxOfficce : ' '}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('taxNumber', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    label={t('firm.taxNumber')}
                    name="taxNumber"
                    defaultValue={firmInfo.taxNumber !== '' ? firmInfo.taxNumber : ' '}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    {...register('currentCode', {
                      required: true,
                    })}
                    fullWidth
                    disabled
                    label={t('firm.currentCode')}
                    name="currentCode"
                    defaultValue={firmInfo.currentCode !== '' ? firmInfo.currentCode : ' '}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <div style={{ width: '100%' }}>
                    <Box
                      sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }}
                    >
                      <TextField
                        {...register('partnerType')}
                        id="outlined-select-currency"
                        disabled
                        select
                        label={t('firm.partnerType')}
                        defaultValue={firmInfo.partnerType}
                        className="w-100"
                      >
                        {partnerTypes.map((option, key) => (
                          <MenuItem key={key} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </form>
      </>
    );
  }

  return <Divider />;
};
