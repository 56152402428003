import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

export const BankAccountInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
      <TableHead>
        <TableRow>
          <TableCell align="left">{t('bankInfo.accountName')}</TableCell>
          <TableCell align="left">{t('bankInfo.bankName')}</TableCell>
          <TableCell align="left">{t('bankInfo.branchName')}</TableCell>
          <TableCell align="left">{t('bankInfo.branchCode')}</TableCell>
          <TableCell align="left">{t('bankInfo.accountNumber')}</TableCell>
          <TableCell align="left">IBAN</TableCell>
          <TableCell align="left">{t('bankInfo.currencyUnit')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Yapi ve Kredi Bankasi</TableCell>
          <TableCell>MERKEZ PLAZA</TableCell>
          <TableCell>00332</TableCell>
          <TableCell>92168094</TableCell>
          <TableCell>TR780006701000000092168094</TableCell>
          <TableCell>TRY</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Yapi ve Kredi Bankasi</TableCell>
          <TableCell>MERKEZ PLAZA</TableCell>
          <TableCell>00332</TableCell>
          <TableCell>92168107</TableCell>
          <TableCell>TR180006701000000092168107</TableCell>
          <TableCell>USD</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Yapi ve Kredi Bankasi</TableCell>
          <TableCell>MERKEZ PLAZA</TableCell>
          <TableCell>00332</TableCell>
          <TableCell>92168114</TableCell>
          <TableCell>TR230006701000000092168114</TableCell>
          <TableCell>EUR</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Finansbank</TableCell>
          <TableCell>SEYRANTEPE</TableCell>
          <TableCell>01087</TableCell>
          <TableCell>69498826</TableCell>
          <TableCell>TR350011100000000069498826</TableCell>
          <TableCell>TRY</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Finansbank</TableCell>
          <TableCell>SEYRANTEPE</TableCell>
          <TableCell>01087</TableCell>
          <TableCell>69499156</TableCell>
          <TableCell>TR490011100000000069499156</TableCell>
          <TableCell>USD</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Finansbank</TableCell>
          <TableCell>SEYRANTEPE</TableCell>
          <TableCell>01087</TableCell>
          <TableCell>69532278</TableCell>
          <TableCell>TR950011100000000069532278</TableCell>
          <TableCell>EUR</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Garanti Bankasi</TableCell>
          <TableCell>1. LEVENT TICARI</TableCell>
          <TableCell>186</TableCell>
          <TableCell>6294019</TableCell>
          <TableCell>TR380006200018600006294019</TableCell>
          <TableCell>TRY</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Garanti Bankasi</TableCell>
          <TableCell>1. LEVENT TICARI</TableCell>
          <TableCell>186</TableCell>
          <TableCell>9032875</TableCell>
          <TableCell>TR400006200018600009032875</TableCell>
          <TableCell>USD</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Garanti Bankasi</TableCell>
          <TableCell>1. LEVENT TICARI</TableCell>
          <TableCell>186</TableCell>
          <TableCell>9032874</TableCell>
          <TableCell>TR150006200070300009032874</TableCell>
          <TableCell>EUR</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Garanti Bankasi</TableCell>
          <TableCell>1. LEVENT TICARI</TableCell>
          <TableCell>186</TableCell>
          <TableCell>9031757</TableCell>
          <TableCell>TR070006200070300009031757</TableCell>
          <TableCell>GBP</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>Denizbank</TableCell>
          <TableCell>MASLAK SUBESI</TableCell>
          <TableCell>02100</TableCell>
          <TableCell>0001792852900001</TableCell>
          <TableCell>TR840013400001792852900001</TableCell>
          <TableCell>TRY</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>İş Bankası</TableCell>
          <TableCell>DÜNYA TİCARET MERKEZİ/İSTANBUL</TableCell>
          <TableCell>1177</TableCell>
          <TableCell>0000111770226146</TableCell>
          <TableCell>TR270006400000111770226146</TableCell>
          <TableCell>TRY</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>İş Bankası</TableCell>
          <TableCell>DÜNYA TİCARET MERKEZİ/İSTANBUL</TableCell>
          <TableCell>1177</TableCell>
          <TableCell>0000211770095157</TableCell>
          <TableCell>TR720006400000211770095157</TableCell>
          <TableCell>USD</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Zonline Ticaret ve Turizm A.S</TableCell>
          <TableCell>İş Bankası</TableCell>
          <TableCell>DÜNYA TİCARET MERKEZİ/İSTANBUL</TableCell>
          <TableCell>1177</TableCell>
          <TableCell>0000211770095161</TableCell>
          <TableCell>TR610006400000211770095161</TableCell>
          <TableCell>EUR</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export { BankAccountInfo as default };
