import moment from 'moment';
import { IGeneralResponse, Lookup } from './GeneralModel';

export interface ICancelReservationReportFilter extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  queryText: string;
  reservationDateType: number;
  startDate: any;
  endDate: any;
  reservationState: number;
  currencyType: number;
  paymentType: number;
  pageNumber: number;
  pageSize: number;
  partnerId: number;
  partnerChannelId: number;
  field:string;
  sort:string;
  reservationNumber:string;
  facilityName:string;
  isRequest:boolean;
}

// const today = new Date();
const date = `${moment(new Date()).add(1, 'days').format('DD.MM.YYYY')}`;
const oneYearAgo = `${moment(new Date()).format('DD.MM.YYYY')}`;
export const defaultCancelReservationReportFilter: ICancelReservationReportFilter = {
  queryText: '',
  reservationDateType: 1,
  startDate: oneYearAgo,
  endDate: date,
  reservationState: 0,
  currencyType: 0,
  paymentType: 0,
  pageNumber: 1,
  pageSize: 10,
  partnerChannelId: 0,
  isRequest: true,
} as ICancelReservationReportFilter;

export interface IReservationList {
  // eslint-disable-next-line camelcase
  id: number;
  reservationDate: string;
  reservationNumber: string;
  totalResults: number;
}

export interface CurrencyLookup {
  enumId: string;
  id: string;
}

export const reservationState = [
  { label: 'reservationReport.allReservation', value: '0' },
  { label: 'reservationReport.noShowReservation', value: '2' },
  { label: 'reservationReport.cancellationReservation', value: '3' },
];

export const paymentTypes: Array<Lookup> = [
  { label: 'reservationReport.unknow', id: '0' },
  { label: 'reservationReport.creditCard', id: '1' },
  { label: 'reservationReport.bankTransfer', id: '2' },
  { label: 'reservationReport.unGuaranteed', id: '3' },
  { label: 'reservationReport.onlinePayment', id: '10' },
  { label: 'reservationReport.onlinePaymentAgent', id: '11' },
  { label: 'reservationReport.virtualCard', id: '12' },
  { label: 'reservationReport.otelzWallet', id: '20' },
];

export interface ICancelReservationListSummary {
  // eslint-disable-next-line camelcase
  totalTRY: number;
  totalRooms: number;
  totalAdults: number;
  totalNight: number;
  totalAgencyCommision: number;
  totalCancelFeeTRY: number;
}

export const defaultCancelReservationListSummary: ICancelReservationListSummary = {
  totalTRY: 0,
  totalRooms: 0,
  totalAdults: 0,
  totalNight: 0,
  totalAgencyCommision: 0,
} as ICancelReservationListSummary;

export interface ICancelReservationReport {
  // eslint-disable-next-line camelcase
  adults:number;
  agentCommision:number;
  agentCommisionLabel:string;
  agentCommisionRate:number;
  agentCommisionTRY:number;
  agentCommisionTRYLabel:string;
  cancelationDate:Date;
  checkinDate:Date;
  checkoutDate:Date;
  children:number;
  crossRate:number;
  currency:string;
  customerName:string;
  facilityId:number;
  facilityName:string;
  id:number;
  isTRY:boolean;
  paymentType:number;
  reservationDate:Date;
  reservationGuid:string;
  reservationId:number;
  reservationNumber:string;
  reservationPrice:number;
  reservationPriceLabel:string;
  reservationPriceTRY:number;
  reservationPriceTRYLabel:string;
  status:number;
  totalNight:number;
  totalResults:number;
  totalRooms:number;
  calculatedCancelFeeTRY:number;
}

export interface ICancelReservationReportExcel {
  // eslint-disable-next-line camelcase
  reservationNumber:string| undefined;
  reservationDate:Date| undefined;
  cancelationDate:Date| undefined;
  status:number| undefined;
  facilityName:string| undefined;
  customerName:string| undefined;
  checkinDate:Date| undefined;
  checkoutDate:Date| undefined;
  totalNight:number| undefined;
  totalRooms:number| undefined;
  children:number| undefined;
  paymentType:number| undefined;
  reservationPrice:number| undefined;
  currency:string| undefined;
  reservationPriceTRY:number| undefined;
  crossRate:number| undefined;
  agentCommisionRate:number| undefined;
  agentCommision:number| undefined;
  agentCommisionTRY:number| undefined;
}
