import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios, { AxiosError } from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Divider, TableHead, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import BaseConfig from '../../config/BaseConfig';
import { BankAccountInfo, Breadcrumb } from '../../components/invoice';
import localePrice from '../../utilities/localePriceHelper';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line no-unused-vars
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

type ServerError = {
  code: string;
  description: string;
};

type TableTypes = {
  // eslint-disable-next-line camelcase
  paymentStatus: boolean
  date: string
  expiryDate: string,
  invoiceNumber: string
  grossTotal: number
  netTotal: number
  paidAmount: number
  dept: number
  description: string
};

export const Invoice = (): JSX.Element => {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState <Array <TableTypes>>([]);

  const getTable = async () => {
    try {
      const headers = BaseConfig.utilities.authorizedHeader();
      const apiClient = axios.create();
      const param = {
        partnerId: BaseConfig.utilities.partnerId(),
        currentCode: BaseConfig.utilities.currentCode(),
      };
      const response = await apiClient.post(BaseConfig.api.user('/api/Finance/GetInvoiceList'), param, {
        headers: JSON.parse(headers),
      });

      // create new array to push coming value to inside
      setRows(response.data.data);
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');

  return (
    <>
      <Breadcrumb />
      <Box sx={{ mb: 3 }}>
        <Button startIcon={<RemoveRedEyeIcon />} variant="outlined" onClick={handleClickOpen}>
          {t('invoices.ourBankAccountInformation')}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle>
            {t('invoices.ourBankAccountInformation')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: 15 }}>
              {t('invoices.invoicesDesc')}
            </DialogContentText>
            <BankAccountInfo />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('button.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Divider />

      <Box sx={{ mt: 2, p: 2, boxShadow: 5 }}>

        <Typography
          sx={{ flex: '1 1 100%', marginBottom: 2 }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {t('partnerMenu.invoiceList')}
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('common.status')}</TableCell>
                <TableCell align="left">
                  {t('invoices.invoiceNumber')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.invoiceDate')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.lastPaymentDate')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.invoiceAmount')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.paid')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.arrears')}
                </TableCell>
                <TableCell align="left">
                  {t('invoices.desc')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row, index) => (
                <TableRow key={index}>
                  {row.dept === 0 ? (
                    <TableCell style={{ width: 160 }} align="left">

                      <CheckIcon style={{
                        color: '#00a514',
                        top: '4px',
                        position: 'relative',
                      }}
                      />
                      {t('invoices.receipt')}
                    </TableCell>
                  ) :
                    (
                      <TableCell style={{ width: 160 }} align="left">
                        <ClearIcon style={{
                          color: '#b71818',
                          top: '4px',
                          position: 'relative',
                        }}
                        />
                        {t('invoices.notPaid')}
                      </TableCell>
                    )
                  }
                  <TableCell style={{ width: 160 }} align="left">
                    {row.invoiceNumber}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {moment(row.date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {moment(row.expiryDate).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.grossTotal, 'TL', 2)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.paidAmount, 'TL', 2)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {localePrice('tr', row.dept, 'TL', 2)}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.description}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

    </>
  );
};

export default Invoice;
