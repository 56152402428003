import React from 'react';
import { AppRouteModel } from '../../routes/Map';
import RootContext from './RootContext';

interface RootContextProps {
  routes: AppRouteModel[];
  children: React.ReactNode;
}

const RootContextProvider: React.FC<RootContextProps> = ({ routes, children }: RootContextProps) => (
  <RootContext.Provider value={{ routes }}>{children}</RootContext.Provider>
);

export default RootContextProvider;
