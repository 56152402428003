/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DrawerContext from '../../context/drawer/DrawerContext';
import { DrawerProps } from '../../context/drawer/DrawerContextProps';
import '../../styles/components/_headers.scss';
import SearchBar from './searchbar/SearchBar';
import { AuthContext } from '../../context/AuthContext';
import { ISearchBoxOption } from '../../models/SearchBoxModel';
import { ISearchBoxOptionRequest } from '../../models/PartnerSearchBoxListModel';
import { Service } from '../../services';
import { LOGIN_PATH, PROFILE_PATH } from '../../routes/Paths';
import Language from './language/Language';
import BaseConfig from '../../config/BaseConfig';

const RightBar = styled.div`
  float:right;
  width:calc(100% - 120px);
  text-align:right;
  >div, >a{
    display:inline-block;
  }
  @media(max-width:550px) {
    width:calc(100% - 45px);
    .languageSelect, .profile-bar{
      display:none;
    }
  }
`;

// eslint-disable-next-line no-unused-vars
const LayoutHeader: React.FC<any> = ({ sidebarVisible }) => {
  const routerHistory = useHistory();
  const { t } = useTranslation();
  const [fullname, setFullname] = useState <String>('');
  const [placeholder, setPlaceholder] = useState <any>('Partner Seçimi');
  const { authorized, setAuth } = useContext(AuthContext);

  const placeholderParam = (value: ISearchBoxOption): void => {
    // takes the first element at the top of the list as a current" partner
    localStorage.setItem('partner', JSON.stringify(value));
    // eslint-disable-next-line no-debugger
    setPlaceholder(value.globalName);
  };

  const LogOut = (): void => {
    setAuth(false);
    localStorage.removeItem('user');
    localStorage.removeItem('partner');
    localStorage.removeItem('login');
    routerHistory.push(LOGIN_PATH);
  };

  useEffect(() => {
    if (authorized) {
      if (localStorage.getItem('user') !== null) {
        setFullname(JSON.parse(localStorage.getItem('user') || '').name);
        const param = {
          term: '',
          lang: 'tr',
          securityId: BaseConfig.utilities.securityId(),
          partnerId: BaseConfig.utilities.partnerIdInUserObject(),
        } as ISearchBoxOptionRequest;
        if (localStorage.getItem('partner') === null) {
          Service.User.GetPartner(param).then(res => {
            // takes the first element at the top of the list as a current" partner
            localStorage.setItem('partner', JSON.stringify(res?.data.data.shift()));
            setPlaceholder(JSON.parse(localStorage.getItem('partner') || '').globalName);
          });
        }
      }
      if (localStorage.getItem('partner') !== null) {
        setPlaceholder(JSON.parse(localStorage.getItem('partner') || '').globalName);
      }
    }
  }, [authorized]);

  const drawerContext = useContext<DrawerProps>(DrawerContext);

  const partnerId = BaseConfig.utilities.partnerId();

  return (
    <header className="main-header">
      <div className="menu-toggle" onClick={() => drawerContext.toggle()}>
        <div />
        <div />
        <div />
      </div>
      <RightBar>
        <Language />
        <div className="search-bar">
          <SearchBar placeholder={placeholder} placeholderParam={placeholderParam} />
        </div>

        <Link style={{ marginLeft: '15px' }} to={{ pathname: `https://www.otelz.com/?to=${partnerId}` }} target="_blank">
          <Button
            className="w-100"
            size="medium"
            type="submit"
            variant="contained"
            color="secondary"
            style={{ lineHeight: '34px' }}
          >
            {t('layoutHeader.doSale')}
          </Button>
        </Link>

        <div className="profile-bar" style={{ marginRight: '15px', marginLeft: '15px' }}>

          <Link to={PROFILE_PATH}>
            <Button startIcon={<AccountCircleIcon />} color="inherit">
              {fullname}
            </Button>
          </Link>
        </div>

        <IconButton color="primary" aria-label="log out">
          <LogoutIcon onClick={() => {
            LogOut();
          }}
          />
        </IconButton>
        <IconButton color="primary" aria-label="log out">
          <OpenWithIcon
            data-fullscreen
            onClick={() => {
              if (document.fullscreenEnabled) {
                if (!document.fullscreen) document.documentElement.requestFullscreen();
                else document.exitFullscreen();
              }
            }}
          />
        </IconButton>
      </RightBar>

    </header>
  );
};

export default LayoutHeader;
