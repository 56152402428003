import { useEffect, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { LOGIN_PATH } from '../../routes/Paths';
import {
  defaultRescuePasswordInput,
  ICheckApi,
  IRescuePasswordInput,
} from '../../models/UserServiceModel';
import 'react-toastify/dist/ReactToastify.css';
import { Service } from '../../services';
import { ServerError } from '../../models/AxiosModel';
import PartnerLandingLogo from '../../components/images/logo-landing';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      otelz.com
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const theme = createTheme();

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const routerHistory = useHistory();

  const [password, setPassword] = useState<IRescuePasswordInput>(
    defaultRescuePasswordInput,
  );

  const [loginButtonStyle, setLoginButtonStyle] = useState({
    backgroundColor: 'transparent',
    textDecoration: 'none',
    color: '#5664d2',
  });

  const handleLoginButtonStyle = (hover : any) => {
    setLoginButtonStyle({
      // not set for now
      backgroundColor: hover ? 'transparent' : 'transparent',
      textDecoration: 'none',
      color: '#5664d2',
    });
  };

  // gets parameter from url
  const { search } = useLocation();
  password.id = new URLSearchParams(search).get('id') ?? '';

  const handleChange =
    (prop: keyof IRescuePasswordInput) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...password, [prop]: event.target.value });
      };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRescuePasswordInput>();

  const rescuePassword = (data: IRescuePasswordInput) => {
    try {
      data.id = password.id;
      Service.User.RescuePassword(data).then(res => {
        if (!res?.data?.error) {
          toast.success(t('operation.operationSuccess'));
          routerHistory.push(LOGIN_PATH);
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (err) {
      if (err && err.response) {
        toast.error(t('operation.operationFailed'));
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const checkApi = () => {
    try {
      const model : ICheckApi = {
        id: password.id,
      } as ICheckApi;

      Service.User.CheckApi(model).then(res => {
        if (res?.data?.error) {
          routerHistory.push(LOGIN_PATH);
        }
      });
    } catch (err) {
      if (err && err.response) {
        toast.error(t('operation.operationFailed'));
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  const onSubmit = (data: IRescuePasswordInput) => {
    rescuePassword(data);
  };

  useEffect(() => {
    checkApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ marginBottom: '10px' }}>
              <a href="/">
                <PartnerLandingLogo height="60" />
              </a>
            </div>
            <div>
              <h3>{t('forgotPassword.renewYourPassword')}</h3>
            </div>
            <form style={{ marginTop: 30 }} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    {...register('password', { required: true })}
                    fullWidth
                    label={t('label.newPassword')}
                    type="password"
                    onChange={handleChange('password')}
                    autoComplete="off"
                  />
                  {errors?.password?.type === 'required' && (
                    <Box sx={{ mt: 1 }}>
                      <span className="error-validation">
                        {t('error.inputRequired')}
                      </span>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('rePassword', { required: true })}
                    fullWidth
                    label={t('error.inputRequired')}
                    type="password"
                    onChange={handleChange('rePassword')}
                    autoComplete="off"
                  />
                  {errors?.rePassword?.type === 'required' && (
                    <Box sx={{ mt: 1 }}>
                      <span className="error-validation">
                        {t('error.inputRequired')}
                      </span>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('forgotPassword.changePassword')}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button>
                    <Link
                      onMouseOver={() => handleLoginButtonStyle(true)}
                      onMouseOut={() => handleLoginButtonStyle(false)}
                      style={loginButtonStyle}
                      to={LOGIN_PATH}
                    >
                      {t('forgotPassword.goToHomePage')}
                    </Link>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    </>
  );
};
