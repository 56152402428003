import { IGeneralResponse, Lookup } from './GeneralModel';

export interface IBankInfo extends IGeneralResponse {
  // eslint-disable-next-line camelcase
  bankInfoId: number;
  name: string;
  iban: string;
  bankCode: string;
  branchCode: string;
  bankName: string;
  branchName: string;
  accountNumber: string;
  currencyCode: string;
  districtId: number;
  cityId: number;
  countryCode: string;
  relationId: number;
  displayOrder: number;
}

export interface IBankBranchLookup extends Lookup {
  cityId: number;
  districtId: number;
}

export interface IBankLookup extends Lookup {
  country: string
}

export interface IBankLookupFormInput {
  bankCode: string;
}

export const defaultIBankInfoProps: IBankInfo = {
  name: '',
  accountNumber: '',
  bankCode: '',
  bankInfoId: 0,
  bankName: '',
  branchCode: '',
  branchName: '',
  cityId: 0,
  countryCode: '',
  currencyCode: '',
  data: '',
  districtId: 0,
  error: false,
  iban: '',
  message: '',
  displayOrder: 0,
} as IBankInfo;

export interface IBankInfoDeleteRequest {
  partnerId: number;
  bankInfoId: number;
}
