import * as React from 'react';
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import { PopperUnstyled } from '@mui/base';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AppLanguage } from '../../../const/app-languages';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-size: 12px;
  font-weight:600;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 120px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 24px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
    font-size: 20px;
    line-height: 25px;
  }

  & img {
    margin: 0px 10px -6px 0;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 12px;
  font-weight:600;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 150px;
  max-height: 200px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  text-align:left;

  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  & img {
    margin: 0px 10px -3px 0;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef((
  props: SelectUnstyledProps<any>,
  ref: React.ForwardedRef<any>,
) => {
  const components: SelectUnstyledProps<any>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Language: React.FC = () => {
  const [code, setCode] = React.useState('tr');
  const { i18n } = useTranslation();

  const changeLanguage = (langCode : string) => {
    i18n.changeLanguage(langCode);
    setCode(langCode);
  };

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      if ((Object.values(AppLanguage) as string[]).includes(language)) {
        setCode(language);
      }
    }
  }, []);

  return (
    <CustomSelect
      value={code ?? 'tr'}
      onChange={changeLanguage}
      className="languageSelect"
    >
      {countries.map(c => (
        <StyledOption key={c.code} value={c.code}>
          <img
            loading="lazy"
            style={{ width: '30px', height: '20px' }}
            src={`https://flagcdn.com/w20/${c.flag}.png`}
            srcSet={`https://flagcdn.com/w40/${c.flag}.png 2x`}
            alt={`Flag of ${c.label}`}
          />
          {c.label}
        </StyledOption>
      ))}
    </CustomSelect>
  );
};

export default Language;

const countries = [
  { param: 'EN', code: 'en', flag: 'gb', label: 'English' },
  { param: 'TR', code: 'tr', flag: 'tr', label: 'Türkçe' },
];
