import React, { useState } from 'react';
import DrawerContext from './DrawerContext';
import { DrawerProps } from './DrawerContextProps';

interface Props {
  children: React.ReactNode;
}

const DrawerContextProvider: React.FC<Props> = ({ children }) => {
  const toggleDrawer = () => {
    drawerProps.isShow = !drawerProps.isShow;
    setDrawerProps({ ...drawerProps });
  };
  const close = () => {
    drawerProps.isShow = true;
    setDrawerProps({ ...drawerProps });
  };
  const toggleSecondary = (status: boolean) => {
    drawerProps.secondaryIsShow = status;
    setDrawerProps({ ...drawerProps });
  };

  const [drawerProps, setDrawerProps] = useState<DrawerProps>({
    toggle: toggleDrawer,
    close,
    toggleSecondary,
    children: null,
    isShow: !!(window.screen.width <= 550),
    secondaryIsShow: false,
    title: '',
  });

  return <DrawerContext.Provider value={drawerProps}>{children}</DrawerContext.Provider>;
};

export default DrawerContextProvider;
