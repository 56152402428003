import { IGeneralResponse } from './GeneralModel';

export interface IChangePassword extends IGeneralResponse {
  CurrentPassword: string;
  NewPassword: string;
  NewPasswordAgain: string;
  showPassword: boolean;
  showNewPassword: boolean;
  showRePassword: boolean;
}

export const defaultIChangePasswordProps: IChangePassword = {
  CurrentPassword: '',
  NewPassword: '',
  NewPasswordAgain: '',
} as IChangePassword;
