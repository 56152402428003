import React from 'react';
import { Doughnut } from '@reactchartjs/react-chart.js';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material/';
import { useTranslation } from 'react-i18next';

interface Props {
  // eslint-disable-next-line no-unused-vars
  salesAmount: number;
  cancelAmount: number;
}

export const SalesCancellationGraph: React.FC<Props> = ({ salesAmount, cancelAmount }) => {
  const { t } = useTranslation();
  const data = {
    labels: [t('dashboard.revenueAmount'), t('dashboard.cancelAmount')],
    datasets: [
      {
        label: t('common.number'),
        data: [salesAmount, cancelAmount],
        backgroundColor: ['#43a047', '#c62828'],
        borderWidth: 2,
        pointBorderWidth: 3,
        pointHoverBorderWidth: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Doughnut data={data} options={options} type={undefined} />
          </Grid>
          <Grid
            item
            sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Typography sx={{ marginTop: 2 }} color="textSecondary" gutterBottom variant="h6">
              <span
                role="button"
                tabIndex={0}
              >
                {t('dashboard.cancel')}
                :
                {' '}
                {cancelAmount}
              </span>
            </Typography>
            <Typography sx={{ marginTop: 2 }} color="textSecondary" gutterBottom variant="h6">
              <span
                role="button"
                tabIndex={0}
              >
                {t('dashboard.revenue')}
                :
                {' '}
                {salesAmount}
              </span>
            </Typography>
          </Grid>
          <Divider />
          <Typography sx={{ marginTop: 2, textAlign: 'center' }} color="textSecondary" gutterBottom variant="h6">
            <span
              role="button"
              tabIndex={0}
            >
              {t('dashboard.cancelRevenueRate')}
              :
              {' '}
              %
              {' '}
              {((cancelAmount / salesAmount) * 100).toFixed(2)}
            </span>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default SalesCancellationGraph;
